import './imagenesSwipe.scss';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide, } from 'swiper/react';
import SwiperCore from 'swiper/core';
import { Navigation, Thumbs  } from 'swiper/modules';
import { useState, useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import arrowLeft from '../assets/ArrowIzq.json';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

SwiperCore.use([Navigation, Thumbs  ]);


const ImageSwipe = props => {
    const [activeThumb, setActiveThumb] = useState()
    

    const flechaL = useRef(null);
    const flechaR = useRef(null);
    const [clickArrowL,setClickArrowL]= useState(false);
    const [clickArrowR,setClickArrowR]= useState(false);
    const [isFirstTime,setIsFirstTime] = useState(true);
    const [isFirstTime2,setIsFirstTime2] = useState(true);

    const [thumbsSwiper, setThumbsSwiper] = useState(null);


    useEffect(() => {
        
        const instanceArrow=lottie.loadAnimation({
            container: flechaL.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: arrowLeft
        });

        
        if(isFirstTime){
            setIsFirstTime(false);
            instanceArrow.goToAndStop(32, true);
        }else{
            instanceArrow.playSegments([0, 20], true);
            instanceArrow.setSpeed(5);
        }

        return () => {instanceArrow.destroy()};   
    }, [clickArrowL,isFirstTime]);


    useEffect(() => {
        
        const instanceArrowR=lottie.loadAnimation({
            container: flechaR.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: arrowLeft
        });

        
        if(isFirstTime2){
            setIsFirstTime2(false);
            instanceArrowR.goToAndStop(32, true);
        }else{
            instanceArrowR.playSegments([0, 32], true);
            instanceArrowR.setSpeed(5);
        }

        return () => {instanceArrowR.destroy()};   
    }, [clickArrowR,isFirstTime2]);
        
    const handdleClickArrowL = () => {
        setClickArrowL(!clickArrowL);
    }

    const handdleClickArrowR = () => {
        setClickArrowR(!clickArrowR);
    }



    return <>

    
        <Swiper
            loop={false}
            spaceBetween={10}
            navigation={true}
            grabCursor={true}
            className='product-images-slider principalS '
            thumbs ={{ swiper: thumbsSwiper }}
        >
            {
                props.images.map((item, index) => (
                    <SwiperSlide key={index}>
                        <img src={item} alt="Imagen" className='imagenPubliSw'/>
                    </SwiperSlide>
                ))
            }
        </Swiper>

        {props.images.length > 1 && 
            <>
                    
                <Swiper
                    navigation={{
                        prevEl: '.prev',
                        nextEl: '.next',
                    }}
                    onSwiper={setThumbsSwiper}
                    loop={false}
                    spaceBetween={10}
                    slidesPerView={2.5}
                    
                    className='product-images-slider-thumbs prueba  '
                >

                    {
                        props.images.map((item, index) => (
                            <SwiperSlide key={index} >
                                <div className="product-images-slider-thumbs-wrapper">
                                    <img src={item} alt="Imagen" className='imagenPubliSw'/>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                        
                        
                </Swiper>

                <div className='prueba3P'>
                    <button onClick={()=>handdleClickArrowL()} className="nada">
                        <div className="prev" ref={flechaL}/>
                    </button>
                    <button onClick={() => handdleClickArrowR()} className="nada">
                        <div className="next" ref={flechaR}/>
                    </button>
                </div>
            </>
        }

        
    </>
}

ImageSwipe.propTypes = {
    images: PropTypes.array.isRequired
}

export default ImageSwipe