import {useHits} from 'react-instantsearch-hooks'
import './autocompletado.scss'


export default function Autocompletado({handleSelection,top}) {
    
    const {hits} = useHits()

    return (
            
        <div className='autocompletado'>
            {hits.slice(0, 4).map((hit, index) => ( // Aquí usas slice para tomar solo los primeros 4 elementos
                <button className='filaAu' key={index}
                        onClick={() => handleSelection(hit.titulo)}>
                    {/* Si necesitas usar <Item>, puedes descomentar y usar la línea de abajo */}
                    {/*<Item key={hit.objectID} hit={hit} />*/}
                    <h1 className='textoAu'>{hit.titulo}</h1>
                </button>
            ))}
        </div>
            
    )
}

/*
function Item ({hit}){
    return(
        <div>
            <div>
                {//<img src={hit.img[0]} alt=''/>}
                <h1 className='textoAu'>{hit.titulo}</h1>
            </div>    
        </div>
    )
}*/