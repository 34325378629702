import React, { useState, useRef, useEffect, useCallback } from "react";
import "./inicio.scss";
import { useLocation } from "react-router-dom";

import Barra from "../barra_de_busqueda/barra.jsx";
import Listado from "../listado/listado.jsx";
import ListadoL from "../loaders/listadoL.jsx";

import lottie from "lottie-web";
import arrowUpDown from "../assets/arrowUpDown.json";
import filter from "../assets/filter.json";

import { Global } from "@emotion/react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { grey } from "@mui/material/colors";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Alert from "@mui/material/Alert";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { WithContext as ReactTags } from "react-tag-input";
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import { useTags } from "../variablesGlobales/tagsGlobal.jsx";
import axios from "axios";
import Footer from "../footer/footer.jsx";

axios.defaults.withCredentials = true;

const drawerBleeding = 56;

const Root = styled("div")(({ theme }) => ({
  height: "100%",
  backgroundColor:
    theme.palette.mode === "light"
      ? grey[100]
      : theme.palette.background.default,
}));

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#fff" : grey[800],
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? grey[300] : grey[900],
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

export default function Inicio(props) {
  const { ventana } = props;
  const searchParams = new URLSearchParams(window.location.search);
  const estado = searchParams.get("estado");
  const location = useLocation();

  const [busqueda, setBusqueda] = useState(
    location.state
      ? location.state.bus
        ? location.state.bus
        : undefined
      : undefined
  );
  const [open, setOpen] = React.useState(false);
  const [optionList, setOptionList] = useState([]);
  const [optionList2, setOptionList2] = useState([]);

  const { usuario, token } = useUsuario();
  const {
    tags,
    tags2,
    setTags,
    setTags2,
    tagsTotal,
    setTagsTotal,
    profesiones,
    setProfesiones,
    selectedOptions2,
    setSelectedOptions2,
    ubicaciones,
    setUbicaciones,
  } = useTags();
  const [cadenaAux, setCadenaAux] = useState("");

  const [ordenamiento, setOrdenamiento] = useState(0);

  const toggleDrawer = (newOpen) => () => {
    handdleClickFiltro();
    setOpen(newOpen);
  };

  const container =
    ventana !== undefined ? () => window().document.body : undefined;

  const flecha = useRef(null);
  const filtro = useRef(null);

  const [isFirstTime, setIsFirstTime] = useState(true);
  const [clickFlecha, setClickFlecha] = useState(false);
  const [clickFiltro, setClickFiltro] = useState(false);
  const [clickFlecha2, setClickFlecha2] = useState(false);

  const [alertaSuccess, setAlertaSuccess] = useState(false);
  const [alertaSuccess2, setAlertaSuccess2] = useState(false);
  const [alertaPending, setAlertaPending] = useState(false);
  const [alertaFailure, setAlertaFailure] = useState(false);

  const [primeraCarga,setPrimeraCarga] = useState(true)

  const [page, setPage] = useState(0);

  const [items, setItems] = useState([]);
  const [loadItems, setLoadItems] = useState(false);
  const itemRange = Array.from({ length: 15 }, (_, i) => i * 2);

  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const observer = useRef();
  const lastItemElementRef = useCallback(
    (node) => {
      if (loadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loadingMore, hasMore, page]
  );

  useEffect(() => {
    //setUsuario(location.state? (location.state.user? location.state.user: undefined) :undefined)
    setBusqueda(
      location.state
        ? location.state.bus
          ? location.state.bus
          : undefined
        : undefined
    );

    setLoadItems(true);
    setItems([]);
    setPage(0);
  }, [location.state]);

  useEffect(() => {
    if (estado) {
      if (estado === "success" && alertaSuccess !== true) {
        setAlertaSuccess(true);
      } else if (estado === "success2") {
        setAlertaSuccess2(true)
      } else if (estado === "pending") {
        setAlertaPending(true);
      } else if (estado === "failure") {
        setAlertaFailure(true);
      }
    }
  }, [estado]);

  useEffect(() => {
    setLoadingMore(true);

    var cadenaURL = "";
    var cuerpo = {};
    var aux = [];
    //var busquedaAux = busqueda ? `${busqueda}` : "";
    //busquedaAux += `${cadenaAux}`;

    var itemsAux = items ? items : [];

    let cancel;

    if (busqueda && profesiones !== undefined && profesiones.length > 0 && ubicaciones !== undefined && ubicaciones.length > 0) {

      profesiones.forEach((profesion) => {
        aux.push(profesion.value);
      });

      const category = {
        CATEGORY_L1: aux,
        ZONA: ubicaciones,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?TITULO=${busqueda}&CATEGORY_L1=""&PRICE=${ordenamiento}&page=${page}`;

    } else if (profesiones !== undefined && profesiones.length > 0 && ubicaciones !== undefined && ubicaciones.length > 0) {

      profesiones.forEach((profesion) => {
        aux.push(profesion.value);
      });

      const category = {
        CATEGORY_L1: aux,
        ZONA: ubicaciones,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?TITULO=""&CATEGORY_L1=""&PRICE=${ordenamiento}&page=${page}`;
    } else if (busqueda && profesiones !== undefined && profesiones.length > 0) {

      profesiones.forEach((profesion) => {
        aux.push(profesion.value);
      });

      const category = {
        CATEGORY_L1: aux,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?TITULO=${busqueda}&CATEGORY_L1=""&PRICE=${ordenamiento}&page=${page}`;
    } else if (busqueda && ubicaciones !== undefined && ubicaciones.length > 0) {
      const category = {
        ZONA: ubicaciones,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?TITULO=${busqueda}&PRICE=${ordenamiento}&page=${page}`;
    } else if (ubicaciones !== undefined && ubicaciones.length > 0) {
      const category = {
        ZONA: ubicaciones,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?PRICE=${ordenamiento}&page=${page}`;
    } else if (busqueda) {
      cadenaURL = `https://api.tulu.com.ar/Items/Busqueda?TITULO=${busqueda}&PRICE=${ordenamiento}&page=${page}`;
      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      };
    } else if (profesiones !== undefined && profesiones.length > 0) {
      profesiones.forEach((profesion) => {
        aux.push(profesion.value);
      });

      const category = {
        CATEGORY_L1: aux,
      };

      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: category,
      };

      cadenaURL = `https://api.tulu.com.ar/Items/Filtros?&PRICE=${ordenamiento}&page=${page}`;
    } else {
      cadenaURL = `https://api.tulu.com.ar/Items/Busqueda?&PRICE=${ordenamiento}&page=${page}`;
      cuerpo = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {},
      };
    }

    if (usuario) {
      cadenaURL = cadenaURL + `&email=${usuario.email}`;
    }

    axios.request({
      method: cuerpo.method,
      url: cadenaURL,
      params: cuerpo.body,
      data: cuerpo.body,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
      withCredentials: true,
    })
      .then((response) => {
        const final = itemsAux.concat(response.data.items);
        setItems(final);
        setHasMore(response.data.items.length > 0);
        setLoadingMore(false);
        setLoadItems(false);
        setPrimeraCarga(false)
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
      });

    return () => cancel();
  }, [profesiones, busqueda, cadenaAux, ordenamiento, usuario, page, ubicaciones, token]);

  /*useEffect(() => {
    setSelectedOptions2([]);
  }, [busqueda]);*/

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://api.tulu.com.ar/Items/category");
      const data = response.data;
      let profesionesFinal = [];
      data.forEach((item) => {
        if (item)
          profesionesFinal.push({ value: item, label: item.slice(1) });
      });

      setOptionList(profesionesFinal);

      const response2 = await axios.get("https://api.tulu.com.ar/Items/zona");
      const data2 = response2.data;
      let elementos = [];

      data2.forEach((item) => {
        elementos.push({ value: item, label: item });
      });
      let resultado = elementos.reduce((a, e) => {
        if (!a.find((d) => d.value == e.value)) {
          a.push(e);
        }
        return a;
      }, []);

      setOptionList2(resultado);
    }
    fetchData();
  }, []);


  useEffect(() => {
    var aux = [];
    if (profesiones !== undefined && profesiones.length > 0) {
      profesiones.forEach((profesion) => {
        aux.push({ id: profesion.value, text: profesion.label });
      });
    }

    setTags(aux);
  }, [profesiones, setTags]);

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: flecha.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: arrowUpDown,
    });

    const instance2 = lottie.loadAnimation({
      container: filtro.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: filter,
    });

    if (isFirstTime) {
      instance.goToAndStop(0, true);
      instance2.goToAndStop(0, true);
      setIsFirstTime(false);
    } else {
      if (!clickFlecha && clickFlecha2) {
        instance.playSegments([7, 14], true);
        setClickFlecha2(false);
        instance2.setSpeed(3);
      } else if (clickFlecha && clickFlecha2) {
        instance.playSegments([0, 7], true);
        setClickFlecha2(false);
        instance2.setSpeed(3);
      } else {
        instance2.playSegments([0, 320], true);
        instance2.setSpeed(20);
      }
    }
    return () => {
      instance.destroy();
      instance2.destroy();
    };
  }, [clickFlecha, clickFiltro]);

  useEffect(() => {
    var aux = [];
    aux = tags.concat(tags2);
    setTagsTotal(aux);
  }, [tags, tags2, setTagsTotal]);

  useEffect(() => {
    var aux = [];
    var cadena2 = "";
    if (selectedOptions2 !== undefined && selectedOptions2.length > 0) {
      selectedOptions2.forEach((profesion) => {
        cadena2 += " " + profesion.value;
        aux.push({ id: profesion.value, text: profesion.label });
      });
    } else {
      cadena2 = "";
    }
    setLoadItems(true);
    setItems([]);
    setPage(0);
    setCadenaAux(cadena2);
    setTags2(aux);
    setPrimeraCarga(false)
  }, [selectedOptions2, setTags2]);

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "0px",
      minHeight: "10vh",
      boxShadow: "0px 0px 25px rgba(255, 255, 255, 0.12)",
      filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
      paddingLeft: "2.3vw",
      paddingRight: "1vw",
      fontSize: "2.1vh",
    }),

    menu: (provided, state) => ({
      ...provided,
    }),
  };

  const handdleClickFlecha = () => {
    if (ordenamiento === 0) {
      setClickFlecha(!clickFlecha);
      setClickFlecha2(true);
      setOrdenamiento(1);
      setLoadItems(true);
      setPage(0);
      setItems([]);
      setPrimeraCarga(false)
    } else if (ordenamiento === 1) {
      setClickFlecha(!clickFlecha);
      setClickFlecha2(true);
      setOrdenamiento(-1);
      setLoadItems(true);
      setPage(0);
      setItems([]);
      setPrimeraCarga(false)
    } else {
      setOrdenamiento(0);
      setLoadItems(true);
      setPage(0);
      setItems([]);
      setPrimeraCarga(false)
    }
  };

  const handdleClickFiltro = () => {
    setClickFiltro(!clickFiltro);
  };

  const handleSelect = (data) => {
    setLoadItems(true);
    setItems([]);
    setPrimeraCarga(false)
    setPage(0);
    setProfesiones(data);
    data.forEach((profesion) => {
      setTags([...tags, { id: profesion.value, text: profesion.label }]);
    });
  };

  const handleSelect2 = (data) => {
    setLoadItems(true);
    setItems([]);
    setPrimeraCarga(false)
    setPage(0);

    setSelectedOptions2(data);

    var aux = [];
    var aux2 = []

    data.forEach((ubicacion) => {
      //cadena2 += " " + ubicacion.value;
      aux2.push(ubicacion.value)
      aux.push({ id: ubicacion.value, text: ubicacion.label });
    });
    setItems([]);
    setPrimeraCarga(false)
    setPage(0);
    setUbicaciones(aux2)
    //setCadenaAux(cadena2);
    setTags2(aux);
  };

  const handleDelete = (i) => {
    let aux = [];

    tags.forEach((profesion, index) => {
      if (index !== i) {
        aux.push({ value: profesion.id, label: profesion.text });
      }
    });

    setProfesiones(aux);
    setLoadItems(true);
    setPage(0);
    setItems([]);
    setPrimeraCarga(false)
  };

  const handleDelete2 = (i) => {
    let aux = [];

    tags2.forEach((profesion, index) => {
      if (index !== i) {
        aux.push({ value: profesion.id, label: profesion.text });
      }
    });

    setSelectedOptions2(aux);
    setUbicaciones([])
  };
  /*const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };*/

  const handleTagClick = (index) => {
    //console.log('The tag at index ' + index + ' was clicked');
  };

  const handleAlertaChange = () => {
    setAlertaSuccess(false);
    setAlertaSuccess2(false);
    setAlertaFailure(false);
    setAlertaPending(false);
  };

  return (
    <div className={loadItems ? "caja1NoScroll" : "caja1"}>
      <Barra user={usuario} bus={busqueda} />
      <div className="botonesFiltro">
        <button
          className="publica2"
          onClick={() => {
            handdleClickFlecha();
          }}
        >
          Precio
          {ordenamiento === 0 ? (
            <></>
          ) : (
            <div
              className="animacionFlechaInicio"
              ref={flecha}
              onClick={() => {
                handdleClickFlecha();
              }}
            />
          )}{" "}
        </button>
        <button className="publica2" onClick={toggleDrawer(true)}>
          <span> Más filtros</span>
          <div className="animacionFiltroInicio" ref={filtro} />
        </button>
        <Root>
          <CssBaseline />
          <Global
            styles={{
              ".MuiDrawer-root > .MuiPaper-root": {
                overflow: "visible",
              },
            }}
          />

          <SwipeableDrawer
            container={container}
            sx={{ height: `calc(50% - ${drawerBleeding}px)` }}
            anchor="bottom"
            open={open}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={drawerBleeding}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <StyledBox
              sx={{
                position: "absolute",
                top: -drawerBleeding,
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
                right: 0,
                left: 0,
                background: "#112B3C",
              }}
            >
              <Puller />
              <Typography
                sx={{
                  p: 3.8,
                  color: "#FFF",
                  backgroundColor: "#112B3C",
                  position: "relative" // Añadido position relative al componente Typography
                }}
                onClick={toggleDrawer(false)}
              >
                <p className="cross2">˟</p>
              </Typography>
            </StyledBox>
            <StyledBox
              sx={{
                px: 2,
                pb: 2,
                height: "100%",
                overflow: "auto",
                background: "#112B3C",
              }}
            >
              <div className="filtros">
                <div className="cajaUbicacionFiltros">
                  <p className="ubicacionFiltro">Profesión</p>
                  <div className="profesiones">
                    <Select
                      options={optionList}
                      placeholder="Profesiones"
                      value={profesiones}
                      onChange={handleSelect}
                      isSearchable={true}
                      isOptionDisabled={() => profesiones.length >= 3}
                      isMulti
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#f9e4d4",
                          primary: "#ED6C02",
                        },
                      })}
                    />
                  </div>
                </div>

                <div className="cajaUbicacionFiltros">
                  <p className="ubicacionFiltro">Ubicación</p>

                  <div className="profesiones">
                    <CreatableSelect
                      options={optionList2}
                      placeholder="Ubicaciones"
                      formatCreateLabel={(inputText) => `${inputText}`} //si no esta va a crear una nueva ubicacion y la va a guardar, esto sirve
                      //para cambiar el texto que aparece en la lista. si pongo por ejemplo
                      //{(inputText) => `Crear ${inputText}`} va a aparecer "Crear CABA" (CABA o cualquier otra zona)
                      value={selectedOptions2}
                      onChange={handleSelect2}
                      isOptionDisabled={() => selectedOptions2.length >= 1}
                      isSearchable={true}
                      isMulti
                      styles={customStyles}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#f9e4d4",
                          primary: "#ED6C02",
                        },
                      })}
                    />
                  </div>
                </div>
              </div>
            </StyledBox>
          </SwipeableDrawer>
        </Root>
      </div>
      <div
        className={tagsTotal.length > 0 ? "boxTagsInicio" : "boxTagsInicio2"}
      >
        <ReactTags
          tags={tags}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleTagClick={handleTagClick}
          inputFieldPosition="inline"
          classNames={{
            tags: "tagsClass",
            tagInput: "tagInputClass",
            tagInputField: "tagInputFieldClass",
            selected: "selectedClass",
            tag: "tagClass",
            remove: "removeClass",
            suggestions: "suggestionsClass",
            activeSuggestion: "activeSuggestionClass",
            editTagInput: "editTagInputClass",
            editTagInputField: "editTagInputField",
            clearAll: "clearAllClass",
          }}
        />
        <ReactTags
          tags={tags2}
          delimiters={delimiters}
          handleDelete={handleDelete2}
          handleTagClick={handleTagClick}
          inputFieldPosition="inline"
          classNames={{
            tags: "tagsClass",
            tagInput: "tagInputClass",
            tagInputField: "tagInputFieldClass",
            selected: "selectedClass",
            tag: "tagClass",
            remove: "removeClass",
            suggestions: "suggestionsClass",
            activeSuggestion: "activeSuggestionClass",
            editTagInput: "editTagInputClass",
            editTagInputField: "editTagInputField",
            clearAll: "clearAllClass",
          }}
        />
      </div>

      {loadItems && itemRange.map((i, index) => <ListadoL key={index} />)}
      <div className="listadoPubli">
        {!loadItems &&
          items?.map((i, index) => {
            if (items.length === index + 1) {
              return (
                <div key={index} ref={lastItemElementRef}>
                  <Listado item={i} bus={busqueda} />
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <Listado item={i} bus={busqueda} />
                </div>
              );
            }
          })}

        {!loadItems && items?.length === 0 && !primeraCarga && (
          <div className="loSentimos">Lo sentimos, no encontramos ningún lugar con esas características  <br></br><br></br>:(</div>
        )

        }
      </div>

      {alertaSuccess && (
        <Alert
          onClose={handleAlertaChange}
          className="posicionAlerta"
          variant="filled"
          severity="success"
        >
          ¡El pago se realizó con éxito!
        </Alert>
      )}
      {alertaSuccess2 && (
        <Alert
          onClose={handleAlertaChange}
          className="posicionAlerta"
          variant="filled"
          severity="success"
        >
          ¡La contraseña se cambió con éxito!
        </Alert>
      )}
      {alertaPending && (
        <Alert
          onClose={handleAlertaChange}
          className="posicionAlerta"
          variant="filled"
          severity="warning"
        >
          Estamos esperando al procesamiento del pago.
        </Alert>
      )}
      {alertaFailure && (
        <Alert
          onClose={handleAlertaChange}
          className="posicionAlerta"
          variant="filled"
          severity="error"
        >
          Hubo un error al realizar el pago, intenta nuevamente.
        </Alert>
      )}
      {loadingMore &&
        <div className="separacion">
          <span className="loader2"></span>
        </div>
      }
      {(hasMore === false && loadingMore === false) &&
        <div >
          <div>
            <div className="separacion ">{items.length > 0 ? "No hay más resultados" : ""}</div>
          </div>

          <Footer />
        </div>
      }
    </div>
  );
}
