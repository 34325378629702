import React, { useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import Inicio from "./inicio/inicio.jsx";
import Portada from "./portada/portada.jsx";
import Publicacion from "./publicacion/publicacion.jsx";
import Publicar from "./crear_publicacion/crearPublicacion.jsx";
import Ingresar from "./ingresar/ingresar.jsx";
import Reservar from "./reserva/reserva.jsx";
import Reservas from './misReservas/misReservas.jsx';
import Publicaciones from './misPublicaciones/misPublicaciones.jsx';
import MisFavoritos from './misFavoritos/misFavoritos.jsx';
import ReservasEnMisPublicaciones from './reservasEnMisPublicaciones/reservasEnMisPublicaciones.jsx';
import PasswordRecovery from './passwordRecovery/passwordRecovery.jsx';
import VerificarMail from './verificarMail/verificarMail.jsx';
//import AcercaDe from './acercaDe/acercaDe.jsx';
//import Contacto from './contacto/contacto.jsx';
import MiCuenta from './miCuenta/miCuenta.jsx';
import Footer from './footer/footer.jsx';

import Barra from './barra_de_busqueda/barra.jsx';

import InicioLoader from './loaders/inicioL.jsx';

import './App.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import { UsuarioProvider, useUsuario } from "./variablesGlobales/usuarioGlobal.jsx";
import { TagsProvider } from "./variablesGlobales/tagsGlobal.jsx"

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { deepOrange } from '@mui/material/colors';
import { orange } from '@mui/material/colors';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Registrarse from './registrarse/registrarse.jsx';

import { Helmet } from "react-helmet";



const MyApp = () => (
  <UsuarioProvider>
    <TagsProvider>
      <App />
    </TagsProvider>
  </UsuarioProvider>
);

export default MyApp;

function App() {

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const { usuario, cargandoUsuario, canLeave } = useUsuario()
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();



  useEffect(() => {
    if (usuario) {
      if (usuario.autorizacionMp === false && usuario.posee_items > 0 && !code && !state) {
        setOpen(true)
      }
    }
  }, [usuario])

  const handleClose = (e) => {
    setOpen(false);
    navigate("/miCuenta", { state: { scroll: true } });
  };

  const theme = createTheme({

    palette: {
      primary: deepOrange,
      secondary: orange,
    },

  })

  return (
    <>

      {cargandoUsuario && <> <Helmet><title>Cargando...</title></Helmet><InicioLoader /> </>}
      {!cargandoUsuario &&
        <>
          <Helmet><title>Tulu</title></Helmet>
          <ThemeProvider theme={theme}>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"No se pudo establecer la conexión con Mercado Pago"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Lamentablemente, no pudimos conectarnos a Mercado Pago y por tanto no podremos procesar los pagos que se generen en tus publicaciones.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>
                  Ir a configuración
                </Button>
              </DialogActions>
            </Dialog>

            <Routes>
              {!usuario &&
                <>
                  <Route path="/" element={<Portada />} />
                  <Route path="/publicar" element={<Ingresar />} />
                  <Route path='/reserva' element={<Ingresar />} />
                  <Route path="/reserva/:id" element={<Ingresar />} />
                  <Route path="/miCuenta" element={<Ingresar />} />
                  <Route path="/miCuenta/:code/:state" element={<Ingresar />} />
                  <Route path="/misFavoritos" element={<MisFavoritos />} />
                  <Route path="/misPublicaciones" element={<Publicaciones />} />
                  <Route path="/reservasMisPublicaciones" element={<ReservasEnMisPublicaciones />} />
                  <Route path='/misReservas' element={<Reservas />} />
                  <Route path="/inicio" element={<Inicio />} />
                  <Route path="/publicacion" element={<Publicacion />} />
                  <Route path="/publicacion/:id" element={<Publicacion />} />
                </>
              }
              {usuario && canLeave &&
                <>
                  <Route path="/" element={<Inicio />} />
                  <Route path="/publicar" element={<Publicar />} />
                  <Route path='/reserva' element={<Reservar />} />
                  <Route path="/reserva/:id" element={<Reservar />} />
                  <Route path="/miCuenta" element={<MiCuenta />} />
                  <Route path="/miCuenta/:code/:state" element={<MiCuenta />} />
                  <Route path="/misFavoritos" element={<MisFavoritos />} />
                  <Route path="/misPublicaciones" element={<Publicaciones />} />
                  <Route path="/reservasMisPublicaciones" element={<ReservasEnMisPublicaciones />} />
                  <Route path='/misReservas' element={<Reservas />} />
                  <Route path="/inicio" element={<Inicio />} />
                  <Route path="/publicacion" element={<Publicacion />} />
                  <Route path="/publicacion/:id" element={<Publicacion />} />
                </>
              }

              {usuario && !canLeave &&
                <>
                  <Route path="/" element={<VerificarMail />} />
                  <Route path="/verificar" element={<VerificarMail />} />
                  <Route path="/publicar" element={<VerificarMail />} />
                  <Route path='/reserva' element={<VerificarMail />} />
                  <Route path="/reserva/:id" element={<VerificarMail />} />
                  <Route path="/miCuenta" element={<VerificarMail />} />
                  <Route path="/miCuenta/:code/:state" element={<VerificarMail />} />
                  <Route path="/misFavoritos" element={<VerificarMail />} />
                  <Route path="/misPublicaciones" element={<VerificarMail />} />
                  <Route path="/reservasMisPublicaciones" element={<VerificarMail />} />
                  <Route path='/misReservas' element={<VerificarMail />} />
                  <Route path="/inicio" element={<VerificarMail />} />
                  <Route path="/publicacion" element={<VerificarMail />} />
                  <Route path="/publicacion/:id" element={<VerificarMail />} />
                </>
              }
              <Route path='/ingresa' element={<Ingresar />} />
              <Route path='/registrate' element={<Registrarse />} />
              <Route path="/__/auth/action" element={<PasswordRecovery />} />
              {/*<Route path="/acercaDe" element={<AcercaDe/>} />*/}
              {/*<Route path="/contacto" element={<Contacto/>} />*/}
              <Route path="/footer" element={<Footer />} />
              <Route path="*" element={<><Barra></Barra> <div className='loSentimos alturaNoTienesRe'> <div className='containerText'>Lo sentimos, la página que estás buscando no se ha encontrado.<Link to="/" className='letrita'>Click aqui para volver al inicio</Link></div></div></>} />
            </Routes>
          </ThemeProvider>
        </>
      }
    </>
  );
}


