import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Barra from "../barra_de_busqueda/barra.jsx";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { InputAdornment } from "@mui/material";
import "./ingresar.scss";
import {
  FacebookLoginButton,
  GoogleLoginButton,
  TwitterLoginButton,
} from "react-social-login-buttons";
import {
  facebookProvider,
  googleProvider,
  twitterProvider,
} from "../config/authMethods.js";
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import Footer from "../footer/footer.jsx";
import Modal from '@mui/material/Modal';
import ReCAPTCHA from "react-google-recaptcha";

export default function Ingresar() {
  const location = useLocation();

  const targetRef = useRef();
  const captcha = useRef(null);
  const [captchaValido, setCaptchaValido] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [habilitarBoton, setHabilitarBoton] = useState(false);
  const [loading,setLoading]=useState(false);
  const [mostrarCaptcha, setMostrarCaptcha] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [openModal, setOpenModal] = useState(false);
  const [timeLeft, setTimeLeft] = useState(90); // 10 segundos
  const [isRunning, setIsRunning] = useState(false);


  const { usuario, login, singInWithPassword,generarEmailResetPassword } = useUsuario();
  const [desdePublicar, setDesdePublicar] = useState(
    location.state
      ? location.state.desdePublicar
        ? location.state.desdePublicar
        : false
      : false
  );
  const [desdeReservar, setDesdeReservar] = useState(
    location.state
      ? location.state.desdeReservar
        ? location.state.desdeReservar
        : false
      : false
  );
  const [desdePublicacion, setDesdePublicacion] = useState(
    location.state
      ? location.state.desdePublicacion
        ? location.state.desdePublicacion
        : false
      : false
  );
  const [item, setItem] = useState(
    location.state
      ? location.state.item
        ? location.state.item
        : undefined
      : undefined
  );
  const [mailUsuario, setMailUsuario] = useState("");
  const [mailRecuperoUsuario, setMailRecuperoUsuario] = useState("");

  const [helper1, setHelper1] = useState(false);
  //const [helper2,setHelper2]=useState(false);

  const navigate = useNavigate();

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {
    if(values.password.length > 0 || mailUsuario.length > 0){
      setMostrarCaptcha(true);
    }
  }, [values.password, mailUsuario]);

  useEffect(() => {
    setDesdePublicar(
      location.state
        ? location.state.desdePublicar
          ? location.state.desdePublicar
          : false
        : false
    );
    setDesdeReservar(
      location.state
        ? location.state.desdeReservar
          ? location.state.desdeReservar
          : false
        : false
    );
    setDesdePublicacion(
      location.state
        ? location.state.desdePublicacion
          ? location.state.desdePublicacion
          : false
        : false
    );
    setItem(
      location.state
        ? location.state.item
          ? location.state.item
          : undefined
        : undefined
    );
  }, [location.state]);

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (timeLeft === 0) {
      setIsRunning(false);
      setTimeLeft(90);
    }
  }, [timeLeft, isRunning]);

  function startCountdown() {
    setIsRunning(true);
  }

  const handdleOnClick = async (provider) => {
    let user = await login(provider);
    if (desdePublicar) {
      //navigate('/publicar',{state:{user:user}})
      navigate("/publicar");
    } else if (desdeReservar) {
      navigate(`/reserva/${item._id}`, { state: { user: user, item: item } });
    } else if (desdePublicacion) {
      navigate(`/publicacion/` + item._id);
    } else {
      navigate("/inicio", { state: { user: user } });
    }
  };

  useLayoutEffect(() => {
    setDimensions({
      width: targetRef.current.offsetWidth,
      height: targetRef.current.offsetHeight,
    });
  }, []);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleIngresar = async () => {
    setLoading(true);
    setHabilitarBoton(true);
    if (!captchaValido) {
      setCaptchaError(true)
      setLoading(false);
      setHabilitarBoton(false);
    } else if(mailUsuario === "" || values.password === ""){
      setPasswordError(true);
      setLoading(false);
      setHabilitarBoton(false);
    }else {
      var usr = await singInWithPassword(mailUsuario, values.password);

      if (usr) {
        if (desdePublicar) {
          navigate("/publicar");
        } else if (desdeReservar) {
          navigate(`/reserva/${item._id}`);
        } else if (desdePublicacion) {
          navigate(`/publicacion/` + item._id);
        } else {
          navigate("/inicio");
        }
      } else {
        setPasswordError(true);
        setHelper1(true);
        setLoading(false);
        setHabilitarBoton(false);
      }
    }
  };

  const handleRecuperar = () => {
    setOpenModal(true);
  }

  const handleEnviar = () =>{
    startCountdown();
    generarEmailResetPassword(mailRecuperoUsuario);
  }

  const onChangeReCAPTCHA = () => {
    if(captcha.current.getValue()){
      setCaptchaValido(true);
      setCaptchaError(false);
    }
  }

  return (
    <div className="cajaCompleta">
      <div className="colorFondoIn" ref={targetRef}>
        <Barra user={usuario} />

        <div className="ContainerCardIn">
          <div className="cardIn">
            <h1 className="tituloIn">Ingresá</h1>
            <h2 className="subtituloIn">con alguna de estas opciones</h2>
            <div className="centradoIn">
              <TextField
                error={passwordError}
                id="outlined-required"
                label="Mail"
                onChange={(e) => setMailUsuario(e.target.value.trim())}
                value={`${mailUsuario}`}
                color="warning"
                sx={{ width: "calc(70% - 10px)", marginTop: "30px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton sx={{ p: "10px" }} aria-label="menu" disabled>
                        <PersonIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              //helperText={helper2? "Ingrese un mail valido. ejemplo@mail.com" : "" }
              />
              <TextField
                error={passwordError}
                id="outlined-adornment-password"
                label="Contraseña"
                onChange={handleChange("password")}
                onClick={() => setPasswordError(false)}
                value={values.password}
                color="warning"
                sx={{ width: "calc(70% - 10px)", marginTop: "20px" }}
                type={values.showPassword ? "text" : "password"}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton sx={{ p: "10px" }} disabled>
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  helper1 ? "El mail o la contraseña son incorrectos" : ""
                }
              />
              {mostrarCaptcha && 
                <div className="recaptcha">
                  <ReCAPTCHA ref={captcha} sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`} onChange={onChangeReCAPTCHA}/>
                  {captchaError && <div className="errorCaptcha">
                    Por favor acepta el captcha
                  </div>}
                </div>
              }

              
              <Button
                variant="contained"
                disabled={habilitarBoton}
                onClick={handleIngresar}
                sx={{
                  width: "calc(70%  - 10px)",
                  marginTop: "20px",
                  height: "50px",
                  marginBottom: "20px",
                }}
              >
                {loading ? <span class="loader3"></span> : "Ingresar"}
              </Button>
              <div className="olvidaste">¿Olvidaste tu contraseña? <div onClick={handleRecuperar} style={{
                color: "#EE6E2D",
                display: "flex",
                marginLeft: "3px",
                textDecoration: "underline",
                cursor: "pointer",
              }}>Recuperala</div></div>
              <div className="registrate">
                ¿Usuario nuevo?{" "}
                <Link
                  to="/Registrate"
                  style={{
                    color: "#EE6E2D",
                    display: "flex",
                    marginLeft: "3px",
                  }}
                >
                  Registate
                </Link>
              </div>
              <div className="separadorIn" />
              <div className="botonesRedesIn">
                {/* Lo saco hasta tener registrada la marca Tulu<FacebookLoginButton
                  onClick={() => handdleOnClick(facebookProvider)}
                >
                  {dimensions.width > 479 && (
                    <span>Inicia sesión con Facebook</span>
                  )}
                  {dimensions.width <= 479 && (
                    <span className="textoIn">Ingresá con Facebook</span>
                  )}
                </FacebookLoginButton> */ }
                <GoogleLoginButton
                  onClick={() => handdleOnClick(googleProvider)}
                >
                  {dimensions.width > 479 && (
                    <span>Inicia sesión con Google</span>
                  )}
                  {dimensions.width <= 479 && (
                    <span className="textoIn">Ingresá con Google</span>
                  )}
                </GoogleLoginButton>
                <TwitterLoginButton
                  onClick={() => handdleOnClick(twitterProvider)}
                >
                  {dimensions.width > 479 && (
                    <span>Inicia sesión con Twitter</span>
                  )}
                  {dimensions.width <= 479 && (
                    <span className="textoIn">Ingresá con Twitter</span>
                  )}
                </TwitterLoginButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={openModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="ContainerCardIn">
          <div className="cardIn">
            <h1 className="tituloIn">Te enviaremos un mail para que recuperes tu contraseña</h1>
            <h2 className="subtituloIn">Ingresá tu mail</h2>
            <div className="centradoIn">

              <TextField
                id="outlined-required"
                label="Mail"
                onChange={(e) => setMailRecuperoUsuario(e.target.value.trim())}
                value={`${mailRecuperoUsuario}`}
                color="warning"
                sx={{ width: "calc(70% - 10px)", marginTop: "30px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton sx={{ p: "10px" }} aria-label="menu" disabled>
                        <PersonIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              //helperText={helper2? "Ingrese un mail valido. ejemplo@mail.com" : "" }
              />
              {isRunning && <div style={{color:"#4a934a"}}>¡Listo! Verifica tu casilla de correo electrónico</div>}
              <div style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  sx={{
                    width: "9rem",
                    marginTop: "20px",
                    height: "50px",
                    marginBottom: "20px",
                  }}
                  onClick={() => setOpenModal(false)}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  disabled={isRunning && timeLeft > 0}
                  onClick={handleEnviar}
                  sx={{
                    width: "10rem",
                    marginTop: "20px",
                    height: "50px",
                    marginBottom: "20px",
                    marginLeft: "20px"
                  }}
                >
                  {isRunning ? `Enviar ${Math.floor(timeLeft / 60)}:${timeLeft % 60}` : 'Enviar'}
                </Button>

              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Footer />
    </div>
  );
}
