import React, { useState, useEffect } from 'react';
import Barra from '../barra_de_busqueda/barra.jsx'
import Footer from "../footer/footer.jsx";
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import { useLocation } from "react-router-dom";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import './miCuenta.scss'

import axios from 'axios';
axios.defaults.withCredentials = true;

export default function MiCuenta(scroll) {
    const searchParams = new URLSearchParams(window.location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    const { usuario, cambiarNombreUsuario, cambiarMailDeMp, cambiarNombreDeMp, cambiarDNI, token } = useUsuario();
    const [nombre, setNombre] = useState(usuario.nombre);
    const [nombreError, setNombreError] = useState(false);
    const [mailUsuario, setMailUsuario] = useState(usuario.emailMp ? usuario.emailMp : "");
    const [mailUsuarioError, setMailUsuarioError] = useState(false);
    const [nombreApellido, setNombreApellido] = useState(usuario.nombreMp ? usuario.nombreMp : "");
    const [nombreApellidoError, setNombreApellidoError] = useState(false);
    const [dni, setDni] = useState(usuario.DNI ? usuario.DNI : "");
    const [dniError, setDniError] = useState(false);

    const [helper1, setHelper1] = useState(false);
    const [helper2, setHelper2] = useState(false);
    const [helper3, setHelper3] = useState(false);
    const [helper4, setHelper4] = useState(false);
    const [alerta, setAlerta] = useState(false);
    const [alertaError, setAlertaError] = useState(false);
    const [cuentaMpError, setCuentaMpError] = useState(false);

    const [loader, setLoader] = useState(false);

    const location = useLocation();


    useEffect(() => {
        async function fetchData() {
            if (code && state && usuario.email) {
                const response = await axios.post(`https://api.tulu.com.ar/Items/cargarDatosMpUsuario`, {code: code, state: state, email: usuario.email });
                const data = response.data;
                const { error } = data;
                if (error) {
                    setAlertaError(true)
                }
                if (data?.status === 'success') {
                    setAlerta(true)
                    usuario.autorizacionMp = true
                }
            }
        }
        fetchData();
    }, [code, state, token, usuario]);


    useEffect(() => {
        if (location?.state?.scroll) {
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            window.scrollTo(0, 0);
        }
    }, [location])

    useEffect(() => {
        async function fetchData() {

            if (usuario.emailMp !== "" && usuario.nombreMp !== "" && usuario.DNI !== "") {
                const response = await axios.post(`https://api.tulu.com.ar/Items/cargarDatosMpUsuario`, { email: usuario.email });
                const data = response.data;
                var { error } = data
                if (error) {
                    console.log("Error")
                } else {
                    setCuentaMpError(data.status)
                    //setCuentaMpError(true)
                }
            }
        }
        fetchData();
    }, [usuario, token]);


    //const handleInputImage = () =>{}

    const handleCambiarNombre = async () => {
        const nombreRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s[a-zA-ZÀ-ÿ\u00f1\u00d1]+)*$/;;
        const isValidNombre = nombreRegex.test(nombre.trim());

        if (!isValidNombre) {
            setNombreError(true);
            setHelper1(true);
        } else {
            const data = { nombre: nombre, email: usuario.email };

            await axios.post(`https://api.tulu.com.ar/Items/updateNombreUsuario`, data);
            cambiarNombreUsuario(nombre);
            setAlerta(true);
        }
    }

    const handleCambiarCobro =  () => {

        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const isValidEmail = emailRegex.test(mailUsuario);

        const dniRegex = /^\d\d\d\d\d\d\d\d/;
        const isValidDni = dniRegex.test(dni);

        const nombreApellidoRegex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s[a-zA-ZÀ-ÿ\u00f1\u00d1]+)*$/;;
        const isValidNombreApellido = nombreApellidoRegex.test(nombreApellido.trim());

        if (!isValidEmail) {
            setMailUsuarioError(true);
            setHelper2(true);
        }

        if (!isValidNombreApellido) {
            setNombreApellidoError(true);
            setHelper3(true);
        }

        if (!isValidDni) {
            setDniError(true);
            setHelper4(true);
        }

        if (isValidEmail && isValidNombreApellido && isValidDni) {
            setLoader(true);

            axios.post(`https://api.tulu.com.ar/Items/updateCobroUsuario`, { emailMp: mailUsuario, nombreMp: nombreApellido, DNI: dni, email: usuario.email });
            cambiarDNI(dni);
            cambiarMailDeMp(mailUsuario);
            cambiarNombreDeMp(nombreApellido);


            const urlMP = `https://auth.mercadopago.com.ar/authorization?client_id=8271569138673544&response_type=code&platform_id=mp&state=${dni.replace(/\./g, "")}&redirect_uri=https://www.tulu.com.ar/miCuenta`

            window.location.href = urlMP
            //setOpenModal(true);
            //setUrlModal(urlMP)
        }

    }


    return (
        <>
            <div className="cajaCompleta2">
                <div className='fondoCuenta'>
                    <Barra />
                    <div className='cajaPrincipalCuenta'>
                        <div className='centarCuenta'>
                            <div>
                                <h1 className='publicaTuEspacioCuenta'>Mi cuenta</h1>
                                {/*
                            <div className='uploadButtonPu2'>
                                <IconButton color="warning" aria-label="upload picture" component="label" className='camaraButtonPu' >
                                    <input hidden accept="image/*" type="file" onChange={handleInputImage} />
                                    <AccountCircleIcon className='camaritaPu' sx={{ fontSize: '3ch' }}/>
                                </IconButton>                        
                            </div>
                            */}
                            </div>
                        </div>
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '30vw' },
                            }}
                            noValidate
                            autoComplete="on"
                            id='configCuenta'
                        >
                            <div className="textoCuenta">
                                <h1 className="tituloCuenta">Información personal</h1>
                                <h1 className='subtituloCuenta'>Correo electrónico:</h1>
                            </div>
                            <div className='caja2Cuenta'>
                                <TextField
                                    disabled
                                    id="outlined-required"
                                    label={`${usuario.email}`}
                                    variant="filled"
                                    value=''
                                    color="warning"
                                />
                            </div>
                            <div className="textoCuenta">
                                <h1 className='subtituloCuenta'>Nombre completo:</h1>
                            </div>
                            <div className='caja2Cuenta'>
                                <TextField
                                    error={nombreError}
                                    required
                                    id="outlined-required"
                                    label="Nombre completo"
                                    value={`${nombre ? nombre : ""}`}
                                    onChange={(e) => setNombre(e.target.value.trimStart())}
                                    onClick={() => setNombreError(false)}
                                    color="warning"
                                    helperText={helper1 ? "El nombre no puede contener caracteres numéricos ni estar vacío. " : ""}
                                />
                            </div>
                            <div className='confirmarBotonCuenta'>
                                <Button variant="contained" onClick={handleCambiarNombre}>Confirmar</Button>
                            </div>
                            <div className="textoCuenta">
                                <h1 className="tituloCuenta">Configuración de cobros con Mercado Pago</h1>
                            </div>

                            <div className='contenedorConfigMp'>
                                <div>
                                    <div className="textoCuenta">
                                        <h1 className='subtituloCuenta'>Mail de usuario:</h1>
                                    </div>
                                    <div className='caja2Cuenta'>
                                        <TextField
                                            error={mailUsuarioError}
                                            required
                                            id="outlined-required"
                                            label="Mail de usuario"
                                            onChange={(e) => setMailUsuario(e.target.value.trim())}
                                            onClick={() => setMailUsuarioError(false)}
                                            value={`${mailUsuario}`}
                                            color="warning"
                                            helperText={helper2 ? "Ingrese un mail valido. ejemplo@mail.com" : ""}
                                        />
                                    </div>
                                    <div className="textoCuenta">
                                        <h1 className='subtituloCuenta'>Nombre y apellido como figura en el DNI:</h1>
                                    </div>
                                    <div className='caja2Cuenta'>
                                        <TextField
                                            error={nombreApellidoError}
                                            required
                                            id="outlined-required"
                                            label="Nombre completo"
                                            onChange={(e) => setNombreApellido(e.target.value.trimStart())}
                                            onClick={() => setNombreApellidoError(false)}
                                            value={`${nombreApellido}`}
                                            color="warning"
                                            helperText={helper3 ? "El nombre no puede contener caracteres numéricos ni estar vacío. " : ""}
                                        />
                                    </div>
                                    <div className="textoCuenta">
                                        <h1 className='subtituloCuenta'>DNI:</h1>
                                    </div>
                                    <div className='caja2Cuenta'>
                                        <TextField
                                            error={dniError}
                                            required
                                            id="outlined-required"
                                            onChange={(e) => setDni(e.target.value.trim())}
                                            onClick={() => setDniError(false)}
                                            label="DNI"
                                            value={`${dni}`}
                                            color="warning"
                                            inputProps={{ maxLength: 10 }}
                                            helperText={helper4 ? `Ingrese un número de documento válido, sin incluir la separacíon de miles "." Ej: 12459752` : ""}
                                        />
                                    </div>
                                </div>
                                {cuentaMpError &&
                                    <div className='alertaConfigMp'>
                                        ¡Atención! Aunque ya has completado todos los campos requeridos, debes permitir el acceso a tu cuenta de Mercado Pago para poder utilizar este servicio. Si no realizas esta acción, no podrás recibir tu dinero. Por favor, al darle nuevamente en el boton de <strong className='confirmarAlertaMp'>"CONFIRMAR"</strong>, asegúrate de permitir el acceso a tu cuenta de Mercado Pago.¡Gracias por tu atención!
                                    </div>
                                }
                            </div>
                            <div className='confirmarBotonCuenta'>
                                {!loader && <Button variant="contained" onClick={handleCambiarCobro}>Confirmar</Button>}
                                {loader && <CircularProgress color="warning" />}
                            </div>
                        </Box>
                    </div>
                </div>
                <Footer />
            </div>

            {alerta && <Alert onClose={() => { setAlerta(false) }} className="posicionAlerta" variant="filled" severity="success">¡Los cambios se guardaron con éxito!</Alert>}
            {alertaError && <Alert onClose={() => { setAlertaError(false) }} className="posicionAlerta" variant="filled" severity="error">Hubo un error al guardar los cambios, intenta nuevamente.</Alert>}
            {/*<Modal
                open={openModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >   
                <div className='containerModal'>
                    <iframe src={urlModal} width="50%" height="70%" title="modal" className='modalCuenta'></iframe>
                </div>
            </Modal>*/}
        </>
    );
}
