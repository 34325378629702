import React, { useEffect, useRef, useState } from "react";
/* global google */

export default function SearchBar (props) {
  const autocompleteInput = useRef(null);
  const [value, setValue] = useState(props.ubi);
  let autocomplete = null;

  
  useEffect(() => {
    autocomplete = new google.maps.places.Autocomplete(
      autocompleteInput.current,
      { types: ["geocode"] }
      );
      
      autocomplete.addListener("place_changed", handlePlaceChanged);
    }, []);
    
    useEffect(() => {
      setValue(props.ubi)
    }, [props.ubi]);

  const handlePlaceChanged = () => {
    const place = autocomplete.getPlace();
    props.onPlaceLoaded(place);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    if(props.error===true){
      props.setError(0)
    }
  }

  return (
      <input
        ref={autocompleteInput}
        id="autocomplete"
        placeholder="Ingrese su ubicacion...*"
        type="text"
        className={props.error?"autoCompleteBoxError":"autoCompleteBox"}
        onChange={handleChange}
        value={value}
      ></input>
  );
};
