import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Barra from "../barra_de_busqueda/barra.jsx";
import "./publicacion.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ImageSwipe from "../imagenes/imagenesSwipe.jsx";
import PublicacionL from "../loaders/publicacionL.jsx";

import lottie from "lottie-web";
import likeButton from "../assets/likeButton.json";

//El rating estatico lo podes encontrar aca https://mui.com/material-ui/react-rating/
import Rating from "@mui/material/Rating";
import { Link, useNavigate } from "react-router-dom";

import { FaPaperPlane } from "react-icons/fa";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import loading from "../assets/loader4.json";

import useAlgoliaInsights from "../hooks/useAlgoliaInsights.js";
import algoliarecommend from "@algolia/recommend";
//import aa from "search-insights";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import axios from "axios";

import Alert from "@mui/material/Alert";

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

axios.defaults.withCredentials = true;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//const center = {lat: -59.0276, lng: -34.0987}

export default function Publicacion() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const like = useRef(null);
  const [clickLike, setClickLike] = useState(
    location.state ? (location.state.like ? location.state.like : false) : false
  );

  const [busqueda, setBusqueda] = useState(
    location.state ? (location.state.bus ? location.state.bus : "") : ""
  );
  const [cargarLikes, setCargarLikes] = useState(true);
  //const [altura, setAltura] = useState(0);

  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const { usuario, token } = useUsuario();
  const [item, setItem] = useState(undefined);
  const [loadItem, setLoadItem] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [textoComentario, setTextoComentario] = useState("");

  const [mostrarComentario, setMostrarComentario] = useState("");

  const [errorComentario, setErrorComentario] = useState(false);
  //const [errorComentario2, setErrorComentario2] = useState(false);


  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');

  const {
    buyProduct,
    sendProductView,
    buyProductCoockie,
    sendProductViewCoockie,
  } = useAlgoliaInsights();
  const client = algoliarecommend(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
  );
  const [relatedProducts, setRelatedProducts] = useState([]);

  //Es para aquellos productos que se compran en conjunto, acutalmente no se esta utilizando pero
  //para el futuro podria servir.
  //const [frequentlyBoughtTogether, setFrequentlyBoughtTogether] = useState([]);
  const animationContainer = useRef(null);

  const [open, setOpen] = useState(false);

  const [reseña, setReseña] = useState(true);

  const [alerta, setAlerta] = useState(false);

  const [puntaje, setPuntaje] = useState({});

  const [value, setValue] = useState(0);
  const [eliminado, setEliminado] = useState(false);

  const [textoWp, setTextoWp] = useState("")

  const [deshabilitarBoton, setDeshabilitarBoton] = useState(
    item?.autorizacionMp ? false : true
  );

  useEffect(() => {
    if (item) {
      setLat(item.location.coordinates[0]);
      setLng(item.location.coordinates[1]);

      if(window.location.href){
        if(item.numTel){
          let texto = "Hola, ¿cómo estás? He visto tu publicación en Tulu y me gustaría obtener más información al respecto. ¿Podrías proporcionarme detalles adicionales? ¡Gracias!";
          let encodeUrl = encodeURIComponent(window.location.href);
          
          texto = `${texto}\n\n${encodeUrl}`
          encodeUrl = encodeURIComponent(texto);

          setTextoWp(`https://wa.me/54${item.numTel}?text=${encodeUrl}`); 
        }
      }
    }
  }, [item])

  useEffect(() => {
    setBusqueda(
      location.state ? (location.state.bus ? location.state.bus : "") : ""
    );
  }, [location]);

  useEffect(() => {
    const instanceLike = lottie.loadAnimation({
      container: like.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: likeButton,
    });

    if (cargarLikes) {
      if (!clickLike) {
        instanceLike.goToAndStop(0, true);
      } else {
        instanceLike.goToAndStop(51, true);
      }
    } else if (clickLike) {
      instanceLike.playSegments([10, 51], true);
      instanceLike.setSpeed(2);
    } else {
      instanceLike.goToAndStop(0, true);
    }
    return () => {
      instanceLike.destroy();
    };
  }, [clickLike, like?.current, loadItem]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setDeshabilitarBoton(item ? (item.autorizacionMp ? false : true) : true);
  }, [item]);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loading,
    });
  }, []);

  useLayoutEffect(() => {
    if (item) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, [item]);

  useEffect(() => {
    setLoadItem(true);
    var url;
    //este me trae tanto el item como los comentarios
    if (usuario) {
      url = `https://api.tulu.com.ar/Items/obtenerItemsComentarios?idItem=${id}&email=${usuario?.email}`;
    } else {
      url = `https://api.tulu.com.ar/Items/obtenerItemsComentarios?idItem=${id}`;
    }
    async function fetchData() {
      try{
        const response = await axios.get(url);
        const data = response.data;
        var { error } = data;
  
        if (error) {
          var a
          error === "El item no existe" ? setEliminado(true) : navigate("/inicio");
  
        } else {
          if (usuario) {
            setClickLike(data.fav);
          }
  
          setItem(data);
          setComentarios(data.comentarios.reverse());
        }
        setLoadItem(false);
      }catch(e){
        console.log(e)
        setLoadItem(false);
        setEliminado(true);
      }

    }
    fetchData();
  }, [id]);


  useEffect(() => {
    async function fetchData() {
      const objectID = item?._id;

      if (objectID) {
        if (usuario) {
          sendProductView(objectID, usuario._id);

          const accion = {
            idItem: objectID,
            idUsuario: usuario._id,
            tipo: "view",
            rating: 50,
          };

          await axios.post(
            `https://api.tulu.com.ar/Items/Acciones`,
            accion,
            {
              headers: {
                "Content-Type": "application/json",
              }
            }
          );
        } else {
          sendProductViewCoockie(objectID);
        }

        const response = await axios.get(
          `https://api.tulu.com.ar/Items/idItem/${objectID}`
        );
        const data = response.data;
        setPuntaje({ promedio: data.promedio, cantidad: data.cantidad });

        setValue(data.promedio);
      }

      if (usuario && item) {
        const response = await axios.get(
          `https://api.tulu.com.ar/Items/idResenia/${usuario._id}`
        );
        const data = response.data;
        if (data.Reseñas.length === 0) {
          setReseña(false);
        } else {
          data.Reseñas.map((item2) => {
            if (item2.idItem === item._id) {
              setReseña(true);
            } else {
              setReseña(false);
            }
          });
        }
      }
    }
    fetchData();
  }, [item, usuario]);


  /* useEffect(() => {
            aa('init',{
                appId: process.env.REACT_APP_ALGOLIA_APP_ID,
                apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
                useCookie: true,
            })
        if(usuario){
            aa('set-user-token', usuario._id)
            aa('viewedObjectIDs', {
                userToken: usuario._id,
                index:process.env.REACT_APP_ALGOLIA_INDEX_NAME,
                eventName: 'Product Added to Cart',
                objectIDs: [item?item._id:""]
            });
        }
    },[item,usuario])*/

  useEffect(() => {
    if (item) {
      var aux = 4;
      if (targetRef?.current?.offsetWidth < 1024) {
        aux = 3;
      }
      client
        .getRelatedProducts([
          {
            indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
            objectID: item ? item._id : "",
            maxRecommendations: aux,
          },
        ])
        .then(({ results }) => {
          var aux = [];
          results[0]?.hits?.map((items) => {
            aux.push({
              _id: items.objectID,
              titulo: items.titulo,
              descripcion: items.descripcion,
              zona: items.zona,
              profesiones: items.categoria,
              precio: items.precio,
              usuario: items.usuario,
              metros: items.metrosCuadrados,
              horario: items.horario,
              img: items.imagenes,
              tags: items.tags,
            });
          });

          setRelatedProducts(aux);
        })
        .catch((err) => {
          console.log(err);
        });

      /*client.getFrequentlyBoughtTogether([
                {
                    indexName: 'your_index_name',
                    objectID: 'your_object_id',
                },
            ])
            .then(({ results }) => {
                console.log("results",results);
                setFrequentlyBoughtTogether(results[0].hits);
            })
            .catch(err => {
                console.log(err);
            });*/
    }
  }, [item]);

  /*
  useEffect(() => {
    var alturaContenedor = ref.current ? ref.current.clientHeight : 0;
    var alturaImagen = refImagen.current ? refImagen.current.clientHeight : 0;
    setAltura(alturaContenedor - alturaImagen - 100);
  });*/

  const handdleLikeButton = () => {
    setCargarLikes(false);

    let cancel;

    if (usuario) {
      var click = clickLike;
      setClickLike(!clickLike);

      axios.request({
        method: click ? "DELETE" : "POST",
        url: `https://api.tulu.com.ar/Items/Favoritos`,
        data: { item: item, usuario: usuario.email },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      }).catch((e) => {
        if (axios.isCancel(e)) return;
      });

      return () => cancel();
    } else {
      setOpen(true);
    }
  };

  const handleEnviar = async () => {
    if (usuario === null || usuario === undefined) {
      setErrorComentario(true);
    } else if (usuario._id === null || usuario._id === undefined) {
      setErrorComentario(true);
    } else if (
      textoComentario === null ||
      textoComentario === undefined ||
      textoComentario === ""
    ) {
      //setErrorComentario2(true);
    } else {
      const comentario = {
        idItem: item._id,
        idUsuario: usuario._id,
        nombreUsuario: usuario.nombre,
        texto: textoComentario,
        email: usuario.email,
      };
      const comentario2 = {
        idItem: item._id,
        idUsuario: usuario._id,
        nombre: usuario.nombre,
        texto: textoComentario,
        email: usuario.email,
      };

      await axios.post(`https://api.tulu.com.ar/Items/Comentarios`, comentario, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      setMostrarComentario("");
      setTextoComentario("");
      setComentarios([...comentarios, comentario2]);
    }
  };

  const handleComentario = (e) => {
    setTextoComentario(e.target.value);
    setMostrarComentario(e.target.value);
  };

  const handleReservar = async () => {
    //poner aca cuando tengas la funcionalidad del boton hecha el resto

    //NO TOCAR, ES DE ALGOLIA!
    const objectID = item?._id;
    if (usuario) {
      buyProduct(objectID, usuario._id);

      const accion = {
        idItem: objectID,
        idUsuario: usuario._id,
        tipo: "Purchase",
        rating: 50,
      };

      await axios.post(`https://api.tulu.com.ar/Items/Acciones`, accion, {
        headers: {
          "Content-Type": "application/json"
        }
      });

    } else {
      buyProductCoockie(objectID);
    }

    if (!usuario) {
      navigate("/ingresa", { state: { item: item, desdeReservar: true } });
    } else {
      navigate(`/reserva/${item._id}`, { state: { item: item, user: usuario } });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinuar = () => {
    navigate("/Ingresa", { state: { item: item, desdePublicacion: true } });
  };

  const handleVotar = async (e) => {
    setReseña(true);
    setAlerta(true);
    setValue(
      (puntaje.promedio * puntaje.cantidad + e) / (puntaje.cantidad + 1)
    );

    await axios.post(`https://api.tulu.com.ar/Items/Resenias`, {
      idItem: item._id,
      idUsuario: usuario._id,
      puntaje: e,
    })

  };

  return (
    <>
      <div ref={targetRef}>
        {loadItem && <PublicacionL />}
        {!eliminado && !loadItem && item && (
          <>
            <Barra user={usuario} bus={busqueda} />

            <div className="publicacion ">
              {dimensions.width < 768 && (
                <div>
                  <h1 className="tituloPublicacionP">{item.titulo}</h1>
                  <div className="precioPublicacion">
                    {usuario && !reseña && (
                      <Rating
                        name="half-rating"
                        value={value}
                        size="medium"
                        onChange={(event, newHover) => {
                          handleVotar(newHover);
                        }}
                      />
                    )}

                    {(!usuario || reseña) && (
                      <Rating
                        name="half-rating"
                        value={value}
                        size="medium"
                        readOnly
                      />
                    )}
                    <h1 className="ratingP"> {value ? value : 0} </h1>
                  </div>
                  <div className="contenedorImagenes">
                    <div className="contenedorSwiperResponsive">
                      <ImageSwipe images={item.img} />
                    </div>
                  </div>

                  <div className="noSuperponer">
                    <h2 className="subtituloPublicacion">Descripción</h2>
                    <h3 className="textoPublicacion parrafoPubli">
                      {item.descripcion}
                    </h3>
                    <h2 className="subtituloPublicacion">Ubicación</h2>
                    <h3 className="textoPublicacion">
                      {item.direccion}
                    </h3>
                    <h3 className={`textoCancelar ${!item.numTel && 'margen_existe_wp'}`} >
                      {item.devolucion === "Siempre" ? "Tendrás la opción de cancelar hasta justo antes de asistir." : item.devolucion === "nunca" ? "Este espacio no permite cancelaciones." : item.devolucion === "24hs" ? "Podrás cancelar este espacio hasta 24 horas antes de asistir." : item.devolucion === "48hs" ? "Podrás cancelar este espacio hasta 48 hs antes de asistir." : item.devolucion === "72hs" ? "Podrás cancelar este espacio hasta 72 hs antes de asistir." : item.devolucion === "96hs" ? "Podrás cancelar este espacio hasta 96 hs antes de asistir." : ""}
                    </h3>
                    <h3 className="textoPublicacion">
                    </h3>


                    {item.numTel && 
                      <>
                        <h2 className="subtituloPublicacion">WhatsApp</h2>
                        <div className="wp_cotainer">
                        <Button 
                          variant="outlined" 
                          startIcon={<WhatsAppIcon sx={{'@media (max-width: 800px)': {height:"3vw"}}}/>}
                          sx={{
                            '@media (max-width: 800px)': {fontSize: '1.5vw'},
                            marginBottom: '3vw'
                          }}
                          onClick={() => { window.open(textoWp) }}
                          >
                            Enviar mensaje
                          </Button>
                        </div>
                      </>
                    }


                    <div className="contenedorReserva">
                      <div className="contenedorPrecioP">
                        {item.metros && <h3 className="metrosP">{item.metros} m²</h3>}
                        <div className="precioPublicacion">
                          <h1 className="precioP">${item.precio} </h1>{" "}
                          <h3 className="porHoraP">por hora</h3>
                        </div>
                      </div>
                      <button
                        className={
                          /* PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                          deshabilitarBoton ? "botonReservaDes" : */"botonReserva"
                        }
                        onClick={/*PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                        deshabilitarBoton ? undefined :*/
                        handleReservar}
                      >
                        {" "}
                        Reservar{" "}
                      </button>
                      <button
                        className="botonLikeP"
                        onClick={() => {
                          handdleLikeButton();
                        }}
                      >
                        <div
                          ref={like}
                          onClick={() => {
                            handdleLikeButton();
                          }}
                        />
                      </button>
                    </div>
                    {/* PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                    deshabilitarBoton && (
                      <p className="errorPagosPu">
                        El propietario de este espacio no tiene un sistema de
                        pagos habilitado.
                      </p>
                    )*/}

                    <h1 className="tagsP">
                      {item.profesiones?.map((item) => {
                        return `${item} `;
                      })}
                      {item.tags?.map((item) => {
                        return `${item} `;
                      })}
                    </h1>
                  </div>

                  <div>
                    <div>
                      {relatedProducts && relatedProducts.length > 0 && (
                        <>
                          <h1 className="recomendacionesP">
                            Estos espacios podrían interesarte
                          </h1>
                          <div className="precioPublicacion contenedorCardsP">
                            {relatedProducts &&
                              relatedProducts?.map((recomendado, index) => (
                                <Link
                                  to={`/publicacion/${recomendado._id}`}
                                  state={{ user: usuario }}
                                  className="nada"
                                  key={index}
                                >
                                  <Card sx={{ maxWidth: 345 }} className="cardP">
                                    <CardActionArea>
                                      <CardMedia
                                        component="img"
                                        image={recomendado.img[0]}
                                        alt="green iguana"
                                        className="imagenCardP"
                                      />
                                      <CardContent>
                                        <Typography
                                          gutterBottom
                                          variant="h5"
                                          component="div"
                                        >
                                          <div className="precioPublicacion">
                                            <h1 className="precioCardP">
                                              ${recomendado.precio}{" "}
                                            </h1>{" "}
                                            <h3 className="porHoraP">por hora</h3>
                                          </div>
                                        </Typography>
                                        <h1 className="textCardP">
                                          {recomendado.titulo}
                                        </h1>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Link>
                              ))}
                          </div>
                        </>)}


                      <h1 className="recomendacionesP">Ubicación</h1>

                      <div className="contenedorMapa">
                        <iframe width="90%" height="700" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas"
                          src={`https://maps.google.com/maps?hl=es&q=@${lat},${lng}&t=&z=16&ie=UTF8&iwloc=B&output=embed`}>
                        </iframe>
                      </div>
                      <h1 className="recomendacionesP">Comentarios</h1>
                      <div className="precioPublicacion contenedorComentariosP">
                        <div className="centroBarraP subirPub">
                          <input
                            placeholder="Deja tu comentario"
                            value={mostrarComentario}
                            className={
                              errorComentario
                                ? "comentarioPubli comentarioP comentarioPError"
                                : "comentarioPubli comentarioP"
                            }
                            onChange={(e) => {
                              handleComentario(e);
                            }}
                          />
                        </div>

                        <button
                          className="enviarComentarioP"
                          onClick={handleEnviar}
                        >
                          {" "}
                          Enviar <FaPaperPlane />{" "}
                        </button>
                      </div>

                      {comentarios && comentarios.length > 0 ? (
                        comentarios
                          ?.slice(0)
                          .reverse()
                          .map((comentarios, index) => (
                            <div
                              className="contenedorDeComentariosP"
                              key={index}
                            >
                              <div className="comentariosP">
                                <h1 className="userP">{comentarios.nombre}</h1>
                                <h2 className="comentarioUserP">
                                  {comentarios.texto}
                                </h2>
                              </div>
                            </div>
                          ))
                      ) : (
                        <div className="centrarPubli">
                          <h1 className="no_hay_comentarios">
                            Actualmente no hay comentarios, ¡Podes ser el
                            primero!
                          </h1>
                        </div>
                      )}

                      <div className="espacioP"> </div>
                    </div>
                  </div>
                </div>
              )}
              {dimensions.width >= 768 && (
                <div className="">
                  <div className="row cajaPubli">
                    <div className="col-sm-6">
                      <div className="sticky-top contenedorSwiperResponsive">
                        <ImageSwipe images={item.img} />
                      </div>
                    </div>

                    <div className="col-sm-6 margenLeft ">
                      <h1 className="tituloPublicacionP">{item.titulo}</h1>

                      <div className="precioPublicacion">
                        {usuario && !reseña && (
                          <Rating
                            name="half-rating"
                            value={value}
                            precision={0.5}
                            size="large"
                            onChange={(event, newHover) => {
                              handleVotar(newHover);
                            }}
                          />
                        )}

                        {(!usuario || reseña) && (
                          <Rating
                            name="half-rating"
                            value={value}
                            precision={0.5}
                            size="large"
                            readOnly
                          />
                        )}

                        <h1 className="ratingP"> {value ? value : 0} </h1>
                      </div>

                      <h2 className="subtituloPublicacion">Descripción</h2>
                      <h3 className="textoPublicacion parrafoPubli">
                        {item.descripcion}
                      </h3>

                      <h2 className="subtituloPublicacion">Ubicación</h2>
                      <h3 className="textoPublicacion">
                        {item.direccion}
                      </h3>

                      {item.numTel && 
                        <>
                          <h2 className="subtituloPublicacion">WhatsApp</h2>
                          <div className="wp_cotainer">
                          <Button 
                            variant="outlined" 
                            startIcon={<WhatsAppIcon sx={{'@media (max-width: 1500px)': {height:"1.5vw"}}}/>}
                            sx={{
                              '@media (max-width: 1500px)': {fontSize: '0.9vw'},
                              marginBottom: '3vw'
                            }}
                            onClick={() => { window.open(textoWp) }}
                            >
                              Enviar mensaje
                            </Button>
                          </div>
                        </>
                      }
                    
                    



                      <h3 className={`textoCancelar ${!item.numTel && 'margen_existe_wp'}`}>
                        {item.devolucion === "Siempre" ? "Tendrás la opción de cancelar hasta justo antes de asistir." : item.devolucion === "nunca" ? "Este espacio no permite cancelaciones." : item.devolucion === "24hs" ? "Podrás cancelar este espacio hasta 24 horas antes de asistir." : item.devolucion === "48hs" ? "Podrás cancelar este espacio hasta 48 hs antes de asistir." : item.devolucion === "72hs" ? "Podrás cancelar este espacio hasta 72 hs antes de asistir." : item.devolucion === "96hs" ? "Podrás cancelar este espacio hasta 96 hs antes de asistir." : ""}
                      </h3>

                      <div className="contenedorReserva">
                        <div className="contenedorPrecioP">
                          {item.metros && <h3 className="metrosP">{item.metros} m²</h3>}
                          <div className="precioPublicacion">
                            <h1 className="precioP">${item.precio} </h1>{" "}
                            <h3 className="porHoraP">por hora</h3>
                          </div>
                        </div>
                        <button
                          className={
                            /* PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                            deshabilitarBoton
                              ? "botonReservaDes"
                              : */"botonReserva"
                          }
                          onClick={/* PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                            deshabilitarBoton ? undefined :*/ handleReservar
                          }
                        >
                          {" "}
                          Reservar{" "}
                        </button>
                        <button
                          className="botonLikeP"
                          onClick={() => {
                            handdleLikeButton();
                          }}
                        >
                          <div
                            ref={like}
                            onClick={() => {
                              handdleLikeButton();
                            }}
                          />
                        </button>
                      </div>
                      {/*PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
                      deshabilitarBoton && (
                        <p className="errorPagosPu">
                          El propietario de este espacio no tiene un sistema de
                          pagos habilitado.
                        </p>
                      )*/}

                      <h1 className="tagsP">
                        {item.profesiones?.map((item) => {
                          return `${item} `;
                        })}
                        {item.tags?.map((item) => {
                          return `${item} `;
                        })}
                      </h1>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {dimensions.width >= 768 && (
              <div className="noSuperponer margenPubli">
                {relatedProducts && relatedProducts.length > 0 && (
                  <>
                    <h1 className="recomendacionesP">
                      Estos espacios podrían interesarte
                    </h1>
                    <div className="precioPublicacion contenedorCardsP">
                      {relatedProducts &&
                        relatedProducts?.map((recomendado, index) => (
                          <Link
                            to={`/publicacion/${recomendado._id}`}
                            state={{ user: usuario }}
                            className="nada"
                            key={index}
                          >
                            <Card sx={{ maxWidth: 345 }} className="cardP">
                              <CardActionArea>
                                <CardMedia
                                  component="img"
                                  image={recomendado.img[0]}
                                  alt="green iguana"
                                  className="imagenCardP"
                                />
                                <CardContent>
                                  <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                  >
                                    <div className="precioPublicacion">
                                      <h1 className="precioCardP">
                                        ${recomendado.precio}{" "}
                                      </h1>{" "}
                                      <h3 className="porHoraP">por hora</h3>
                                    </div>
                                  </Typography>
                                  <h1 className="textCardP">
                                    {recomendado.titulo}
                                  </h1>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Link>
                        ))}
                    </div>
                  </>)}
                <h1 className="recomendacionesP">Ubicación</h1>

                <div className="contenedorMapa">
                  <iframe width="90%" height="700" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas"
                    src={`https://maps.google.com/maps?hl=es&q=@${lat},${lng}&t=&z=16&ie=UTF8&iwloc=B&output=embed`}>
                  </iframe>
                </div>


                <h1 className="recomendacionesP">Comentarios</h1>
                <div className="precioPublicacion contenedorComentariosP">
                  <div className="centroBarraP ">
                    <input
                      placeholder="Deja tu comentario"
                      value={mostrarComentario}
                      className={
                        errorComentario
                          ? "comentarioPubli comentarioP comentarioPError"
                          : "comentarioPubli comentarioP"
                      }
                      onChange={(e) => {
                        handleComentario(e);
                      }}
                    />
                  </div>

                  <button className="enviarComentarioP" onClick={handleEnviar}>
                    {" "}
                    Enviar <FaPaperPlane />{" "}
                  </button>
                </div>
                {errorComentario && (
                  <h8 className="errorComentario">
                    Debes iniciar sesión para dejar comentarios
                  </h8>
                )}

                {comentarios && comentarios.length > 0 ? (
                  comentarios
                    ?.slice(0)
                    .reverse()
                    .map((comentarios, index) => (
                      <div className="contenedorDeComentariosP" key={index}>
                        <div className="comentariosP">
                          <h1 className="userP">{comentarios.nombre}</h1>
                          <h2 className="comentarioUserP">
                            {comentarios.texto}
                          </h2>
                        </div>
                      </div>
                    ))
                ) : (
                  <div className="centrarPubli">
                    <h1 className="no_hay_comentarios">
                      Actualmente no hay comentarios, ¡Podes ser el primero!
                    </h1>
                  </div>
                )}

                <div className="espacioP"> </div>
              </div>
            )}
          </>
        )}
      </div>
      {!item && eliminado && (
        <>
          <Barra user={usuario} bus={busqueda} />

          <div className="loSentimos alturaNoTienesRe">
            Lamentablemente, la publicación que estás buscando <br></br>ya ha sido
            eliminada o no está disponible temporalmente. <br></br>
            <br></br> :({" "}
          </div>
        </>
      )}
      {/*!item && (
        <div className="divGeneralCargarPu">
          <Barra user={usuario} bus={busqueda}/>
          <div classname="centrarDivPuCarg">
            <div className="loader">
              <div className="loader2" ref={animationContainer}></div>
            </div>
          </div>
        </div>
      )*/}

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {"Debes iniciar sesión o registrarte para guardar esta publicación."}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleContinuar}>Ingresar/Registrarse</Button>
        </DialogActions>
      </Dialog>

      {alerta && (
        <Alert
          onClose={() => {
            setAlerta(false);
          }}
          className="posicionAlerta"
          variant="filled"
          severity="success"
        >
          ¡Tu reseña se envió con éxito!
        </Alert>
      )}
    </>
  );
}
