import React, { useRef, useEffect } from 'react';
import icono from '../assets/Tulu-azul.webp'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import search from '../assets/search.webp'

import lottie from "lottie-web"
import menu from '../assets/hamburguerMenu.json'
import puerta from '../assets/puertita.webp'
import '../barra_de_busqueda/barra.scss'

import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';


export default function BarraL() {
    const animationContainer = useRef(null);

    useEffect(() => {
        const instance = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: menu
        });
        instance.goToAndStop(18, true);
        return () => instance.destroy();
    }, []);

    return (
        <div className="navBarBarra">
            <div className="navegacionBarra1">
                <div><img src={icono} alt="logo" className="logoBarra" /></div>
                <div><img src={puerta} alt="logo" className="puertaBarra" /></div>


                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="centroBarra">
                        <div className='busquedaU'>
                            <InputGroup className="border-none">
                                <Form.Control disabled color="withe" className="shadow-none searchBarra2 Mui-disabled" style={{ backgroundColor: 'transparent' }}
                                    placeholder="¿Qué estás buscando?"
                                />
                            </InputGroup>
                        </div>

                    </div>
                </div>

                <div className="centroBarra1">
                    <Skeleton>
                        <div className="letras2">Creá tu cuenta</div>
                        <div className="letras2">Ingresá</div>
                    </Skeleton>
                    <div><button className="publicaDeshabilitado">Publicá tu espacio</button> </div>
                </div>

                <React.Fragment key={'right'} >
                    <Button disabled className='drawer-barra' sx={{
                        '@media all and (min-width: 1024px)': {
                            display: 'none',
                        },
                        '@media all and (min-width: 768px) and (max-width: 1024px)': {
                            display: 'flex',
                        },
                    }}><div className='animation-container' ref={animationContainer} /></Button>
                </React.Fragment>
            </div>
        </div>
    )
}
