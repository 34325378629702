import * as React from "react";
import { useEffect, useState, useCallback } from "react";

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import "./calendario.scss"




const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EE6E2D",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookDrivingSlot({ horas, item, diasSelec, horariosReservados }) {


  const [diasSeleccionados, setDiasSeleccionados] = useState([]);
  const [cantidadDeHoras, setCantidadDeHoras] = useState(0);
  const [idDiasHorario, setidDiasHorario] = useState(0);

  const [bookingDate, setBookingDate] = useState(null);
  const [bookingTimes, setBookingTimes] = useState([]);

  const [timesLu, setTimesLu] = useState([]);
  const [timesMa, setTimesMa] = useState([]);
  const [timesMi, setTimesMi] = useState([]);
  const [timesJu, setTimesJu] = useState([]);
  const [timesVi, setTimesVi] = useState([]);
  const [timesSa, setTimesSa] = useState([]);
  const [timesDo, setTimesDo] = useState([]);

  const [open, setOpen] = React.useState(false);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  const [value, onChange] = useState(new Date());

  const dias = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
  


  const [loader, setLoader] = useState(false);

  const state = {
    locale: "es",
    value: new Date(),
  };

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])


  useEffect(() => {

    var dias = Object.keys(item.horario);
    var cadenaEntera = "";


    dias.forEach((dia) => {
      var horario = []
      var horaInicio = parseInt(new Date(item.horario[dia].horaInicio)?.toLocaleTimeString('es-AR'));
      var horaFin = parseInt(new Date(item.horario[dia].horaFin)?.toLocaleTimeString('es-AR'));

      if (horaFin === 0) {
        horaFin = 24;
      }

      if (item.horario[dia].horaInicio != null) {

        if (horaInicio > horaFin) {
          while (horaInicio !== horaFin) {
            if (horaInicio + 1 === 24) {
              horario.push([{ rango: "23:00 - 00:00", reservado: false }]);
              horaInicio = horaInicio + 1;
            } else if (horaInicio === 24) {
              horario.push([{ rango: "00:00 - 01:00", reservado: false }]);
              horaInicio = 1;
            } else {
              cadenaEntera = horaInicio + ":00 - " + (horaInicio + 1) + ":00"
              horario.push([{ rango: cadenaEntera, reservado: false }]);
              horaInicio = horaInicio + 1;
            }
          }
        } else {
          while (horaInicio < horaFin) {
            if (horaInicio + 1 === 24) {
              horario.push([{ rango: "23:00 - 00:00", reservado: false }]);
              horaInicio = horaInicio + 1;
            } else {
              cadenaEntera = horaInicio + ":00 - " + (horaInicio + 1) + ":00"
              horario.push([{ rango: cadenaEntera, reservado: false }]);
              horaInicio = horaInicio + 1;
            }
          }
        }

      }

      if (dia === "lunes") {
        setTimesLu(horario);
      }
      if (dia === "martes") {
        setTimesMa(horario);
      }
      if (dia === "miercoles") {
        setTimesMi(horario);
      }
      if (dia === "jueves") {
        setTimesJu(horario);
      }
      if (dia === "viernes") {
        setTimesVi(horario);
      }
      if (dia === "sabado") {
        setTimesSa(horario);
      }
      if (dia === "domingo") {
        setTimesDo(horario);
      }
    })

  }, [item.horario])

  const megaFuncion = useCallback (async () => {
    // Bail out if there is no date selected
    if (!bookingDate) return;
    setLoader(true);

    let newBookingTimes;

    if (!newBookingTimes) {


      if (bookingDate.getDay() === 1) {
        newBookingTimes = (timesLu);
      }
      if (bookingDate.getDay() === 2) {
        newBookingTimes = (timesMa);
      }
      if (bookingDate.getDay() === 3) {
        newBookingTimes = (timesMi);
      }
      if (bookingDate.getDay() === 4) {
        newBookingTimes = (timesJu);
      }
      if (bookingDate.getDay() === 5) {
        newBookingTimes = (timesVi);
      }
      if (bookingDate.getDay() === 6) {
        newBookingTimes = (timesSa);
      }
      if (bookingDate.getDay() === 0) {
        newBookingTimes = (timesDo);
      }

      // Update cache with new time slots for the selected date

    }

    await setBookingTimes(newBookingTimes);
    setLoader(false);

  }, [bookingDate, setLoader, setBookingTimes, timesLu, timesMa, timesMi, timesJu, timesVi, timesSa, timesDo]);

  const recervadosRecientemente = useCallback( () => {
    if (bookingDate) {
      diasSeleccionados.forEach((horario) => {
        let fecha = new Date(horario.fecha);
        if (bookingDate.getMonth() === fecha.getMonth() && bookingDate.getDate() === fecha.getDate()) {
          if (bookingDate.getDay() === 1) {
            timesLu.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 2) {
            timesMa.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 3) {
            timesMi.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 4) {
            timesJu.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 5) {
            timesVi.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 6) {
            timesSa.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 0) {
            timesDo.forEach((hora) => {
              if (hora[0].rango === horario.horario) {
                hora[0].reservado = true;
              }
            })
          }
        }
      })
    }
  }, [bookingDate, timesDo, timesJu, timesLu, timesMa, timesMi, timesSa, timesVi,diasSeleccionados]);

  const resetearHorarios = useCallback(() => {
    timesLu.forEach((horario) => {
      horario[0].reservado = false;
    })
    timesMa.forEach((horario) => {
      horario[0].reservado = false;
    })
    timesMi.forEach((horario) => {
      horario[0].reservado = false;
    })
    timesJu.forEach((horario) => {
      horario[0].reservado = false;
    })
    timesVi.forEach((horario) => {
      horario[0].reservado = false;
    })
    timesSa.forEach((horario) => {
      horario[0].reservado = false;
    })

    timesDo.forEach((horario) => {
      horario[0].reservado = false;
    })
  },[timesDo, timesJu, timesLu, timesMa, timesMi, timesSa, timesVi])

  const yaReservadosPreviamente = useCallback(() => {
    if (bookingDate) {
      horariosReservados.forEach((horario) => {
        let fecha = new Date(horario.fecha);
        if (bookingDate.getMonth() === fecha.getMonth() && bookingDate.getDate() === fecha.getDate()) {
          if (bookingDate.getDay() === 1) {
            timesLu.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 2) {
            timesMa.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 3) {
            timesMi.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 4) {
            timesJu.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 5) {
            timesVi.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 6) {
            timesSa.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }
          if (bookingDate.getDay() === 0) {
            timesDo.forEach((hora) => {
              if (hora[0].rango === horario.hora) {
                hora[0].reservado = true;
              }
            })
          }

        }
      })
    }

  },[bookingDate, timesDo, timesJu, timesLu, timesMa, timesMi, timesSa, timesVi,horariosReservados])


  useEffect(() => {
    resetearHorarios();
    yaReservadosPreviamente();
    recervadosRecientemente();
    megaFuncion();
  }, [bookingDate,megaFuncion,recervadosRecientemente,resetearHorarios,yaReservadosPreviamente]);

  const onDateChange = e => {
    const fecha = e.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
    setBookingDate(new Date(fecha));
    
    if (screenWidth < 900) {
      setOpen(true)
    }

  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleSeleccionHorario = async time => {

    var original = diasSeleccionados;

    const [start] = time[0].rango.split(" - ");
    const [startHour, startMinute] = start.split(":");

    const date = new Date(bookingDate);
    date.setHours(startHour);
    date.setMinutes(startMinute);

    var nuevo = {
      id: idDiasHorario,
      fecha: date,
      horario: time[0].rango,
    }

    original.push(nuevo);

    original.sort((a, b) => {
      const fechaA = a.fecha;
      const fechaB = b.fecha;
      const horarioA = a.horario;
      const horarioB = b.horario;

      if (fechaA < fechaB) {
        return -1;
      }
      if (fechaA > fechaB) {
        return 1;
      }

      if (fechaA === fechaB && horarioA < horarioB) {
        return -1;
      }

      if (fechaA === fechaB && horarioA > horarioB) {
        return 1;
      }
      return 0;
    })

    if (bookingDate.getDay() === 1) {
      var originalLu = timesLu;
      originalLu.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesLu(originalLu);
    }
    if (bookingDate.getDay() === 2) {
      var originalMa = timesMa;
      originalMa.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesMa(originalMa);
    }
    if (bookingDate.getDay() === 3) {
      var originalMi = timesMi;
      originalMi.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesMi(originalMi);
    }
    if (bookingDate.getDay() === 4) {
      var originalJu = timesJu;
      originalJu.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesJu(originalJu);
    }
    if (bookingDate.getDay() === 5) {
      var originalVi = timesVi;
      originalVi.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      setTimesVi(originalVi);
    }
    if (bookingDate.getDay() === 6) {
      var originalSa = timesSa;
      originalSa.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesSa(originalSa);
    }
    if (bookingDate.getDay() === 0) {
      var originalDo = timesDo;
      originalDo.forEach((horario) => {
        if (horario[0].rango === time[0].rango) {
          horario[0].reservado = true;
        }
      })
      await setTimesDo(originalDo);
    }

    await setDiasSeleccionados(original)
    await horas(cantidadDeHoras + 1)
    await diasSelec(original)
    await setCantidadDeHoras(cantidadDeHoras + 1)
    await setidDiasHorario(idDiasHorario + 1)

  }

  const handleEliminar = (indexItem) => {
    const nuevosHorarios = diasSeleccionados.filter((horario, index) => index !== indexItem);

    if (diasSeleccionados[indexItem].fecha.getDay() === 1) {
      var originalLu = timesLu;
      originalLu.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesLu(originalLu);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 2) {
      var originalMa = timesMa;
      originalMa.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesMa(originalMa);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 3) {
      var originalMi = timesMi;
      originalMi.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesMi(originalMi);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 4) {
      var originalJu = timesJu;
      originalJu.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesJu(originalJu);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 5) {
      var originalVi = timesVi;
      originalVi.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesVi(originalVi);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 6) {
      var originalSa = timesSa;
      originalSa.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesSa(originalSa);
    }
    if (diasSeleccionados[indexItem].fecha.getDay() === 0) {
      var originalDo = timesDo;
      originalDo.forEach((horario) => {
        if (horario[0].rango === diasSeleccionados[indexItem].horario) {
          horario[0].reservado = false;
        }
      })
      setTimesDo(originalDo);
    }

    horas(cantidadDeHoras - 1)
    setCantidadDeHoras(cantidadDeHoras - 1)
    setDiasSeleccionados(nuevosHorarios)
    diasSelec(nuevosHorarios)
  }

  var today = new Date();
  var day = today.getDate();
  var month = today.getMonth();
  var year = today.getFullYear();
  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return (
    <div>
        <div className="k-my-8">
          <div className="k-flex k-display-flex k-mb-4" >
          </div>
          <div className="k-flex k-display-flex k-mb-4" >
            <Calendar value={bookingDate} onChange={onDateChange} minDate={new Date()} locale="es-ES" formatShortWeekday={(locale, date) => dias[date.getDay()]}/>
            <div className="k-ml-4 k-display-flex k-flex-col esconder">
              Horarios disponibles:
              <div className="botonesHorarios">
                {!loader &&
                  <>
                    {bookingTimes.map((time) => {
                      return (
                        <Button
                          key={time[0].rango}
                          disabled={time[0].reservado}
                          variant="contained"
                          className={time[0].reservado ? "k-button k-mb-4 botonHorasReservado" : "k-button k-mb-4 botonHoras"}
                          onClick={e => handleSeleccionHorario(time, bookingDate)}
                          sx={{ width: 100 }}
                        >
                          {time[0].rango}
                        </Button>
                      );
                    })}
                  </>
                }
                {loader && <CircularProgress />}
              </div>

            </div>
          </div>

          <TableContainer component={Paper}
            sx={{
              maxWidth: {
                xs: 325,
                sm: 450,
                md: 500,
                lg: 680,
                xl: 680
              },
              minWidth: {
                xs: 325,
                sm: 450,
                md: 500,
                lg: 680,
                xl: 680
              },
              ml: {
                md: 0
              }

            }}
          >
            <Table
              sx={{
                maxWidth: {
                  xs: "40vw",
                  sm: 450,
                  md: 500,
                  lg: 680,
                  xl: 680
                },
                minWidth: {
                  xs: 325,
                  sm: 450,
                  md: 500,
                  lg: 680,
                  xl: 680
                }
              }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">Día</StyledTableCell>
                  <StyledTableCell align="center">Fecha</StyledTableCell>
                  <StyledTableCell align="center">Horario</StyledTableCell>
                  <StyledTableCell align="center">Eliminar</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {diasSeleccionados.map((t, index) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell component="th" scope="row" align="center">
                      {t.fecha.toLocaleDateString('es-ES', options).split(',')[0]}
                    </StyledTableCell>
                    <StyledTableCell align="center">{t.fecha.toLocaleDateString('es-ES', options).split(',')[1]}</StyledTableCell>
                    <StyledTableCell align="center">{t.horario}</StyledTableCell>
                    <StyledTableCell align="center" ><button className="botonBorrarRe" onClick={() => handleEliminar(index)}><DeleteIcon sx={{ color: "#DB4437" }} /></button></StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          display: {
            md: "none",
            lg: "none",
            xl: "none"
          },
        }}
      >
        <DialogTitle>{"Horarios disponibles: "}</DialogTitle>
        <div className="k-ml-6 k-display-flex k-flex-col textoCard">
          {bookingTimes.length > 0 && "Seleccione uno o más horarios."}
          {bookingTimes.length === 0 && "No hay horarios disponibles para esta fecha."}
          <div className="botonesHorariosCard">
            {!loader &&
              <>
                {bookingTimes.map(time => {
                  return (
                    <>

                      <Button
                        key={time[0].rango}
                        disabled={time[0].reservado}
                        variant="contained"
                        className={time[0].reservado ? "k-button k-mb-4 botonHorasReservado" : "k-button k-mb-4 botonHoras"}
                        onClick={e => handleSeleccionHorario(time, bookingDate)}
                        sx={{ width: 100 }}
                      >
                        {time[0].rango}
                      </Button>


                    </>
                  );
                })}
              </>
            }
            {loader && <CircularProgress />}
          </div>

        </div>
        <DialogActions>
          <Button onClick={handleClose}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};