import React from 'react'
import BarraL from './barraL.jsx';
import ListadoL from './listadoL.jsx'
import Skeleton from '@mui/material/Skeleton';

import './scss/inicioL.scss'

export default function Inicio() {
  const range = Array.from({length: 15}, (_, i) => i * 2);
  return (
    <div className='inicioL'>
      <BarraL/>
      <div className="botonesInicioLoader">
        <Skeleton>
          <button className="publica2"> <p className='animacionFlechaInicio'  /> </button>
          <button className="publica2">Más filtros <p className='animacionFiltroInicio' /></button>
        </Skeleton>
      </div>
      {
        range.map((num,index) => (
            <ListadoL key={index}/>
          ))
      }
    </div>
  )
}
