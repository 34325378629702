import React from "react";
import Skeleton from "@mui/material/Skeleton";
import './scss/listadoL.scss';

export default function ListadoL() {
  return (
    <div className="contenedorPublicacion">
      <div className="a">
        <div className="imagenSkeleton">
          <Skeleton
            className="skeletonImage"
            sx={{
              width: "2vw",
              height: {
                xs: '20vh',
                sm:"30vh",
                md:"30vh",
                lg:"30vh",
                xl:"30vh",
                },
              marginTop: "2vh",
              padding: "0px",
              transformOrigin: "0% 0% 0px",
            }}
          />
        </div>
      </div>

      <div className="bL">
        <Skeleton>
          <h1 className="tituloPublicacion">Titulo publicacion prueba</h1>
        </Skeleton>
        <div>
          <Skeleton>
            <h2 className="precioPublicacion">
              $1000<p className="porHoraL">por hora</p>
            </h2>
          </Skeleton>
        </div>

        <Skeleton>
          <h3 className="metrosCuadradosPublicacion">100 m²</h3>
        </Skeleton>

        <Skeleton>
          <h3 className="zonaPublicacion">
            {" "}
            Australia 1600, San isidro, Buenos Aires
          </h3>
        </Skeleton>
      </div>
    </div>
  );
}
