import React, { useState, useLayoutEffect, useRef, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Barra from '../barra_de_busqueda/barra.jsx'
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment } from '@mui/material';
import './registrarse.scss'


import { FacebookLoginButton, GoogleLoginButton, TwitterLoginButton } from "react-social-login-buttons";

//import { authentication } from '../firebase-config.js'
//import { signInWithPopup } from "firebase/auth";
import { facebookProvider, googleProvider, twitterProvider } from '../config/authMethods.js'
//import { Navigate } from "react-router-dom";

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import Footer from "../footer/footer.jsx";
import ReCAPTCHA from "react-google-recaptcha";

export default function Registrarse() {

    const location = useLocation();

    const targetRef = useRef();
    const captcha = useRef(null);
    const [captchaValido, setCaptchaValido] = useState(null);
    const [captchaError, setCaptchaError] = useState(false);
    const [mostrarCaptcha, setMostrarCaptcha] = useState(false);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    const { usuario, login, registroEmailPass } = useUsuario()
    const [desdePublicar, setDesdePublicar] = useState(location.state ? (location.state.desdePublicar ? location.state.desdePublicar : false) : false)
    const [desdeReservar, setDesdeReservar] = useState(location.state ? (location.state.desdeReservar ? location.state.desdeReservar : false) : false)
    const [desdePublicacion, setDesdePublicacion] = useState(location.state ? (location.state.desdePublicacion ? location.state.desdePublicacion : false) : false)
    const [item, setItem] = useState(location.state ? (location.state.item ? location.state.item : undefined) : undefined)
    const [mailUsuario, setMailUsuario] = useState("");
    const [mailUsuarioError, setMailUsuarioError] = useState(false);
    const [mailTomado, setMailTomado] = useState(false);
    const [habilitarBoton, setHabilitarBoton] = useState(false)
    const [loading, setLoading] = useState(false)

    const [helper0, setHelper0] = useState(false);
    const [helper1, setHelper1] = useState(false);
    const [helper2, setHelper2] = useState(false);


    const navigate = useNavigate();

    const [values, setValues] = useState({
        password: '',
        rePassword: '',
        showPassword: false,
    });
    const [passwordError, setPasswordError] = useState(false)
    const [rePasswordError, setRePasswordError] = useState(false)

    useEffect(() => {
        if (values.password.length > 0 || values.rePassword.length >0 || mailUsuario.length > 0) {
            setMostrarCaptcha(true);
        }
    }, [values.password,values.rePassword, mailUsuario]);

    useEffect(() => {
        setDesdePublicar(location.state ? (location.state.desdePublicar ? location.state.desdePublicar : false) : false)
        setDesdeReservar(location.state ? (location.state.desdeReservar ? location.state.desdeReservar : false) : false)
        setDesdePublicacion(location.state ? (location.state.desdePublicacion ? location.state.desdePublicacion : false) : false)
        setItem(location.state ? (location.state.item ? location.state.item : undefined) : undefined)
    }, [location.state])

    useEffect(() => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        var isValidEmail = emailRegex.test(mailUsuario);
        var passw = false


        if (values.password.length < 6 || values.rePassword !== values.password) {
            passw = false
        } else {
            passw = true
        }


        if (passw && isValidEmail && mailUsuario.length > 0) {
            setHabilitarBoton(false)
        } else {
            setHabilitarBoton(true)
        }
    }, [mailUsuario, values.password, values.rePassword])


    const handleBlurEmail = () => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        const isValidEmail = emailRegex.test(mailUsuario);
        if (mailUsuario !== "" && !isValidEmail) {
            setMailUsuarioError(true)
            setHelper2(true)
        } else {
            setMailUsuarioError(false)
            setHelper2(false)
        }
    }

    const handleBlurPassword = () => {
        if (values.password.length < 6) {
            setPasswordError(true)
            setHelper1(true)
        } else {
            setHelper1(false)
        }
    }

    const handleBlurRePassword = () => {
        if (values.rePassword !== values.password) {
            setRePasswordError(true)
            setPasswordError(true)
            setHelper0(true)
        } else {
            setRePasswordError(false)
            setPasswordError(false)
            setHelper0(false)
        }
    }


    const handdleOnClick = async (provider) => {
        let user = await login(provider)
        if (desdePublicar) {
            //navigate('/publicar',{state:{user:user}})
            navigate('/publicar')
        } else if (desdeReservar) {
            navigate('/reserva', { state: { user: user, item: item } })
        } else if (desdePublicacion) {
            navigate(`/publicacion/` + item._id)
        }
        else {
            navigate('/inicio', { state: { user: user } })
        }
    }


    useLayoutEffect(() => {
        setDimensions({
            width: targetRef.current.offsetWidth,
            height: targetRef.current.offsetHeight
        });
    }, []);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleRegistro = async () => {
        if (!captchaValido) {
            setCaptchaError(true)
        }else{
            setLoading(true)
            setHabilitarBoton(true)
            const usr = await registroEmailPass(mailUsuario, values.password)
            if (usr) {
                navigate('/inicio')
            } else {
                setMailTomado(true)
                setMailUsuarioError(true)
                setLoading(false)
                setHabilitarBoton(false)
            }
        }
    }


    const onChangeReCAPTCHA = () => {
        if (captcha.current.getValue()) {
            setCaptchaValido(true);
            setCaptchaError(false);
        }
    }


    return (
        <div className="cajaCompleta">
            <div className="colorFondoIn" ref={targetRef}>

                <Barra user={usuario} />

                <div className="ContainerCardIn">
                    <div className="cardIn">
                        <h1 className="tituloIn">Regístrate</h1>
                        <h2 className="subtituloIn">con alguna de estas opciones</h2>
                        <div className="centradoIn">
                            <TextField
                                error={mailUsuarioError}
                                id="outlined-required"
                                label="Mail"
                                onChange={(e) => setMailUsuario(e.target.value.trim())}
                                onClick={() => setMailUsuarioError(false)}
                                onBlur={handleBlurEmail}
                                value={`${mailUsuario}`}
                                color="warning"
                                sx={{ width: "calc(70% - 10px)", marginTop: "30px" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                sx={{ p: '10px' }}
                                                aria-label="menu"
                                                disabled
                                            >
                                                <PersonIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={helper2 ? "Ingrese un mail valido. ejemplo@mail.com" : mailTomado ? "El mail ya esta en uso" : ""}
                            />
                            <TextField
                                error={passwordError}
                                id="outlined-adornment-password"
                                label="Contraseña"
                                onChange={handleChange('password')}
                                onClick={() => setPasswordError(false)}
                                onBlur={handleBlurPassword}
                                value={values.password}
                                color="warning"
                                sx={{ width: "calc(70% - 10px)", marginTop: "20px" }}
                                type={values.showPassword ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                sx={{ p: '10px' }}
                                                disabled
                                            >
                                                <LockIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={helper1 ? "La contraseña debe contener como mínimo 6 caracteres" : ""}
                            />
                            <TextField
                                error={rePasswordError}
                                id="outlined-adornment-password"
                                label="Repite la contraseña"
                                onChange={handleChange('rePassword')}
                                onBlur={handleBlurRePassword}
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.rePassword}
                                sx={{ width: "calc(70% - 10px)", marginTop: "20px" }}
                                color="warning"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                sx={{ p: '10px' }}
                                                disabled
                                            >
                                                <LockIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={helper0 ? "Las contraseñas no coinciden" : ""}
                            />

                            {mostrarCaptcha &&
                                <div className="recaptcha">
                                    <ReCAPTCHA ref={captcha} sitekey={`${process.env.REACT_APP_GOOGLE_RECAPTCHA}`} onChange={onChangeReCAPTCHA} />
                                    {captchaError && <div className="errorCaptcha">
                                        Por favor acepta el captcha
                                    </div>}
                                </div>
                            }

                            <Button variant="contained" disabled={habilitarBoton} onClick={handleRegistro} sx={{ width: "calc(70%  - 10px)", marginTop: "20px", height: "50px", marginBottom: "20px" }}>
                                {loading ? <span class="loader3"></span> : "Registrarse"}
                            </Button>
                            <div className="registrate">¿Ya tenes cuenta? <Link to="/Ingresa" style={{ color: "#EE6E2D", display: "flex", marginLeft: "3px" }}>Ingresa</Link></div>
                            <div className="separadorIn" />
                            <div className="botonesRedesIn">
                                {/* Lo saco hasta tener registrada la marca Tulu<FacebookLoginButton onClick={() => handdleOnClick(facebookProvider)}>
                                    {dimensions.width > 479 &&
                                        <span>Inicia sesión con Facebook</span>
                                    }
                                    {dimensions.width <= 479 &&
                                        <span className="textoIn">Ingresá con Facebook</span>
                                    }
                                </FacebookLoginButton> */}
                                <GoogleLoginButton onClick={() => handdleOnClick(googleProvider)}>
                                    {dimensions.width > 479 &&
                                        <span>Inicia sesión con Google</span>
                                    }
                                    {dimensions.width <= 479 &&
                                        <span className="textoIn">Ingresá con Google</span>
                                    }
                                </GoogleLoginButton>
                                <TwitterLoginButton onClick={() => handdleOnClick(twitterProvider)}>
                                    {dimensions.width > 479 &&
                                        <span>Inicia sesión con Twitter</span>
                                    }
                                    {dimensions.width <= 479 &&
                                        <span className="textoIn">Ingresá con Twitter</span>
                                    }
                                </TwitterLoginButton>
                            </div>

                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}