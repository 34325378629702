import React from "react";
import Barra from "../barra_de_busqueda/barra.jsx";
import Skeleton from "@mui/material/Skeleton";

import "./scss/publicacionL.scss";

export default function PublicacionL() {
  const range = Array.from({ length: 7 }, (_, i) => i);
  return (
    <div className="containerGeneralL">
      <Barra />
      <div className="containerPublicacionL">
        <div className="FotoPublicacionL">
          <Skeleton
            className="FotoPrincipalPublicacionL Mui-disabled"
            style={{
              margin: "0px",
              padding: "0px",
              transformOrigin: "0% 0% 0px",
              height: "68.35vw",
              width: "41vw",
            }}
          />
          <div className="SubFotosPublicacionL">
            <Skeleton
              style={{
                height: "14.7vw",
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                width: "10vw",
                marginRight: "10px",
              }}
            />
            <Skeleton
              style={{
                height: "14.7vw",
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                width: "10vw",
                marginRight: "10px",
              }}
            />
            <Skeleton
              style={{
                height: "14.7vw",
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                width: "5vw",
                marginRight: "10px",
              }}
            />
          </div>
        </div>
        <div className="DescripcionPublicacionL">
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
            }}
          >
            <div className="TituloPublicacionL">aaaaaaa</div>
          </Skeleton>
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
              marginBottom: "20px",
              marginLeft: "5px",
            }}
          >
            <div className="EstrellasPublicacionL">aaaaa</div>
          </Skeleton>

          {/*La descripcion comienza aca*/}
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
            }}
          >
            <div className="DescripcionTituloPublicacionL">a</div>
          </Skeleton>

          {range.map((num, index) => (
            <React.Fragment key={index}>
              <Skeleton
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                  transformOrigin: "0% 0% 0px",
                  marginRight: "-10px",
                }}
              >
                <div className="DescripcionTextoPublicacionL"></div>
              </Skeleton>
              <Skeleton
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                  transformOrigin: "0% 0% 0px",
                  marginRight: "-10px",
                }}
              >
                <div className="DescripcionTextoPublicacionL"></div>
              </Skeleton>
            </React.Fragment>
          ))}

          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
              marginBottom: "20px",
            }}
          >
            <div className="DescripcionTextoPublicacionL"></div>
          </Skeleton>
          {/* La descripcion termina aca*/}

          {/* La ubicacion comienza aca*/}
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
            }}
          >
            <div className="DescripcionTituloPublicacionL">a</div>
          </Skeleton>

          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginRight: "-10px",
              marginBottom: "40px",
            }}
          >
            <div className="DescripcionTextoPublicacionL"></div>
          </Skeleton>
          {/* La ubicacion termina aca*/}

          <div className="BotonesPublicacionL">
            <div className="BotonesSeccion1PublicacionL">
              <Skeleton
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                  transformOrigin: "0% 0% 0px",
                  marginRight: "-10px",
                }}
              >
                <div className="MetrosPublicacionL">aaaaaaaa</div>
              </Skeleton>
              <Skeleton
                style={{
                  marginTop: "0px",
                  paddingTop: "0px",
                  transformOrigin: "0% 0% 0px",
                }}
              >
                <div className="PrecioPublicacionL">aa</div>
              </Skeleton>
            </div>
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
              }}
            >
              <div className="BotonesSeccion2PublicacionL"></div>
            </Skeleton>
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
              }}
            >
              <div className="BotonesSeccion3PublicacionL"></div>
            </Skeleton>
          </div>
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
            }}
          >
            <div className="tagsPublicacionL"></div>
          </Skeleton>
        </div>

        <div className="recomendacionesPublicacionL">
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
            }}
          >
            <div className="DescripcionTituloPublicacionL"></div>
          </Skeleton>

          <div className="recomendacionesL">
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                marginLeft:"1vw"
              }}
            >
              <div className="reecomendacionL"></div>
            </Skeleton>
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                marginLeft:"1vw"
              }}
            >
              <div className="reecomendacionL"></div>
            </Skeleton>
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                marginLeft:"1vw"
              }}
            >
              <div className="reecomendacionL"></div>
            </Skeleton>
            <Skeleton
              style={{
                marginTop: "0px",
                paddingTop: "0px",
                transformOrigin: "0% 0% 0px",
                marginLeft:"1vw"
              }}
            >
              <div className="reecomendacionL"></div>
            </Skeleton>
          </div>
        </div>
        <div className="ComentariosPublicacionL">
        <Skeleton
            style={{
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginTop: "-35vw"
            }}
          >
            <div className="mapaPublicacionL"></div>
          </Skeleton>
        </div>
        <div className="ComentariosPublicacionL">
        <Skeleton
            style={{
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
              marginTop: "-5vw"
            }}
          >
            <div className="DescripcionTituloPublicacionL"></div>
          </Skeleton>
          <Skeleton
            style={{
              marginTop: "0px",
              paddingTop: "0px",
              transformOrigin: "0% 0% 0px",
            }}
          >
            <div className="comentariosL"></div>
          </Skeleton>
        </div>
      </div>
    </div>
  );
}
