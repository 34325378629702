import React, {useState,useEffect} from 'react';
import './misPublicaciones.scss'
import { useLocation,useNavigate } from "react-router-dom";

import Barra from '../barra_de_busqueda/barra.jsx'
import Listado from '../listado/listado.jsx'

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import ListadoL from '../loaders/listadoL';
import Footer from "../footer/footer.jsx";

import axios from 'axios';
axios.defaults.withCredentials = true;

export default function Inicio(props) {

    const location = useLocation();
    const navigate = useNavigate();

    const {usuario,cargandoUsuario,token} = useUsuario();
    const [items,setItems] = useState(location.state? (location.state.items? location.state.items: []) :[])
    const [loadItems,setLoadItems]= useState(false)
    const itemRange = Array.from({length: 15}, (_, i) => i * 2);


    useEffect(() => {
        async function fetchData() {
            if (usuario) {
                setLoadItems(true)
                const response = await axios.get(`https://api.tulu.com.ar/Items/propiedadesDelUsuario/${usuario._id}`);
                const data = response.data;
                setItems(data.Items);
                setLoadItems(false);
            } else if (!cargandoUsuario) {
                navigate('/ingresa')
            }
        }
        fetchData();
    }, [usuario, cargandoUsuario, navigate, token])
    

    const eliminarPubli = (e) => {
        if(items){
            setItems(items.filter(item => item._id !== e))
        }
    }

    return (
        <div  className={loadItems? "caja1NoScroll": "caja1"}>
            <Barra user={usuario} />
            <div className="botonesFiltro"/>

            {loadItems && itemRange.map((i) => (<ListadoL key={i}/>) )}
            <div className='favItem'>
            {(!loadItems && items) &&
                <>
                    {items.map((i,index) => {
                        return(
                        <Listado key={index} user={usuario} item={i} misP={true} eliminarPubli={eliminarPubli}/>
                        )}
                    )}
                </>
            }

            {(!loadItems && items.length===0) && <div className="loSentimos favItem">Aún no has publicado ningún espacio,   <br></br><br></br>¡muestra tus lugares y atrae reservas!</div>}
            </div>
            <div>
                <div className='separacion'></div>
                <Footer/>
            </div>
            
        </div>
    );
}