import React, { useState, useEffect } from 'react'
import Barra from '../barra_de_busqueda/barra.jsx'
import Footer from "../footer/footer.jsx";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate, useLocation } from "react-router-dom";
import { InputAdornment } from '@mui/material';
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import './passwordRecovery.scss'


export default function PasswordRecovery(props) {
  const location = useLocation();
  const mode = new URLSearchParams(location.search).get("mode");
  const oobCode = new URLSearchParams(location.search).get("oobCode");
  const [result, setResult] = useState(null);

  const { resetPassword, verificarMail, enviarMailVerificacion } = useUsuario();
  const [passwordError, setPasswordError] = useState(false)
  const [rePasswordError, setRePasswordError] = useState(false)
  const [helper0, setHelper0] = useState(false);
  const [helper1, setHelper1] = useState(false);
  const [habilitarBoton, setHabilitarBoton] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ocurrioError, setOcurrioError] = useState(false)
  const [reenviarCodigo, setReenviarCodigo] = useState(false)
  const [timeLeft, setTimeLeft] = useState(90); // 10 segundos
  const [isRunning, setIsRunning] = useState(false);
  const navigate = useNavigate();

  const loader = document.querySelector('.loader4');

  const [values, setValues] = useState({
    password: '',
    rePassword: '',
    showPassword: false,
  });

  useEffect(() => {
    (async () => {
      const aux = await verificarMail(oobCode);
      setResult(aux);
    })();
  }, [mode, oobCode]);

  useEffect(() => {
    const fail = () => {
      loader?.classList.add('fail');
    }
    const success =() => {
      loader?.classList.add('success');
    }
  
    /*
    const  reset = () => {
      loader?.classList.remove('fail')
    }*/

    if (result) {
      const { errorV } = result;
      if (errorV) {
        fail();
        setReenviarCodigo(true)
      } else {
        success();
      }
    }
  }, [result]);

  useEffect(() => {
    var passw = false


    if (values.password.length < 6 || values.rePassword !== values.password) {
      passw = false
    } else {
      passw = true
    }

    if (passw === false) {
      setHabilitarBoton(true)
    } else {
      setHabilitarBoton(false)
    }
  }, [values.password, values.rePassword])

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (timeLeft === 0) {
      setIsRunning(false);
      setTimeLeft(90);
    }
  }, [timeLeft, isRunning]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleBlurRePassword = () => {
    if (values.rePassword !== values.password) {
      setRePasswordError(true)
      setPasswordError(true)
      setHelper0(true)
    } else {
      setRePasswordError(false)
      setPasswordError(false)
      setHelper0(false)
    }
  }



  const handleBlurPassword = () => {
    if (values.password.length < 6) {
      setPasswordError(true)
      setHelper1(true)
    } else {
      setHelper1(false)
    }
  }
  const handleRegistro = async () => {
    setLoading(true)
    setHabilitarBoton(true)
    const usr = await resetPassword(oobCode, values.password)
    const { error } = usr

    if (error) {
      setLoading(false)
      setOcurrioError(true)
    } else {
      navigate('/inicio?estado=success2')
    }
  }

  const handleReenviarCodigo = () => {
    setIsRunning(true);
    enviarMailVerificacion()
  }
  return (
    <div className="cajaCompleta">
      <div className="colorFondoIn">
        <Barra />

        <div className="ContainerCardIn">
          <div className="cardIn">
            {mode === "resetPassword" && oobCode && <>
              <h1 className="tituloIn">Restablecé tu contraseña</h1>
              <h2 className="subtituloIn">Ingresá la nueva contraseña</h2>
              <TextField
                error={passwordError}
                id="outlined-adornment-password"
                label="Contraseña"
                onChange={handleChange('password')}
                onClick={() => setPasswordError(false)}
                onBlur={handleBlurPassword}
                value={values.password}
                color="warning"
                sx={{ width: "calc(70% - 10px)", marginTop: "20px" }}
                type={values.showPassword ? 'text' : 'password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        sx={{ p: '10px' }}
                        disabled
                      >
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={helper1 ? "La contraseña debe contener como mínimo 6 caracteres" : ""}
              />
              <TextField
                error={rePasswordError}
                id="outlined-adornment-password"
                label="Repite la contraseña"
                onChange={handleChange('rePassword')}
                onBlur={handleBlurRePassword}
                type={values.showPassword ? 'text' : 'password'}
                value={values.rePassword}
                sx={{ width: "calc(70% - 10px)", marginTop: "20px" }}
                color="warning"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        sx={{ p: '10px' }}
                        disabled
                      >
                        <LockIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={helper0 ? "Las contraseñas no coinciden" : ""}
              />
              {ocurrioError && <p className="error" style={{ marginTop: "20px", marginBottom: "-10px" }}>Ocurrió un error, vuelva a generar el código e intente nuevamente</p>}
              <Button variant="contained" disabled={habilitarBoton} onClick={handleRegistro} sx={{ width: "calc(70%  - 10px)", marginTop: "20px", height: "50px", marginBottom: "20px" }}>
                {loading ? <span class="loader3"></span> : "Recuperar contraseña"}
              </Button>
            </>
            }

            {mode === "verifyEmail" && oobCode &&
              <>
                <div style={{ height: "auto" }}>
                  <h1 className="tituloIn">Verificación finalizada</h1>
                  <h2 className="subtituloIn">Tu codigo no puedo ser verificado</h2>
                  <div className="centrarError">
                    <span className="loader4"></span>
                  </div>

                  {
                    !reenviarCodigo && <div className='botonPR2'></div>
                  }
                  {
                    reenviarCodigo &&
                    <div className='botonPR'>
                      <Button
                        variant="contained"
                        disabled={isRunning && timeLeft > 0}
                        onClick={handleReenviarCodigo}
                        sx={{
                          width: "15rem",
                          height: "50px",
                          marginBottom: "20px",
                        }}
                      >
                        {isRunning ? `Reenviar código ${Math.floor(timeLeft / 60)}:${timeLeft % 60}` : 'Reenviar código'}
                      </Button>
                    </div>
                  }
                </div>
              </>
            }

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
