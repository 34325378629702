import aa from 'search-insights'

aa('init',{
    appId: process.env.REACT_APP_ALGOLIA_APP_ID,
    apiKey: process.env.REACT_APP_ALGOLIA_API_KEY,
    useCookie: true,
})

export default function useAlgoliaInsights() {

    const buyProductCoockie = (objectID,userID) => {
        aa('convertedObjectIDs', {
            index: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
            eventName: 'Product Added to Cart',
            objectIDs: [objectID],
            userToken: userID,
        })
    }

    const buyProduct = (objectID) => {
        aa('convertedObjectIDs', {
            index: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
            eventName: 'Product Added to Cart',
            objectIDs: [objectID],
        })
    }

    const sendProductViewCoockie = (objectID,userID) => {
        aa('set-user-token', userID)
        aa('viewedObjectIDs', {
            index: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
            eventName: 'Product Viewed',
            objectIDs: [objectID],
            userToken: userID,
        })
    }

    const sendProductView = (objectID) => {
        aa('viewedObjectIDs', {
            index: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
            eventName: 'Product Viewed',
            objectIDs: [objectID],
        })
    }

    return {
        sendProductView,
        buyProduct,
        buyProductCoockie,
        sendProductViewCoockie
    }
}