import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import icono from "../assets/Tulu-azul.webp";
import Box from "@mui/material/Box";

import lottie from "lottie-web";
import menu from "../assets/hamburguerMenu.json";
import AcercaDe from "../acercaDe/acercaDe";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import '../portada/portada.scss'

export default function BarraPortada() {
  const animationContainer = useRef(null);
  const [clickMenu, setClickMenu] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [showContent, setShowContent] = useState(false);
  const [contacto, setContacto] = useState(false)
  const [acercaDe, setAcercaDe] = useState(false)

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handdleClickMenu = () => {
    setClickMenu(!clickMenu);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className="Lista"
    >
      <List>
        {[
          "Inicio",
          "Acerca de",
          "Contacto",
          "Creá tu cuenta",
          "Ingresá",
          "publicá tu espacio",
        ].map((text, index) => (
          <ListItem key={text}>
            <ListItemButton>
              {index === 0 && (
                <Link
                  to="/inicio"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {text}
                </Link>
              )}
              {index === 1 && (
                <button
                  style={{ all: "unset", textDecoration: "none", color: "black" }}
                  onClick={() => handleNav(1)}
                >
                  {text}
                </button>
              )}
              {index === 2 && (
                <button
                  style={{ all: "unset", textDecoration: "none", color: "black" }}
                  onClick={() => handleNav(2)}
                >
                  {text}
                </button>
              )}
              {index === 3 && (
                <Link
                  to="/registrate"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {text}
                </Link>
              )}
              {index === 4 && (
                <Link
                  to="/ingresa"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {text}
                </Link>
              )}
              {index === 5 && (
                <Link
                  to="/Ingresa"
                  state={{ desdePublicar: true }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  {text}
                </Link>
              )}
              {index !== 0 &&
                index !== 1 &&
                index !== 2 &&
                index !== 3 &&
                index !== 4 &&
                index !== 5 && (
                  <Link
                    to="/"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    {text}
                  </Link>
                )}
              <ListItemText />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: menu,
    });

    if (isFirstTime) {
      instance.goToAndStop(18, true);
      setIsFirstTime(false);
    } else {
      instance.playSegments([120, 145], true);
      instance.playSegments([145, 120], true);
    }

    /* Animacion completa on click
        if(isFirstTime){
            instance.goToAndStop(18, true); 
            setIsFirstTime(false);
        }else if(clickMenu){
            instance.playSegments([120, 145], true);
        }else{

            instance.playSegments([145, 120], true);
        }
        */

    return () => instance.destroy();
  }, [clickMenu, isFirstTime]);

  const handleNav = (n) => {
    setShowContent(!showContent)
    if (n === 1) {
      setAcercaDe(true)
    } else {
      setContacto(true)
    }
  }
  return (
    <div>
      <div className="navBar">
        <div className="navegacion1">
          <Link to="/" className="logoLink">
            <img src={icono} alt="logo" className="logo" />
          </Link>

          <div className="centro">
            <Link to="/inicio" className="letras">
              Inicio
            </Link>
            <button
              className="letras"
              onClick={() => handleNav(1)}
            >
              Acerca de
            </button>
            <button className="letras" onClick={() => handleNav(2)}>
              Contacto
            </button>
            <Link to="/Registrate" className="letras3">
              Creá tu cuenta
            </Link>
            <Link to="/Ingresa" className="letras3">
              Ingresá
            </Link>
          </div>

          <div className="centro1">
            <Link to="/Registrate" className="letras2">
              Creá tu cuenta
            </Link>
            <Link to="/Ingresa" className="letras2">
              Ingresá
            </Link>

            <Link to="/Ingresa" state={{ desdePublicar: true }}>
              <button className="publica">Publicá tu espacio</button>
            </Link>
          </div>

          <React.Fragment key={"right"}>
            <Button onClick={toggleDrawer("right", true)}
              sx={{
                display: "none",
                flexDirection: "row",
                justifyContent: "right",
                margin: "5vw",

                '@media all and (max-width: 1024px)': {
                  display: 'flex',
                },
              }}
            >
              <div
                className="animation-container"
                ref={animationContainer}
                onClick={() => {
                  handdleClickMenu();
                }}
              />
            </Button>
            <SwipeableDrawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
              className="drawer"
            >
              {list("right")}
            </SwipeableDrawer>
          </React.Fragment>
        </div>
      </div>
      <AcercaDe showContent={showContent} setShowContent={setShowContent} contacto={contacto} setContacto={setContacto} acercaDe={acercaDe} setAcercaDe={setAcercaDe} />
    </div >
  );
}
