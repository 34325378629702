import React, { useState, useRef, useEffect } from 'react';
import icono from '../assets/Tulu-azul.webp'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import search from '../assets/search.webp'
import './barra.scss'
import { Link, useNavigate } from "react-router-dom";

import { getActiveToken } from '../utils/getActiveToken';

import lottie from "lottie-web"
import menu from '../assets/hamburguerMenu.json'

import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import puerta from '../assets/puertita.webp'

import ClearIcon from '@mui/icons-material/Clear';

import { useSearchBox } from 'react-instantsearch-hooks';
import Autocompletado from '../autocompletado/autocompletado.jsx';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import { useTags } from '../variablesGlobales/tagsGlobal.jsx'
import { authentication } from '../firebase-config.js'

import AcercaDe from "../acercaDe/acercaDe";
//import Cookies from 'universal-cookie';
//const cookies = new Cookies();

export default function Barra(props) {
  const navigate = useNavigate();

  const animationContainer = useRef(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const [clickMenu, setClickMenu] = useState(false);
  const { usuario } = useUsuario();
  const [busqueda, setBusqueda] = useState(props.bus)
  const { setSelectedOptions2, setProfesiones, setUbicaciones } = useTags()
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [contacto, setContacto] = useState(false)
  const [acercaDe, setAcercaDe] = useState(false)
  const inputRef = useRef();
  const { refine } = useSearchBox()
  const wrapperRef = useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMiCuenta = () => {
    setAnchorEl(null);
    navigate('/miCuenta', { state: { user: usuario } })
  }

  const handleMisReservas = () => {
    setAnchorEl(null);
    navigate('/misReservas', { state: { user: usuario } })
  }

  const handleMisPublicaciones = () => {
    setAnchorEl(null);
    navigate('/misPublicaciones', { state: { user: usuario } })
  }

  const handleReservasMisPublicaciones = () => {
    setAnchorEl(null);
    navigate('/reservasMisPublicaciones', { state: { user: usuario } })
  }

  const handleMisFavoritos = () => {
    setAnchorEl(null);
    navigate('/misFavoritos', { state: { user: usuario } })
  }


  /*
    useEffect(() => {
        setUsuario(props.user)
    },[props.user])*/

  useEffect(() => {
    setBusqueda(props.bus)
  }, [props.bus])


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    const instance = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: menu
    });

    if (isFirstTime) {
      instance.goToAndStop(18, true);
      setIsFirstTime(false);
    } else {
      instance.playSegments([120, 145], true);
      instance.playSegments([145, 120], true);
    }
    return () => instance.destroy();

  }, [clickMenu, isFirstTime]);

  const handdleClickMenu = () => {
    setClickMenu(!clickMenu);
  }
  const handleNav = (n) => {
    setShowContent(!showContent)
    if (n === 1) {
      setAcercaDe(true)
    } else {
      setContacto(true)
    }
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      className='Lista'
    >
      {usuario === undefined &&
        <List >
          {['Inicio', 'Acerca de', 'Contacto', 'Creá tu cuenta', 'Ingresá', 'publicá tu espacio'].map((text, index) => (
            <ListItem key={text}>
              <ListItemButton >
                {index === 0 && <Link to="/inicio" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 1 && <button style={{ all: "unset", textDecoration: "none", color: "black" }} onClick={() => handleNav(1)}>{text}</button>}
                {index === 2 && <button style={{ all: "unset", textDecoration: "none", color: "black" }} onClick={() => handleNav(2)}>{text}</button>}
                {index === 3 && <Link to="/registrate" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 4 && <Link to="/ingresa" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 5 && <Link to="/Ingresa" state={{ desdePublicar: true }} style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index !== 0 && index !== 1 && index !== 2 && index !== 5 && index !== 4 && index !== 3 && <Link to="/inicio" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                <ListItemText />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      }

      {usuario !== undefined &&
        <List >
          {['Inicio', 'Mi cuenta', 'Mis favoritos', 'Mis reservas', 'Reservas de mis publicaciones', 'Mis publicaciones', 'Publicá tu espacio', 'Salir'].map((text, index) => (
            <ListItem key={text}>
              <ListItemButton >
                {index === 0 && <Link to="/inicio" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 1 && <Link to="/miCuenta" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 2 && <Link to="/misFavoritos" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 3 && <Link to="/misReservas" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 4 && <Link to="/reservasMisPublicaciones" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 5 && <Link to="/misPublicaciones" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 6 && <Link to="/publicar" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                {index === 7 && <button onClick={handleCerrrarSesion} style={{ textDecoration: 'none', color: 'black', border: 'none', background: 'none', cursor: 'pointer' }}>{text}</button>}
                {index !== 0 && index !== 1 && index !== 2 && index !== 3 && index !== 4 && index !== 5 && index !== 6 && index !== 7 && <Link to="/inicio" style={{ textDecoration: 'none', color: 'black' }}>{text}</Link>}
                <ListItemText />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      }
    </Box>
  );

  const handleCerrrarSesion = async () => {
    //Borro el token de la cookie
    //cookies.remove('token');
    await authentication.signOut()
    window.location.href = '/'; //si sacas esto no te lleva a la portada, te deja en el inicio
  }

  const handleBusqueda = async (e) => {
    var key = e.keyCode || e.which;
    if (key === 13) {
      if (busqueda !== undefined) {
        setSelectedOptions2([])
        setProfesiones([])
        setUbicaciones([])
        navigate("/inicio", { state: { bus: busqueda } })
      }
    }
  }

  const handleClear = async () => {
    if (props.bus !== undefined) {
      setBusqueda('')
      navigate("/inicio", { state: { bus: '' } })
    } else {
      setBusqueda('')
    }
  }

  const handleInput = (e) => {
    var key = e.keyCode || e.which;
    if (key !== 13) {
      const { value, selectionEnd = 0 } = inputRef.current;
      const { word } = getActiveToken(value, selectionEnd)
      const shouldOpenAutocomplete = /^(?=.{1,50}$)[\w ]+/.test(word);
      setShowAutocomplete(shouldOpenAutocomplete);
      shouldOpenAutocomplete && refine(word);
    } else {
      setShowAutocomplete(false);
    }
  }

  const handleSelection = (selection) => {
    const { value, selectionEnd = 0 } = inputRef.current;
    const { word, range } = getActiveToken(value, selectionEnd)
    const [index] = range
    const prefix = value.substring(0, index)
    const suffix = value.substring(index + word.length)

    const newText = `${prefix}${selection}${suffix}`

    inputRef.current.value = newText
    inputRef.current.focus()

    setBusqueda(selection)
    setShowAutocomplete(false)

  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowAutocomplete(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="navBarBarra">
      <div className="navegacionBarra1">
        <Link to="/inicio" onClick={handleClear}><img src={icono} alt="logo" className="logoBarra" /></Link>
        <Link to="/inicio" onClick={handleClear}><img src={puerta} alt="logo" className="puertaBarra" /></Link>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="centroBarra">
            <div className='busquedaU'>
              <InputGroup className="border-none">
                <Form.Control className="shadow-none searchBarra2 "
                  placeholder="¿Qué estás buscando?"
                  value={busqueda}
                  onChange={(e) => setBusqueda(e.target.value)}
                  onKeyDown={(e) => (handleBusqueda(e))}
                  onKeyUp={(e) => handleInput(e)}
                  ref={inputRef}
                />
                {(busqueda === undefined || busqueda === "") && <InputGroup.Text className="basic-addon2 searchBarra" ><img alt="" src={search} className="lupaBarra" /></InputGroup.Text>}
                {(busqueda !== undefined && busqueda !== "") && <InputGroup.Text className="basic-addon2 searchBarra" onClick={handleClear}><ClearIcon sx={{ fontSize: "20px" }} className="lupaBarra gris" /></InputGroup.Text>}
              </InputGroup>
            </div>

          </div>
          <div ref={wrapperRef} style={showAutocomplete ? {} : { visibility: "hidden" }} >
            <Autocompletado style={{ width: '100%' }} handleSelection={handleSelection} top={`${1000}px`} />
          </div>
        </div>

        <div className="centroBarra1">

          {usuario === undefined &&
            <>
              <Link to="/Registrate" className="letras-barra">Creá tu cuenta</Link>
              <Link to="/Ingresa" className="letras-barra">Ingresá</Link>
            </>
          }

          {usuario !== undefined &&
            <>
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  <AccountCircleIcon sx={{ height: 30, width: 30 }} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleMiCuenta}>Mi cuenta</MenuItem>
                  <MenuItem onClick={handleMisFavoritos}>Mis favoritos</MenuItem>
                  <MenuItem onClick={handleMisReservas}>Mis reservas</MenuItem>
                  <MenuItem onClick={handleMisPublicaciones}>Mis publicaciones</MenuItem>
                  <MenuItem onClick={handleReservasMisPublicaciones}>Reservas de mis publicaciones</MenuItem>
                  <MenuItem onClick={handleCerrrarSesion}>Salir</MenuItem>
                </Menu>
              </div>
            </>
          }
          {/*<a onClick={handleCerrrarSesion} className="letras2"><AccountCircleIcon/></a>*/}

          {usuario === undefined &&
            <Link to="/Ingresa" state={{ desdePublicar: true }}><button className="publica-barra">Publicá tu espacio</button> </Link>
          }

          {usuario !== undefined &&
            <Link to="/publicar" ><button className="publica-barra">Publicá tu espacio</button> </Link>
          }



        </div>

        <React.Fragment key={'right'} >
          <Button onClick={toggleDrawer('right', true)} className='drawer-barra' sx={{
            '@media all and (min-width: 1024px)': {
              display: 'none',
            },
            '@media all and (min-width: 768px) and (max-width: 1024px)': {
              display: 'flex',
            },
          }}><div className='animation-container-barra' ref={animationContainer} onClick={() => { handdleClickMenu() }} /></Button>
          <SwipeableDrawer
            anchor={'right'}
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
            className='drawer-barra'
          >
            {list('right')}
          </SwipeableDrawer>
        </React.Fragment>
        <AcercaDe showContent={showContent} setShowContent={setShowContent} contacto={contacto} setContacto={setContacto} acercaDe={acercaDe} setAcercaDe={setAcercaDe} />

      </div>
    </div>
  );
}