import React, { useEffect,useState } from 'react'
import Barra from '../barra_de_busqueda/barra.jsx'
import Footer from "../footer/footer.jsx";
import Button from "@mui/material/Button";
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

export default function VerificarMail() {

  const {enviarMailVerificacion } = useUsuario();
  const [timeLeft, setTimeLeft] = useState(90); // 10 segundos
  const [isRunning, setIsRunning] = useState(true);

  useEffect(() => {
    if (isRunning && timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
    if (timeLeft === 0) {
      setIsRunning(false);
      setTimeLeft(90);
    }
  }, [timeLeft, isRunning]);

  const handleReenviarCodigo = () => {
    setIsRunning(true);
    enviarMailVerificacion()
  }
  return (
    <div className="cajaCompleta">
      <div className="colorFondoIn">
        <Barra />

        <div className="ContainerCardIn">
          <div className="cardIn" style={{height:"30vh",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center"}}>
            <h1 className="tituloIn" style={{marginRight:"1vw"}}>¡Hola! Te enviamos un correo electrónico</h1>
            <h2 className="subtituloIn" style={{marginRight:"1vw"}}>Para continuar, necesitamos que verifiques tu cuenta a través del correo que te hemos enviado.</h2>
            <Button
              variant="contained"
              disabled={isRunning && timeLeft > 0}
              onClick={handleReenviarCodigo}
              sx={{
                width: "15rem",
                height: "50px",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              {isRunning ? `Reenviar código ${Math.floor(timeLeft / 60)}:${timeLeft % 60}` : 'Reenviar código'}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
