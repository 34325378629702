import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Barra from '../barra_de_busqueda/barra.jsx'

import "@progress/kendo-theme-default/dist/all.css";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import BookDrivingSlot from '../calendarioReserva/BookDrivingSlot.jsx';
import "./reserva.scss"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import lottie from "lottie-web"
import loading from '../assets/loader4.json'

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import HorariosGrid from './horariosGrid.jsx';

import axios from 'axios';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
axios.defaults.withCredentials = true;
export default function Reservar() {

    const location = useLocation();
    const { id } = useParams();

    const [loadItem, setLoadItem] = useState(false);

    const { usuario, token } = useUsuario();
    const [item, setItem] = useState(location.state ? (location.state.item ? location.state.item : undefined) : undefined);
    const [nombre, setNombre] = useState(usuario ? usuario.nombre : "");
    const [horas, setHoras] = useState(0);
    const [precioFinal, setPrecioFinal] = useState(0);
    const [diasSeleccionados, setDiasSeleccionados] = useState([]);
    const [horariosSeleccionados, setHorariosSeleccionados] = useState([]);
    const [nombreError, setNombreError] = useState(false);
    const [horasError, setHorasError] = useState(false);
    const [eliminado, setEliminado] = useState(false);

    const [reservasUsuario, setReservasUsuario] = useState([]);

    const [horariosReservados, setHorariosReservados] = useState([]);
    const [open, setOpen] = React.useState(false);

    const [loader, setLoader] = useState(false);
    const animationContainer = useRef(null);
    const navigate = useNavigate();


    useEffect(() => {
        //setUsuario(location.state? (location.state.user? location.state.user: undefined) :undefined)
        setItem(location.state ? (location.state.item ? location.state.item : undefined) : undefined)
    }, [location.state])

    useEffect(() => {
        setLoadItem(true);
        var url;
        //este me trae tanto el item como los comentarios
        if (usuario) {
            url = `https://api.tulu.com.ar/Items/id/${id}`;
            async function fetchData() {
                try {
                    const response = await axios.get(url);
                    const data = response.data;
                    var { error } = data;

                    if (error) {
                        error === "El item no existe" ? setEliminado(true) : navigate("/inicio");

                    } else {
                        setItem(data);
                    }
                    setLoadItem(false);
                } catch (e) {
                    console.log(e)
                    setLoadItem(false);
                    setEliminado(true);
                }

            }
            fetchData();
        } 
    }, [id]);

    useEffect(() => {
        setNombre(usuario ? usuario.nombre : "");
    }, [usuario])

    useEffect(() => {
        if (nombre.length > 0) {
            setNombreError(false);
        } else {
            setNombreError(true);
        }
    }, [nombre])

    useEffect(() => {
        lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: loading
        });

    }, [loader]);


    useEffect(() => {
        if (item) {
            async function fetchData() {
                const response = await axios.get(`https://api.tulu.com.ar/Items/idReservadosItem/${item._id}`);
                const data = response.data;
                let reservados = []
                data.Reservas.forEach((item) => {
                    item.reservas.forEach((reserva) => {
                        reservados.push({ fecha: reserva.fecha, hora: reserva.horario })
                    })
                })

                setHorariosReservados(reservados);

                if (usuario) {
                    const response2 = await axios.get(`https://api.tulu.com.ar/Items/idReservadosUser/${usuario._id}`);
                    const data2 = response2.data;
                    var res = []
                    data2.Reservas.forEach((item) => {
                        item.reservas.forEach((reserva) => {
                            res.push(reserva)
                        })
                    })

                    setReservasUsuario(res)
                }
            }
            fetchData();
        }
    }, [item, usuario, token])

    useEffect(() => {
        if (horariosSeleccionados.length > 0) {
            var horas = 0;
            var total = 0;
            horariosSeleccionados.forEach((horario) => {
                horario.modulos.forEach((modulo) => {
                    horas += parseInt(modulo.horas) * modulo.repeticiones;
                    total += parseInt(modulo.precio) * modulo.repeticiones;
                })
            })

            setHoras(horas); //seteo las horas
            setPrecioFinal(total); //seteo el preciofinal
        }
    }, [horariosSeleccionados])


    const handleHoras = (e) => {
        setHorasError(false);
        setHoras(e);
        setPrecioFinal(e * item.precio);
    }

    const handleDiasSelecionados = (e) => {
        setDiasSeleccionados(e);
    }


    const handleReservar = async () => {
        if (horas <= 0 || nombre.length <= 0) {
            setHorasError(true);
        } else {
            await handleHorarios()
        }

    }

    const handleHorarios = async () => {
        let reservasEnMismoHorario = false;

        reservasUsuario.forEach((reserva) => {
            diasSeleccionados.forEach((dia) => {
                let fecha1 = new Date(reserva.fecha);
                let fecha2 = new Date(dia.fecha);
                if (fecha1.getDate() === fecha2.getDate() && fecha1.getMonth() === fecha2.getMonth() && fecha1.getFullYear() === fecha2.getFullYear()) {
                    if (reserva.horario === dia.horario) {
                        reservasEnMismoHorario = true;
                    }
                }
            })
        })


        if (reservasEnMismoHorario) {
            setOpen(true);
        } else {
            handleEnviar(true)
        }



    }

    useEffect(() => {
        if (reservasUsuario.length > 0) {
            setHorasError(false);
        }
    }, [reservasUsuario])

    const handleEnviar = async (valor) => {
        var errores = false
        if (nombre.length === 0) {
            setNombreError(true);
            errores = true;
        }

        if (horas <= 0 || nombre.length <= 0) {
            setHorasError(true);
            errores = true;
        }

        if (!errores && valor) {
            setLoader(true);
            console.log('days', horariosSeleccionados)

            // las reservas tienen que ser del estilo [{id:0, fecha: date, horario: '11:00 - 12:00'},..]

            let indice_aux = 0

            const reservas_aux = horariosSeleccionados.flatMap((reserva) => {
                return reserva.horarios.flatMap((horario) => {
                    return reserva.fecha.map((fecha) => {
                        let partes_fecha = fecha.split('/')
                        return {
                            id: indice_aux++,
                            fecha: new Date(`${partes_fecha[2]}-${partes_fecha[1]}-${partes_fecha[0]}`),
                            horario: horario
                        }
                    })
                })
            })

            let obj = {
                idItem: item._id,
                idUsuario: usuario._id,
                reservas: reservas_aux,
                horas: horas,
                total: precioFinal,
                titulo: item.titulo,
                nombreUsuario: usuario.nombre,
                nombreIngresado: nombre,
                portada: item.img[0],
                direccion: item.direccion
            };

            const response = await axios.post(`https://api.tulu.com.ar/Items/Reservas`, obj);
            /*
            Es para habiltiar mercado pago. 

            PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'
            const data = response.data;

            obj.idReserva = data.idReserva;

            //const responseLocation = await axios.post(`https://api.tulu.com.ar/Items/crear-orden`, obj);
            window.location.href = responseLocation.data;
            */
            navigate('/inicio?estado=success');
        }

    }

    const handleNombre = (a) => {
        setNombre(a);
    }

    const handleClose = () => {
        setOpen(false);
        handleEnviar(true)
    };

    const handleCloseModif = () => {
        handleEnviar(false)
        setOpen(false);
    };



    return (
        <>
            {loadItem &&
                <>
                    <Barra user={usuario} />
                    <div className='fondoPuLoader'>
                        <div className='cajaPrincipalReservaLoader'>
                            <CircularProgress sx={{ color: '#ee6e2d' }} />
                        </div>
                    </div>
                </>
            }
            {!loadItem && !eliminado && item && (
                <div className='fondoPu'>
                    <Barra user={usuario} />
                    <div className='cajaPrincipalPublicar'>
                        <div className='centarPu'>
                            <h1 className='publicaTuEspacioPu'>¡Reserva tu espacio!</h1>
                        </div>

                        {
                            item.img.length > 1 &&
                            <ImageList cols={item.img.length < 3 ? 2 : 3} sx={{
                                width: '50vw',
                                height: '50vh',
                                overflow: 'hidden',
                                margin: 'auto',
                                borderRadius: '20px',
                                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
                                marginTop: '4vh',
                                marginBottom: '4vh'

                            }}>
                                {item.img.map((imagen) => (
                                    <ImageListItem key={imagen}>
                                        <img
                                            srcSet={`${imagen}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${imagen}?w=248&fit=crop&auto=format`}
                                            loading="lazy"
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        }
                        {
                            item.img.length === 1 &&
                            <div className='imagenReserva'>
                                <img src={item.img[0]} alt="imagen" />
                            </div>
                        }
                        <Box
                            component="form"
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '80vw' },
                            }}
                            noValidate
                            autoComplete="off"
                            className='cuerpoPu'>
                            <div className="textoPu">
                                <h1 className="tituloPu">Nombre</h1>
                                <h1 className='subtituloPu'>Ingresa tu nombre completo para que el propietario pueda identificarte.</h1>
                            </div>
                            <div className='caja2Pu'>
                                <TextField
                                    error={nombreError}
                                    required
                                    id="outlined-required"
                                    label="Nombre"
                                    defaultValue={`${nombre}`}
                                    onChange={(a) => { handleNombre(a.target.value) }}
                                    color="warning"
                                    helperText={nombreError ? "Debes ingresar tu nombre." : ""}
                                />

                            </div>

                        </Box>

                        <div className="textoPu">
                            <h1 className="tituloPu">Días y horarios</h1>
                            <h1 className='subtituloPu'>Selecciona los días y los horarios que te queden más comodos!</h1>
                        </div>
                        <HorariosGrid horarios={item.horario} setHorariosSelec={setHorariosSeleccionados} item={item} setPrecioFinal={setPrecioFinal} setHoras={setHoras} horariosReservados={horariosReservados} />

                        {/*VERSION CALENDARIO
                            <div className="textoPu">
                                <h1 className="tituloPu">Días y horarios</h1>
                                <h1 className='subtituloPu'>Selecciona los días y los horarios que te queden más comodos!</h1>
                                <div className='cajaCalendario'>
                                    <BookDrivingSlot horas={handleHoras} item={item} diasSelec={handleDiasSelecionados} horariosReservados={horariosReservados} />
                                </div>
                            </div>*/
                        }

                        <div className="textoPu">
                            <div className='cajaHoraYPrecio'>
                                <h1 className="horasReservadas">Horas Reservadas:</h1>
                                <p className='monto'>{horas}</p>
                            </div>
                        </div>

                        <div className="textoPu">
                            <div className='cajaHoraYPrecio'>
                                <h1 className="horasReservadas">Total Semanal: </h1>
                                <p className='monto'>${precioFinal}</p>
                            </div>
                        </div>
                        <h3 className="textoCancelar" style={{ marginLeft: "6vw" }}>
                            {item.devolucion === "Siempre" ? "Tendrás la opción de cancelar hasta justo antes de asistir." : item.devolucion === "nunca" ? "Este espacio no permite cancelaciones." : item.devolucion === "24hs" ? "Podrás cancelar este espacio hasta 24 horas antes de asistir." : item.devolucion === "48hs" ? "Podrás cancelar este espacio hasta 48 hs antes de asistir." : item.devolucion === "72hs" ? "Podrás cancelar este espacio hasta 72 hs antes de asistir." : item.devolucion === "96hs" ? "Podrás cancelar este espacio hasta 96 hs antes de asistir." : ""}
                        </h3>

                        <div className='caja2Pu'>
                            {horasError && <p className='errorHorasPu'>Debes reservar al menos una hora</p>}
                            {!loader &&
                                <button className="reservaRealizada" onClick={handleReservar}>Reservar</button>
                            }
                            {loader &&
                                <div className="crearPublicacion2"><div className='animacionLoaderPu' ref={animationContainer}></div></div>
                            }
                        </div>

                    </div>

                    <div>

                        <Dialog
                            open={open}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle>{"¿Deseas continuar de todas formas? "}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    Tenés una o más reservas que superponen sus horarios
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseModif}>Modificar horarios</Button>
                                <Button onClick={handleClose}>Continuar</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            )}

            {!item && eliminado && (
                <>
                    <Barra user={usuario} />

                    <div className="loSentimos alturaNoTienesRe">
                        Lamentablemente, la publicación que estás buscando <br></br>ya ha sido
                        eliminada o no está disponible temporalmente. <br></br>
                        <br></br> :({" "}
                    </div>
                </>
            )}
        </>
    )
}