import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import lottie from "lottie-web";
import likeButton from '../assets/likeButton.json'
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import algoliasearch from 'algoliasearch';

import './listado.scss'

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";
import axios from 'axios';
axios.defaults.withCredentials = true;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Listado(props) {

    const navigate = useNavigate();

    const like = useRef(null);
    const [clickLike, setClickLike] = useState(false);
    const [cargarLikes, setCargarLikes] = useState(true);
    const [open, setOpen] = React.useState(false);

    const { usuario, token } = useUsuario();
    const [item, setItem] = useState(props.item)
    const [busqueda] = useState(props.bus)

    const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME
    const client = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY);
    const index = client.initIndex(indexName);


    const [vengoDeMisPublicaciones, setVengoDeMisPublicaciones] = useState(props.misP ? true : false)

    useEffect(() => {
        setItem(props.item)

        if (props.item.fav) {
            setClickLike(props.item.fav)
        }
    }, [props.item])

    useEffect(() => {
        setVengoDeMisPublicaciones(props.misP ? true : false)
    }, [props.misP])


    useEffect(() => {
        const instanceLike = lottie.loadAnimation({
            container: like.current,
            renderer: "svg",
            loop: false,
            autoplay: false,
            animationData: likeButton,
        });


        if (cargarLikes) {
            if (!clickLike) {
                instanceLike.goToAndStop(0, true);
            } else {
                instanceLike.goToAndStop(51, true);
            }
        }
        else if (clickLike) {
            instanceLike.playSegments([10, 51], true);
            instanceLike.setSpeed(2);
        } else {
            instanceLike.goToAndStop(0, true);
        }
        return () => { instanceLike.destroy() };
    }, [clickLike, like, cargarLikes])

    const handleClose = () => {
        setOpen(false);
    }

    const handleContinuar = () => {
        setOpen(false);

        props.eliminarPubli(item._id)
        axios.delete(`https://api.tulu.com.ar/Items/Items`, {
            data: {
                idItem: item._id,
                idUsuario: usuario._id
            }
        });

        index.deleteObject(item._id);


    }

    const handdleLikeButton = () => {
        let cancel
        var click = clickLike
        setClickLike(!clickLike);
        setCargarLikes(false);


        axios.request({
            method: click ? 'DELETE' : 'POST',
            url: `https://api.tulu.com.ar/Items/Favoritos`,
            data: { item: item, usuario: usuario.email },
            cancelToken: new axios.CancelToken(c => cancel = c),
            withCredentials: true
        }).catch(e => {
            if (axios.isCancel(e)) return
        })


        return () => cancel()
    }


    const handleEditarPublicacion = () => {
        navigate('/publicar', { state: { user: usuario, item: item } });
    }

    const handleEliminarPublicacion = () => {
        setOpen(true);
    }


    return (
        <div className="contenedorPublicacion">
            <Link to={`/publicacion/${item._id}`} state={{ item: item, like: clickLike, bus: busqueda }} className={item.estado === "activo" ? "a" : "greyA"}>
                <div className='sombra'>
                    <div style={{ backgroundImage: `url(${item.img[0]})` }} className='imagenPubli' />
                </div>
            </Link>

            <Link to={`/publicacion/${item._id}`} state={{ item: item, like: clickLike, bus: busqueda }} className={item.estado === "activo" ? "b" : "greyB"} >
                <h1 className='tituloPublicacion'>{item.titulo}</h1>
                <div>
                    <h2 className='precioPublicacion'>${item.precio} <p className='porHoraL'>por hora</p></h2>
                </div>
                {item.metros && <h3 className='metrosCuadradosPublicacion'>{item.metros} m²</h3>}
                <h3 className='zonaPublicacion'> {item.direccion}</h3>
            </Link>

            {vengoDeMisPublicaciones &&
                <div className='botonesVEE'>
                    <IconButton color="warning" aria-label="upload picture" component="label" onClick={handleEditarPublicacion}>
                        <EditIcon className='eye' />
                    </IconButton>
                    <IconButton color="warning" aria-label="upload picture" component="label" onClick={handleEliminarPublicacion}>
                        <DeleteIcon className='tash' />
                    </IconButton>
                </div>
            }

            {!vengoDeMisPublicaciones &&
                <button className={usuario ? "likeButton" : "likeButton3"} onClick={() => { handdleLikeButton() }}><div className='likeButton2' ref={like} onClick={() => { handdleLikeButton() }} /></button>
            }


            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"¿Deseas eliminar la siguiente publicación?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {item.titulo}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleContinuar}>Continuar</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

