import React, { useEffect, useState } from 'react';
import './horariosGrid.scss';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EE6E2D",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HorariosGrid(props) {

  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  const [selected, setSelected] = useState([]);
  const [minHora, setMinHora] = useState(0);
  const [maxHora, setMaxHora] = useState(24);
  const [horarios, setHorarios] = useState([]);

  const [modulos, setModulos] = useState([])


  const [formatearHora, setFormatearHora] = useState(false)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });


  const fechaActual = new Date();
  const diaActual = fechaActual.getDate();
  const mesActual = fechaActual.getMonth();
  const añoActual = fechaActual.getFullYear();
  const uniqueDias = new Map();


  const [contadorDia, setContadorDia] = useState(diaActual);
  const [contadorMes, setContadorMes] = useState(mesActual);
  const [vueltasMes, setVueltasMes] = useState(0);
  const [contadorAño, setContadorAño] = useState(añoActual);
  const meses = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  // Estado para almacenar los días del mes por día de la semana
  const [diasPorDiaSemana, setDiasPorDiaSemana] = useState({});
  const [diasSeleccionados, setDiasSeleccionados] = useState([]);

  const [fechaHoraRes, setFechaHoraRes] = useState({})


  useEffect(() => {
    setHorarios(props.horarios);
    let min = 24; // Inicializa con una hora máxima
    let max = 0; // Inicializa con una hora mínima

    // Itera sobre los horarios para encontrar la hora mínima y máxima
    Object.values(props.horarios).forEach((horario) => {
      const horaInicio = new Date(horario.horaInicio).getHours();
      const horaFin = new Date(horario.horaFin).getHours();
      if (horaInicio < min) {
        min = horaInicio;
      }
      if (horaFin > max) {
        max = horaFin;
      }

    });

    setMinHora(min);
    setMaxHora(max);


    // Asegúrate de que props.item.modulos es un array y existe antes de intentar ordenarlo y filtrarlo
    if (props.item.modulos && Array.isArray(props.item.modulos)) {

      // si existe un modulo con 1 hora lo quito
      props.item.modulos = props.item.modulos.filter(modulo => modulo.horas !== '1');

      // agrego el 1 al modulo
      props.item.modulos = [...props.item.modulos, { horas: '1', precio: props.item.precio.toString() }];

      // Ordenar primero por horas y luego por precio en caso de igualdad de horas
      props.item.modulos.sort((a, b) => {
        const hourComparison = a.horas.localeCompare(b.horas, undefined, { numeric: true });
        if (hourComparison !== 0) {
          return hourComparison;
        }
        // Comparar precios si las horas son iguales
        return a.precio - b.precio;
      });

      // Filtrar para mantener solo el módulo con el precio más bajo (o mas alto) para cada hora
      const bestPricePerHour = {};
      const uniqueModulos = props.item.modulos.filter(modulo => {
        if (!bestPricePerHour[modulo.horas] || modulo.precio > bestPricePerHour[modulo.horas].precio) {
          bestPricePerHour[modulo.horas] = modulo;
          return true;
        }
        return false;
      });

      // Asumiendo que setModulos es una función para actualizar el estado en un componente de React
      setModulos(bestPricePerHour);
    } else {
      setModulos({ '1': { horas: '1', precio: props.item.precio.toString() } })
    }

  }, [props]);

  useEffect(() => {
    encontrarDiasPorDiaSemana();
  }, [contadorMes, contadorAño]);

  useEffect(() => {
    window.innerWidth < 768 ? setFormatearHora(true) : setFormatearHora(false)
  }, [window.innerWidth]);
  useEffect(() => {
    // Agrega el manejador de eventos cuando el componente se monta
    window.addEventListener('resize', handleResize);

    // Limpieza del evento cuando el componente se desmonte
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let fechasHorariosReservadosObj = {}
    let año
    let mes
    //fechasHorariosReservadosObj va a ser un diccionario del estilo:
    /*
      2024 (año):{
        1 (mes):{
          domingo: ['16:00 - 17:00', '17:00 - 18:00'],
          lunes: ['16:00 - 17:00', '17:00 - 18:00'],
          martes: ['16:00 - 17:00', '17:00 - 18:00'],
          miercoles: ['16:00 - 17:00', '17:00 - 18:00'],
          jueves: ['16:00 - 17:00', '17:00 - 18:00'],
          viernes: ['16:00 - 17:00', '17:00 - 18:00'],
          sabado: ['16:00 - 17:00', '17:00 - 18:00']
        }
      }
    */

    if (props.horariosReservados) {

      props.horariosReservados.forEach(horario => {
        año = new Date(horario.fecha).getFullYear()
        mes = new Date(horario.fecha).getMonth()

        if (!fechasHorariosReservadosObj[año]) {
          fechasHorariosReservadosObj[año] = {}
        }

        if(!fechasHorariosReservadosObj[año][mes]){
          fechasHorariosReservadosObj[año][mes]= {
            domingo: [],
            lunes: [],
            martes: [],
            miercoles: [],
            jueves: [],
            viernes: [],
            sabado: []
          }
        }


        const fecha = new Date(horario.fecha).toLocaleDateString('es-AR', options)
        const dia = new Date(horario.fecha).getDay() == 1 ? 'lunes' : new Date(horario.fecha).getDay() == 2 ? 'martes' : new Date(horario.fecha).getDay() == 3 ? 'miercoles' : new Date(horario.fecha).getDay() == 4 ? 'jueves' : new Date(horario.fecha).getDay() == 5 ? 'viernes' : new Date(horario.fecha).getDay() == 6 ? 'sabado' : 'domingo'
        // si las horas son por ejemplo '16:00 - 18:00' tengo que agregar las dos horas
        const horasDelMedio = []
        const horaInicio = horario.hora.split('-')[0]
        const horaFin = horario.hora.split('-')[1]

        const horaInicioInt = parseInt(horaInicio.split(':')[0])
        const horaFinInt = parseInt(horaFin.split(':')[0])

        if (Math.abs(horaFinInt - horaInicioInt) > 1) {
          for (let i = horaInicioInt; i < horaFinInt; i++) {
            horasDelMedio.push(i.toString().padStart(2, '0') + ':00 - ' + (i + 1).toString().padStart(2, '0') + ':00')
          }
        }

        if (horasDelMedio.length > 0) {
          for (let i = 0; i < horasDelMedio.length; i++) {
            if (!fechasHorariosReservadosObj[año][mes][dia].includes(horasDelMedio[i])) {
              fechasHorariosReservadosObj[año][mes][dia].push(horasDelMedio[i])
            }
          }
        } else if (!fechasHorariosReservadosObj[año][mes][dia].includes(horario.hora)) {
          fechasHorariosReservadosObj[año][mes][dia].push(horario.hora)
        }
      })
      setFechaHoraRes(fechasHorariosReservadosObj)
    }

  }, [props.horariosReservados])

  const toggleSelection = (dia, hora, mes) => {
    const index = selected.findIndex(
      (item) => item.dia === dia && item.hora === hora && item.mes === mes
    );
    if (index === -1) {
      setSelected([...selected, { dia, hora, fechas: diasPorDiaSemana[dia], mes }]);
    } else {
      setSelected(selected.filter((item) => !(item.dia === dia && item.hora === hora)));
    }
  };

  const formatHora = (hora) => {
    // Formatea la hora para mostrarla en el formato deseado
    const horaFormateada = hora < 10 ? `0${hora}` : hora.toString();
    const horaInicio = horaFormateada + ':00';
    const horaFin = horaFormateada === '23' ? '00:00' : (hora + 1).toString().padStart(2, '0') + ':00';
    return `${horaInicio} - ${horaFin}`;
  };

  const encontrarDiasPorDiaSemana = () => {
    let diaMes = diaActual
    if (contadorMes !== mesActual) {
      setContadorDia(1)
      diaMes = 1
    } else {
      setContadorDia(diaActual)
      diaMes = diaActual
    }
    const diasSemana = ['domingo', 'lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado'];
    const diasPorDiaSemana = {};

    // Iterar sobre los días restantes del mes
    for (let dia = 1; dia <= new Date(contadorAño, contadorMes + 1, 0).getDate(); dia++) {
      const diaFecha = new Date(contadorAño, contadorMes, dia);
      const nombreDia = diasSemana[diaFecha.getDay()];

      // Si el día de la semana no existe en el objeto, inicializa un arreglo vacío
      if (!diasPorDiaSemana[nombreDia]) {
        diasPorDiaSemana[nombreDia] = [];
      }

      // Agrega la fecha al arreglo correspondiente al día de la semana
      if (dia >= diaMes) {
        diasPorDiaSemana[nombreDia].push(`${dia}/${contadorMes + 1}/${contadorAño}`);
      }
    }

    setDiasPorDiaSemana(diasPorDiaSemana);
  };

  const handleEliminar = (indexAEliminar) => {
    console.log('diaSeleccionado1', indexAEliminar)
    console.log('diaSeleccionado', diasSeleccionados)
    const diaSeleccionado = diasSeleccionados[indexAEliminar];
    const dia = diaSeleccionado.dia;
    const horarios = diaSeleccionado.horarios[0].split(" - "); // esto asume que los horarios siempre están en formato "HH:MM - HH:MM"

    // Convertir horas de horarios a números enteros
    const horaInicio = parseInt(horarios[0].split(":")[0]);
    const horaFin = parseInt(horarios[1].split(":")[0]);

    // Filtrar 'selected' para remover todas las entradas que coincidan con el día, las horas y las fechas
    setSelected(selected.filter(item =>
      !(item.dia === dia &&
        /* SI QUIERO FILTRAR POR HORAS TAMBIÉN Y BORAR HORA A HORA:
        item.hora >= horaInicio &&
        item.hora < horaFin &&*/
        diaSeleccionado.fecha.every(fecha => item.fechas.includes(fecha))
      )));
  };

  let uniqueIndex = 0;

  selected.forEach((item) => {
    const dia = item.dia;
    const fecha = item.fechas;

    // Crear clave única para cada combinación de día y fecha
    const key = `${dia}|${fecha}`;

    console.log('selected', selected)

    const horarios = selected
      .filter((i) => i.dia === dia && i.mes === item.mes)
      .map((i) => formatHora(i.hora))
      .sort((a, b) => a.split(' - ')[0].localeCompare(b.split(' - ')[0]))
      .reduce((acc, hora) => {
        console.log('selecteddd', hora)
        if (acc.length === 0) {
          return [hora];
        } else {
          const last = acc[acc.length - 1];
          const lastStart = last.split(' - ')[0];
          const lastEnd = last.split(' - ')[1];
          const currentStart = hora.split(' - ')[0];
          const currentEnd = hora.split(' - ')[1];

          // Calcular la diferencia de horas entre el inicio del último intervalo guardado y el final del actual
          const duration = new Date(`1970-01-01T${currentEnd}:00`) - new Date(`1970-01-01T${lastStart}:00`);
          const hours = duration / (1000 * 60 * 60);

          // Si el final del último horario es igual al inicio del actual y no supera las 4 horas, combinamos
          if (lastEnd === currentStart && modulos.hasOwnProperty(hours.toString())) {
            return [...acc.slice(0, -1), `${lastStart} - ${currentEnd}`];
          } else {
            // Si no, empezamos un nuevo intervalo
            return [...acc, hora];
          }
        }
      }, []);


    let modulos_por_horario = horarios.map(hora => {
      const horaInicio = hora.split(' - ')[0];
      const horaFin = hora.split(' - ')[1];
      const duration = new Date(`1970-01-01T${horaFin}:00`) - new Date(`1970-01-01T${horaInicio}:00`);
      const hours = duration / (1000 * 60 * 60);
      return modulos[hours.toString()];
    })
      .sort((a, b) => a.horas.localeCompare(b.horas, undefined, { numeric: true }));

    // los modulos que son iguales los agrupo y pongo la cantidad de horas y el precio y las veces que se repite
    modulos_por_horario = modulos_por_horario.reduce((acc, modulo) => {
      const last = acc[acc.length - 1];
      if (last && last.horas === modulo.horas && last.precio === modulo.precio) {
        last.repeticiones++;
      } else {
        acc.push({ ...modulo, repeticiones: 1 });
      }
      return acc;
    }, []);



    // Guardar en el mapa
    uniqueDias.set(key, { dia, fecha, horarios, modulos: modulos_por_horario, index: uniqueIndex });
    uniqueIndex++;
  });

  useEffect(() => {
    setDiasSeleccionados(Array.from(uniqueDias.values()));
  }, [selected]);

  useEffect(() => {

    if (diasSeleccionados.length > 0) {
      props.setHorariosSelec(Array.from(diasSeleccionados.values()));
    } else {
      props.setHoras(0)
      props.setPrecioFinal(0)
    }
  }, [diasSeleccionados]);

  const handleResize = () => {
    // Actualiza el estado con las nuevas dimensiones de la ventana
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  const handlePrevMonth = () => {
    setVueltasMes(vueltasMes - 1)
    if (contadorMes === 0) {
      setContadorAño(prev => prev - 1);
      setContadorMes(11)
    } else {
      setContadorMes(prev => prev - 1);
    }
  };

  const handleNextMonth = () => {
    /*if (contadorMes < mesActual + 3) {
      setContadorMes(prev => prev + 1);
    }*/
    setVueltasMes(vueltasMes + 1)
    console.log('contadorMes', vueltasMes)
    if (contadorMes === 11) {
      setContadorAño(prev => prev + 1);
      setContadorMes(0);
    } else {
      setContadorMes(prev => prev + 1);
    }
  };



  return (<>
    <div className="month-picker">
      <Button
        onClick={handlePrevMonth}
        disabled={vueltasMes === 0}
        //si esta deshabiltiado quiero que este de este color d0b197
        sx={{
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#ee6e2d'
          }
        }}
      >
        <ArrowBackIosIcon sx={{ color: (vueltasMes === 0) ? '#d0b197' : '#ee6e2d' }} />
      </Button>
      <span className="month-name">{meses[contadorMes]}</span>
      <Button
        onClick={handleNextMonth}
        disabled={(vueltasMes>2)}
        //si esta deshabiltiado quiero que este de este color d0b197
        sx={{
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          '&:hover': {
            backgroundColor: 'transparent',
            color: '#ee6e2d'
          }
        }}
      >
        <ArrowForwardIosIcon sx={{ color: (vueltasMes > 2) ? '#d0b197' : '#ee6e2d' }} />
      </Button>

    </div>
    <div className='grid-container'>
      <table className="horarios-grid">
        <thead>
          <tr>
            <th></th>
            {Object.keys(horarios).map((dia) => (
              <th key={dia}>{dia}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(maxHora - minHora)].map((_, hora) => (
            <tr key={hora + minHora}>
              <td>{formatHora(hora + minHora)}</td>
              {Object.entries(horarios).map(([dia, horario]) => {
                const horaInicio = new Date(horario.horaInicio).getHours();
                const horaFin = new Date(horario.horaFin).getHours();
                const horaActual = hora + minHora;

                const isSelected = selected.some(
                  (item) => item.dia === dia && item.hora === horaActual && item.mes === contadorMes
                );

                // Verifica si la hora actual está dentro del rango de horario para este día
                let isDisabled = horaActual < horaInicio || horaActual >= horaFin || diasPorDiaSemana[dia].length === 0 
                if (fechaHoraRes[contadorAño] && fechaHoraRes[contadorAño][contadorMes] && fechaHoraRes[contadorAño][contadorMes][dia]) {
                  isDisabled = isDisabled || fechaHoraRes[contadorAño][contadorMes][dia].includes(formatHora(horaActual))
                }


                return (
                  <td
                    key={`${dia}-${horaActual}-${contadorMes}`}
                    className={`hora-cell ${isSelected ? 'selected' : ''} ${isDisabled ? 'disabled' : ''
                      }`}
                    onClick={() => {
                      if (!isDisabled) {
                        toggleSelection(dia, horaActual, contadorMes);
                      }
                    }}
                  ></td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="horarios-select-container">
      <TableContainer component={Paper}
        sx={{
          maxWidth: {
            xs: 325,
            sm: 450,
            md: '65%',
            lg: '65%',
            xl: '65%'
          },
          minWidth: {
            xs: 325,
            sm: 450,
            md: '65%',
            lg: '65%',
            xl: '65%'
          },
          ml: {
            md: 0
          },

        }}
      >
        <Table
          sx={{
            maxWidth: '100%',
            minWidth: '100%',
            '& th, & td, & tbody , & tr , & th': {
              fontSize: {
                xs: '0.5rem', // para extra small devices
                sm: '1rem', // para small devices
                md: '1rem', // para medium devices
                lg: '1rem',   // default para large devices y más grandes
              },
              padding: {
                xs: '1px', // para extra small devices
                sm: '10px', // para small devices
                md: '10px', // para medium devices
                lg: '10px',   // default para large devices y más grandes
              }
            }
          }}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Día</StyledTableCell>
              <StyledTableCell align="center">Fechas</StyledTableCell>
              <StyledTableCell align="center">Horario</StyledTableCell>
              <StyledTableCell align="center">Módulo / Precio</StyledTableCell>
              <StyledTableCell align="center">Eliminar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diasSeleccionados.map((t, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell component="th" scope="row" align="center" >
                  {t.dia == "domingo" ? "Domingo" : t.dia == "lunes" ? "Lunes" : t.dia == "martes" ? "Martes" : t.dia == "miercoles" ? "Miércoles" : t.dia == "jueves" ? "Jueves" : t.dia == "viernes" ? "Viernes" : "Sábado"}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t.fecha.map((fecha, index) => (
                    <React.Fragment key={index}>
                      {fecha}
                      {index !== t.fecha.length - 1 ? <><span>, </span><br /></> : null}
                    </React.Fragment>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="center">{t.horarios.map(
                  (horario, index) => (
                    <React.Fragment key={index}>
                      {horario}
                      {index !== t.horarios.length - 1 ? <><span>, </span><br /></> : null}
                    </React.Fragment>
                  )
                )}</StyledTableCell>
                <StyledTableCell align="center">
                  {t.modulos.map((modulo, index) => (
                    <React.Fragment key={index}>
                      {modulo.repeticiones} x (Pack {modulo.horas}hs / ${modulo.precio})
                      {index !== t.modulos.length - 1 ? <><span>, </span><br /></> : null}
                    </React.Fragment>
                  ))}
                </StyledTableCell>
                <StyledTableCell align="center" ><button className="botonBorrarRe" onClick={() => handleEliminar(index)}><DeleteIcon sx={{ color: "#DB4437" }} /></button></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  </>

  );
};



