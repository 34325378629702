import React, { useState, useRef, useEffect } from "react";
import "./portada.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import search from "../assets/search.webp";
import Select from "react-select";
import { Link } from "react-router-dom";

import { getActiveToken } from "../utils/getActiveToken";

import { useSearchBox } from "react-instantsearch-hooks";
import Autocompletado from "../autocompletado/autocompletado.jsx";

import BarraPortada from "../barra_portada/barra_portada";

import { useTags } from "../variablesGlobales/tagsGlobal.jsx";
import Footer from "../footer/footer.jsx";

import axios from 'axios';
axios.defaults.withCredentials = true;
export default function Portada() {
  const inputRef = useRef();
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const { refine } = useSearchBox();

  const [optionList, setOptionList] = useState([]);
  const [ubicacion, setUbicacion] = useState("");

  const { setProfesiones } = useTags();

  const [selectedOptions, setSelectedOptions] = useState([]);

  const wrapperRef = useRef(null);

  /*const optionList = [
        { value: "red", label: "Contaduria" },
        { value: "green", label: "Psicologia" },
        { value: "yellow", label: "Abogacia" },
        { value: "blue", label: "Dermatologia" },
        { value: "white", label: "Dentista" }
    ];*/

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "0px",
      height: "10vh",
      boxShadow: "0px 0px 25px rgba(0, 0, 0, 0.12)",
      paddingLeft: "2.3vw",
      paddingRight: "1vw",
      fontSize: "2.1vh",
    }),

    menu: (provided, state) => ({
      ...provided,
    }),
  };

  const handleSelect = (data) => {
    setSelectedOptions(data);
    setProfesiones(data);
  };

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get("https://api.tulu.com.ar/Items/category");
      const data = response.data;
      let profesionesFinal = [];
      data.forEach((item) => {
        if (item)
          profesionesFinal.push({ value: item, label: item.slice(1) });
      });

      setOptionList(profesionesFinal);
    }
    fetchData();
  }, []);


  const handleBuscar = () => { };

  const handleInput = (e) => {
    var key = e.keyCode || e.which;
    if (key !== 13) {
      const { value, selectionEnd = 0 } = inputRef.current;
      const { word } = getActiveToken(value, selectionEnd);
      const shouldOpenAutocomplete = /^(?=.{1,50}$)[\w ]+/.test(word);
      setShowAutocomplete(shouldOpenAutocomplete);
      shouldOpenAutocomplete && refine(word);
    } else {
      setShowAutocomplete(false);
    }
  };

  const handleSelection = (selection) => {
    const { value, selectionEnd = 0 } = inputRef.current;
    const { word, range } = getActiveToken(value, selectionEnd);
    const [index] = range;
    const prefix = value.substring(0, index);
    const suffix = value.substring(index + word.length);

    const newText = `${prefix}${selection}${suffix}`;

    inputRef.current.value = newText;
    inputRef.current.focus();

    setShowAutocomplete(false);

    setUbicacion(selection);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowAutocomplete(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <div className="cajaCompleta">
      <div className="caja0" >
        <BarraPortada />
        <div className="caja2">
          <div className="caja3">
            <h1 className="titulo">Encontra tu lugar ideal</h1>
            <br></br>

            <div className="caja5">
              <div className="cajaUbicacion">
                <p className="ubicacion">Búsqueda</p>

                <InputGroup className="mb-3 search3">
                  <Form.Control
                    className="shadow-none search2"
                    placeholder="¿Qué estás buscando?"
                    onChange={(a) => {
                      setUbicacion(a.target.value);
                    }}
                    onKeyUp={(e) => handleInput(e)}
                    value={ubicacion}
                    ref={inputRef}
                  />
                  <InputGroup.Text className="basic-addon2 search">
                    <img alt="" src={search} />
                  </InputGroup.Text>
                </InputGroup>

                {showAutocomplete && (
                  <div ref={wrapperRef}>
                    <Autocompletado handleSelection={handleSelection} />
                  </div>
                )}
              </div>
              <div className="cajaUbicacion2">
                <p className="ubicacion">Profesión</p>

                <div className="profesiones">
                  <Select
                    options={optionList}
                    placeholder="Profesiones"
                    value={selectedOptions}
                    onChange={handleSelect}
                    isSearchable={true}
                    isMulti
                    isOptionDisabled={() => selectedOptions.length >= 3}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#f9e4d4",
                        primary: "#ED6C02",
                      },
                    })}
                    styles={customStyles}
                  />
                </div>
              </div>
            </div>

            <div className="caja4">
              <Link
                to="/inicio"
                state={{ prof: selectedOptions, bus: ubicacion }}
              >
                <button className="buscar" onClick={() => handleBuscar()}>
                  Buscar
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
