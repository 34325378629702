import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Skeleton from '@mui/material/Skeleton';
import './scss/reservasL.scss'


export default function ReservasL() {
  return (
    <>
      <Paper
        elevation={3}
        sx={{
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "48%",
            xl: "32%",
          },
          height: "300px",
        }}
        className="cardMR"
      >
        <div className="contenedorDentroCardMR">
          <Skeleton className='Mui-disabled contenedorImagenLoader'>
          </Skeleton>

          <div className="cajaTextMR">
            <Skeleton>
              <h1 className="tituloMR">
                Alquiler de consultorio y espacio de trabajo{" "}
              </h1>
            </Skeleton>
            <Skeleton>
              <h2 className="fechaYHoraReservaMR margenMR">Día: 23/11/2022</h2>
            </Skeleton>
            <Skeleton>
              <h2 className="fechaYHoraReservaMR">Hora: 13:00 - 14:00</h2>
            </Skeleton>
            <Skeleton>
              <h2 className="direccionMR">Buenos Aires Balvanera mitre 650</h2>
            </Skeleton>
            <div className="botonCancelarMR">
              <Skeleton>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "12px",
                    },
                    marginTop: {
                      xs: "0px",
                      sm: "0px",
                      md: "-30px",
                      lg: "0px",
                      xl: "0px",
                    },
                    marginRight: "20px",
                    paddingRight: "20px",
                  }}
                >
                  ver
                </Button>
              </Skeleton>
              <div> &nbsp;&nbsp;&nbsp;</div>
              <Skeleton>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "10px",
                      lg: "10px",
                      xl: "12px",
                    },
                    marginTop: {
                      xs: "0px",
                      sm: "0px",
                      md: "-30px",
                      lg: "0px",
                      xl: "0px",
                    },
                  }}
                >
                  Cancelar reserva
                </Button>
              </Skeleton>
            </div>
          </div>
        </div>
      </Paper>
    </>
  );
}
