import React, {useState,useEffect} from 'react';
import { useNavigate } from "react-router-dom";

import Barra from '../barra_de_busqueda/barra.jsx'
import Footer from "../footer/footer.jsx";
import Listado from '../listado/listado.jsx'
import ListadoL from '../loaders/listadoL.jsx';

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import axios from 'axios';
axios.defaults.withCredentials = true;
export default function MisFavoritos(props) {

    const navigate = useNavigate()
    const {usuario,cargandoUsuario,token} = useUsuario();
    const [items,setItems] = useState()
    const [loadItems,setLoadItems]= useState(false)
    const itemRange = Array.from({length: 15}, (_, i) => i * 2);


    useEffect(() => {
        async function fetchData() {
            if (usuario) {
                setLoadItems(true)
                const response = await axios.get(`https://api.tulu.com.ar/Items/idFav/${usuario._id}`);
                const data = response.data;
                setItems(data);
                setLoadItems(false);
            } else if (!cargandoUsuario) {
                navigate('/ingresa')
            }
        }
        fetchData();
    }, [usuario, cargandoUsuario, token, navigate])
    

    const likeQuitado = (e) => {
        const newItms = items.filter(item => item._id !== e)
        setItems(newItms)

        return true
    }

    return (
        <>
            {/*!cargandoUsuario &&   */ }
                <div  className={loadItems? "caja1NoScroll": "caja1"}>
                    <Barra user={usuario}  />
                    <div className="botonesFiltro">
        
                    </div>
                    {loadItems && itemRange.map((i) => (<ListadoL key={i}/>) )}
                    <div className='favItem'>

                        {!loadItems && items?.map((i) => {
                            return(
                            <Listado user={usuario} item={i} likeQuitado={likeQuitado}/>
                            )}
                        )}

                        {!loadItems && items?.length===0 && <div className="loSentimos favItem">Aún no tienes ningún lugar favorito,   <br></br><br></br> ¡explora y encuentra el lugar perfecto para agregar a tus favoritos!</div>}
                    </div>

                    <div>
                        <div className='separacion'></div>
                        <Footer/>
                    </div>
                </div>
            {/*cargandoUsuario && <div> cargando </div>}

            {!cargandoUsuario && !usuario && <div> No estas logueado </div>*/}
        </>
    )
}