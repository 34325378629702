import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Barra from '../barra_de_busqueda/barra.jsx'
import ReservasL from '../loaders/reservasL.jsx'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Footer from "../footer/footer.jsx";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Alert from "@mui/material/Alert";

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './misReservas.scss'

import axios from 'axios';
axios.defaults.withCredentials = true;

export default function Reserva() {
    //const location = useLocation();
    const dias = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];
    const fechaActual = new Date()
    const navigate = useNavigate();

    const { usuario, cargandoUsuario, token } = useUsuario();
    const [reservasUsuario, setReservasUsuario] = useState([]);
    const [horasQuitadas, setHorasQuitadas] = useState([])
    const [loadItems, setLoadItems] = useState(false)
    const itemRange = Array.from({ length: 15 }, (_, i) => i * 2);
    const [param1, setParam1] = useState()
    const [param2, setParam2] = useState()
    const [open, setOpen] = useState(false)
    const [open2, setOpen2] = useState(false)

    const [loaderPopUp, setLoaderPopUp] = useState(false)

    const [errorReservas, setErrorReservas] = useState(true)
    const [bookingDate, setBookingDate] = useState(new Date());
    const onDateChange = e => {
        const fecha = e.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
        setBookingDate(new Date(fecha));
    };



    useEffect(() => {
        async function fetchData() {
            if (usuario) {
                setLoadItems(true)
                const response = await axios.get(`https://api.tulu.com.ar/Items/idReservadosUser/${usuario._id}`);
                const data = response.data;
                data.Reservas.forEach((r) => {
                    r.reservas.forEach((r2) => {
                        r2.fecha = new Date(r2.fecha)
                    })
                })
                setReservasUsuario(data.Reservas)
                setLoadItems(false)
            } else if (!cargandoUsuario) {
                navigate('/ingresa')
            }
        }
        fetchData();
    }, [usuario, cargandoUsuario, navigate, token])


    useEffect(() => {
        if (reservasUsuario.some(r => r.reservas.length > 0)) {
            setErrorReservas(false)
        } else {
            setErrorReservas(true)
        }
    }, [reservasUsuario])

    const handleParams = (param1, param2) => {
        setParam1(param1)
        setParam2(param2)
        setOpen(true)
    }

    const handleCancelarReserva = async (id, indice) => {
        setLoaderPopUp(true)
        let reservasAux = reservasUsuario;
        let obj;
        let aux4 = horasQuitadas


        reservasAux.forEach((reserva) => {
            if (reserva._id === id) {
                let aux = []
                let aux2 = []
                let aux3 = []

                aux = reserva.reservas
                aux3 = aux.filter((r) => r.id === indice)
                aux4.push(aux3)
                aux2 = aux.filter((r) => r.id !== indice)
                obj = {
                    idReserva: id,
                    reservas: aux2,
                    horasQuitadas: aux4,
                    email: usuario.email,
                };
                reserva.reservas = aux2
            }
        })

        setReservasUsuario([...reservasAux]);
        setHorasQuitadas(aux4)

        const response = await axios.post(`https://api.tulu.com.ar/Items/CancelarReserva`, obj);
        const data = response.data;
        setOpen2(true);


        setOpen(false)
        setLoaderPopUp(false)
    }

    const handleVerEspacio = (id) => {
        navigate(`/publicacion/${id}`, { state: { user: usuario } })

    }

    const handleCancelar = (fecha, tipo) => {
        var fechaActual = new Date()
        if (tipo === "Siempre" && (fechaActual.getTime() < fecha.getTime())) {
            return false
        } else if (tipo === "24hs" && (fechaActual.getTime() + 86400000 < fecha.getTime())) {
            return false
        } else if (tipo === "48hs" && (fechaActual.getTime() + 172800000 < fecha.getTime())) {
            return false
        } else if (tipo === "72hs" && (fechaActual.getTime() + 259200000 < fecha.getTime())) {
            return false
        } else if (tipo === "96hs" && (fechaActual.getTime() + 345600000 < fecha.getTime())) {
            return false
        } else {
            return true
        }
    }

    /*const handleClickOpen = () => {
        setOpen(true);
    };*/

    const handleClose = () => {
        setOpen(false);
    };

    const isSameDay = (dateA, dateB) => {
        return (
            dateA.getDate() === dateB.getDate() &&
            dateA.getMonth() === dateB.getMonth() &&
            dateA.getFullYear() === dateB.getFullYear()
        );
    }


    return (
        <div>
            <Barra user={usuario} />

            <div className='centrarMR'>
                <div className='cajaCalendarMr'>
                    <Paper elevation={3}>
                        <Calendar
                            value={bookingDate}
                            onChange={onDateChange}
                            locale="es-ES"
                            formatShortWeekday={(locale, date) => dias[date.getDay()]}
                            tileClassName={({ date }) => {
                                const isHighlighted = reservasUsuario.some((r1) =>
                                    r1.reservas.some((r2) => isSameDay(new Date(r2.fecha), date))
                                );
                                return isHighlighted ? 'highlighted-day' : '';
                            }}
                            tileContent={({ date }) => {
                                // Verificar si la fecha coincide con alguna de las fechas de reserva
                                const matchingReservas = reservasUsuario.reduce((total, r) => {
                                    return total + r.reservas.filter((re) => isSameDay(new Date(re.fecha), date)).length;
                                }, 0);

                                if (matchingReservas > 0) {
                                    return (
                                        <>
                                            <div className="triangle"></div>
                                            <span className="number">{matchingReservas > 100 ? "*" : matchingReservas}</span>
                                        </>
                                    );
                                }

                                return null; // No se muestra ningún contenido adicional en la celda
                            }}
                        />
                    </Paper>
                </div>
                <div className='cajaMR'>
                    {loadItems && itemRange.map((i, index) => (<ReservasL key={index} />))}

                    {(!loadItems && reservasUsuario) && reservasUsuario.map((r, index) => {
                        let re = r.reservas

                        return (

                            re.sort((a, b) => {
                                // Comparar las horas de las reservas
                                if (a.fecha.getHours() < b.fecha.getHours()) {
                                    return -1;
                                } else if (a.fecha.getHours() > b.fecha.getHours()) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }).map((reservas, index2) => (
                                reservas.fecha.getDay() === bookingDate?.getDay() &&
                                reservas.fecha.getDate() === bookingDate?.getDate() &&
                                reservas.fecha.getMonth() === bookingDate?.getMonth() &&
                                reservas.fecha.getFullYear() === bookingDate?.getFullYear() &&
                                <Paper
                                    elevation={3}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            md: '100%',
                                            lg: '48%',
                                            xl: '32%',
                                        },
                                        height: 'auto',
                                        marginLeft: '1.2vw',
                                        marginBottom: '1.2vw'
                                    }}
                                    key={`${r._id}-${index2}`}
                                >
                                    <div className='contenedorDentroCardMR'>
                                        <img src={r.portada} className='contenedorImagenMR' alt="portada" />
                                        <div className='cajaTextMR'>
                                            <p className='tituloMR'>{r.titulo} </p>
                                            <p className='codigo'>{r.idReservaMP}</p>
                                            <p className='fechaYHoraReservaMR margenMR'>Día:  {reservas.fecha.toLocaleDateString('es-ES')}</p>
                                            <p className='fechaYHoraReservaMR'>Hora: {reservas.horario}</p>
                                            <p className='direccionMR'>{r.direccion}</p>
                                            <p className='codigo2'>Reservado para: <b>{r.nombreIngresado}</b></p>
                                            <p className='codigo2 margenMR2'>Código de verificación: <b>{r.codigo}</b></p>
                                            <p className='cancelacionMR'>{r.devolucion === "Siempre" ? "Podrás realizar la cancelación hasta justo antes de asistir." : r.devolucion === "nunca" ? "Este espacio no permite cancelaciones." : r.devolucion === "24hs" ? "Podrás cancelar este espacio hasta 24 horas antes de asistir." : r.devolucion === "48hs" ? "Podrás cancelar este espacio hasta 48 hs antes de asistir." : r.devolucion === "72hs" ? "Podrás cancelar este espacio hasta 72 hs antes de asistir." : r.devolucion === "96hs" ? "Podrás cancelar este espacio hasta 96 hs antes de asistir." : ""}</p>
                                            {/*<h2>Estado</h2>*/}
                                            <div className='botonCancelarMR'>

                                                <Button
                                                    variant="contained"
                                                    disabled={handleCancelar(reservas.fecha, reservasUsuario[index].devolucion)}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '10px',
                                                            xl: '12px',
                                                        },
                                                        marginRight: '5%'
                                                    }}
                                                    onClick={() => handleParams(r._id, reservas.id)}>
                                                    Cancelar reserva
                                                </Button>
                                                <Button variant="contained"
                                                    onClick={() => handleVerEspacio(r.idItem)}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '10px',
                                                            xl: '12px',
                                                        },
                                                    }}
                                                >
                                                    ver
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            ))
                        )


                    })}

                    {(!loadItems && errorReservas) && <div className="loSentimos alturaNoTienesRe">Aún no tienes ninguna reserva,  <br></br><br></br>¡busca tu lugar ideal y haz tu reserva!</div>}

                </div>
            </div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle>{"Confirmar cancelacion. "}</DialogTitle>

                <DialogActions>
                    <Button onClick={handleClose} disabled={loaderPopUp}>Volver</Button>
                    <Button onClick={() => handleCancelarReserva(param1, param2)} disabled={loaderPopUp}>Confirmar</Button>
                </DialogActions>
            </Dialog>
            <div>
                <div className='separacion'></div>
                <Footer />
            </div>
            {/*
            <Paper elevation={2} sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>Reserva</h1>   
            </Paper>
            */}
            {open2 && <Alert onClose={() => { setOpen2(false) }} className="posicionAlerta" variant="filled" severity="success">¡La cancelación se reealizó con éxito!</Alert>}
        </div>
    )
}