import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';


import { BrowserRouter as Router } from "react-router-dom";

import algoliasearch from 'algoliasearch'
import { InstantSearch } from 'react-instantsearch-hooks';

const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, process.env.REACT_APP_ALGOLIA_API_KEY)

const INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME

const script = document.createElement('script');
let clave= process.env.REACT_APP_GOOGLE_LPQTP;
clave= "https://maps.googleapis.com/maps/api/js?key="+clave+"&libraries=places&language=es".toString();
script.src = clave;
document.head.appendChild(script);

const root = createRoot(document.getElementById('root'));

root.render(
  <InstantSearch
    searchClient={searchClient}
    indexName={INDEX_NAME}
    suppressHydrationWarning={true}
  >
      <Router>
        <App />
      </Router>
  </InstantSearch>
);
