import React, { useState, useEffect, useMemo } from "react";
import { authentication } from '../firebase-config.js'
import { signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, verifyPasswordResetCode, confirmPasswordReset, sendPasswordResetEmail, sendEmailVerification, applyActionCode } from "firebase/auth";
//import { useNavigate } from "react-router-dom";
import axios from 'axios';
//import Cookies from 'universal-cookie';

axios.defaults.withCredentials = true;
//const cookies = new Cookies();


const UsuarioContext = React.createContext();

export function UsuarioProvider(props) {

    const [usuario, setUsuario] = useState(undefined)
    const [cargandoUsuario, setCargandoUsuario] = useState(true)
    const [token, setToken] = useState(undefined)
    const [canLeave, setCanLeave] = useState(false);
    //const navigate = useNavigate();


    useEffect(() => {

        try {

            authentication.onAuthStateChanged(async user => {
                var usuarioFinal
                if (user) {
                    try {
                        
                        if (user.emailVerified === false) {
                            enviarMailVerificacion(user.email)
                            setCanLeave(false)
                        } else {
                            setCanLeave(true)
                        }
                        const response = await axios.post('https://api.tulu.com.ar/Items/Usuarios', { uid: user.uid, provider: user.providerId, nombre: user.displayName, email: user.email, emailVerified: user.emailVerified, telefono: user.phoneNumber, imagen: user.photoURL }, { withCredentials: true });
                        const data = response.data;

                        if (data.message) {
                            //cookies.remove('token');
                            await authentication.signOut()
                            window.location.href = '/ingresa'; //si sacas esto no te lleva a la portada, te deja en el inicio
                        } else {
                            usuarioFinal = {
                                _id: data._id,
                                nombre: data.nombre,
                                email: user.email,
                                imagen: user.photoURL,
                                telefono: user.phoneNumber,
                                DNI: data.DNI,
                                emailMp: data.emailMp,
                                nombreMp: data.nombreMp,
                                autorizacionMp: data.autorizacionMp,
                                posee_items: data.posee_items
                            }

                        }
                        setUsuario(usuarioFinal ? usuarioFinal : undefined)
                        setCargandoUsuario(false)
                    }
                    catch (e) {
                        //cookies.remove('token');
                        await authentication.signOut()
                        setUsuario(undefined)
                        setCargandoUsuario(false)
                    }
                } else {
                    authentication.signOut()
                    //cookies.remove('token');
                    setUsuario(undefined)
                    setCargandoUsuario(false)
                }
            })
        } catch (e) {
            authentication.signOut()
            //cookies.remove('token');
            setUsuario(undefined)
            setCargandoUsuario(false)
        }
    }, [])


    const cambiarNombreUsuario = async (nombre) => {
        var usuarioFinal = usuario
        usuarioFinal.nombre = nombre
        setUsuario(usuarioFinal)
    }

    const cambiarMailDeMp = async (mail) => {
        var usuarioFinal = usuario
        usuarioFinal.emailMp = mail
        setUsuario(usuarioFinal)
    }

    const cambiarNombreDeMp = async (nombre) => {
        var usuarioFinal = usuario
        usuarioFinal.nombreMp = nombre
        setUsuario(usuarioFinal)
    }

    const cambiarDNI = async (dni) => {
        var usuarioFinal = usuario
        usuarioFinal.DNI = dni
        setUsuario(usuarioFinal)
    }

    const login = async (provider) => {

        var user

        const re = await signInWithPopup(authentication, provider)

        try {
            const response = await axios.post('https://api.tulu.com.ar/Items/Usuarios', { uid: re.user.uid, provider: re.providerId, nombre: re.user.displayName, email: re.user.email, emailVerified: re.user.emailVerified, telefono: re.user.phoneNumber, imagen: re.user.photoURL });
            const data = response.data;
            user = {
                _id: data._id,
                nombre: data.nombre,
                email: re.user.email,
                imagen: re.user.photoURL,
                telefono: re.user.phoneNumber,
                DNI: data.DNI,
                emailMp: data.emailMp,
                nombreMp: data.nombreMp,
                autorizacionMp: data.autorizacionMp,
                posee_items: data.posee_items
            }
            setUsuario(user);
            //cookies.set('token', data.token, { path: '/' , httpOnly: true , secure: true})

        } catch (err) {
            console.log(err);
        }


        return user
    }

    const registroEmailPass = async (email, password) => {
        var user
        await createUserWithEmailAndPassword(authentication, email, password)
            .then(async (re) => {

                try {
                    const response = await axios.post('https://api.tulu.com.ar/Items/Usuarios', { uid: re.user.uid, provider: re.providerId, nombre: re.user.displayName, email: re.user.email, emailVerified: re.user.emailVerified, telefono: re.user.phoneNumber, imagen: re.user.photoURL });
                    const data = response.data;
                    user = {
                        _id: data._id,
                        nombre: data.nombre ? data.nombre : "",
                        email: re.user.email,
                        imagen: re.user.photoURL,
                        telefono: re.user.phoneNumber,
                        DNI: data.DNI,
                        emailMp: data.emailMp,
                        nombreMp: data.nombreMp,
                        autorizacionMp: data.autorizacionMp,
                        posee_items: data.posee_items
                    }
                    setUsuario(user);

                } catch (err) {
                    console.log(err);
                }

            }).catch((err) => {
                return "error"
            })
        return user
    }

    const singInWithPassword = async (email, password) => {
        var user
        await signInWithEmailAndPassword(authentication, email, password)
            .then(async (re) => {

                try {
                    const response = await axios.post('https://api.tulu.com.ar/Items/Usuarios', { uid: re.user.uid, provider: re.providerId, nombre: re.user.displayName, email: re.user.email, emailVerified: re.user.emailVerified, telefono: re.user.phoneNumber, imagen: re.user.photoURL });
                    const data = response.data;
                    user = {
                        _id: data._id,
                        nombre: data.nombre ? data.nombre : "",
                        email: re.user.email,
                        imagen: re.user.photoURL,
                        telefono: re.user.phoneNumber,
                        DNI: data.DNI,
                        emailMp: data.emailMp,
                        nombreMp: data.nombreMp,
                        autorizacionMp: data.autorizacionMp,
                        posee_items: data.posee_items
                    }
                    setUsuario(user);
                    setToken(data.token);
                } catch (err) {
                    console.log(err);
                }

            }).catch((err) => {
                return "error"
            })
        return user
    }

    const resetPassword = async (actionCode, password) => {
        return await verifyPasswordResetCode(authentication, actionCode)
            .then(async (email) => {
                const accountEEmail = email;
                const newPassword = password;

                return await confirmPasswordReset(authentication, actionCode, newPassword)
                    .then(async (resp) => {
                        const usr = await singInWithPassword(accountEEmail, newPassword)
                        return usr
                        //navigate("/Ingresa")

                    }).catch((error) => {
                        // Error occurred during confirmation. The code might have expired or the
                        // password is too weak.
                        return { error: error }
                    });
            }).catch((error) => {
                // Invalid or expired action code. Ask user to try to reset the password
                // again.
                return { error: error }
            });
    }

    const generarEmailResetPassword = async (email) => {
        await sendPasswordResetEmail(authentication, email)
    }

    const enviarMailVerificacion = async () => {

        await sendEmailVerification(authentication.currentUser)
            .then(() => { })
            .catch((error) => {
                console.log(error)
            });
    }

    const verificarMail = async (code) => {
        return await applyActionCode(authentication, code)
            .then(() => {
                setCanLeave(true)
                setTimeout(() => window.location.href = 'https://www.tulu.com.ar/inicio', 1000);
                return { success: "success" }
            })
            .catch((error) => {
                return { errorV: error }
            });
    }

    //Use memo guarda la referencia de un objeto, y no vuelve a creear el objeto hasta que el usuario cambie.
    const value = useMemo(() => {
        return ({
            usuario: usuario,
            cargandoUsuario: cargandoUsuario,
            token: token,
            canLeave: canLeave,
            login,
            cambiarNombreUsuario,
            cambiarMailDeMp,
            cambiarNombreDeMp,
            cambiarDNI,
            registroEmailPass,
            singInWithPassword,
            resetPassword,
            generarEmailResetPassword,
            verificarMail,
            enviarMailVerificacion
        })
    }, [usuario, cargandoUsuario, token, canLeave])


    return (
        <UsuarioContext.Provider value={value} {...props} />
    )
}

export function useUsuario() {
    const context = React.useContext(UsuarioContext);
    if (!context) {
        throw new Error('useUsuario debe estar dentro del provedor UsuarioContext')
    }
    return context;
}
