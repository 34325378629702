import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Barra from '../barra_de_busqueda/barra.jsx'
import ReservasL from '../loaders/reservasL.jsx'
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import './reservasEnMisPublicaciones.scss'
//import Button from '@mui/material/Button';
import Footer from "../footer/footer.jsx";

import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import axios from 'axios';

axios.defaults.withCredentials = true;
export default function ReservasEnMisPublicaciones() {
    const dias = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'];

    const navigate = useNavigate();

    const { usuario, cargandoUsuario, token } = useUsuario();
    const [reservasUsuario, setReservasUsuario] = useState([]);

    const [loadItems, setLoadItems] = useState(false)
    const itemRange = Array.from({ length: 15 }, (_, i) => i * 2);
    const [errorReservas, setErrorReservas] = useState(true)
    const [bookingDate, setBookingDate] = useState(new Date());
    const onDateChange = e => {
        const fecha = e.toLocaleString('en-US', { timeZone: 'America/Argentina/Buenos_Aires' });
        setBookingDate(new Date(fecha));
    };


    useEffect(() => {
        if (usuario) {
            setLoadItems(true)
            axios.get(`https://api.tulu.com.ar/Items/reservasDelPropietario/${usuario._id}`)
                .then(response => {
                    setReservasUsuario(response.data.Reservas)
                    setLoadItems(false)
                })
        } else if (!cargandoUsuario) {
            navigate('/ingresa')
        }
    }, [usuario, cargandoUsuario, navigate, token])
    

    useEffect(() => {
        if (reservasUsuario.some(r => r.reservas.length > 0)) {
            setErrorReservas(false)
        } else {
            setErrorReservas(true)
        }
    }, [reservasUsuario])

    /*const handleCancelarReserva = async (id,indice) => {
        let reservasAux=reservasUsuario;
        let requestOptions;

        reservasAux.map( (reserva) =>{
            if(reserva._id === id){
                let aux=[]
                let aux2=[]
                
                aux=reserva.reservas
                aux2=aux.filter( (r) => r.id !== indice)
                requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        idReserva: id,
                        reservas: aux2
                    })
                };
                reserva.reservas=aux2
            }
        })

        setReservasUsuario([...reservasAux]);

       


        await fetch('https://api.tulu.com.ar/Items/CancelarReserva', requestOptions)
        .then(response => response.json())
        .then(data => {})

        
    }*/


    const isSameDay = (dateA, dateB) => {
        return (
            dateA.getDate() === dateB.getDate() &&
            dateA.getMonth() === dateB.getMonth() &&
            dateA.getFullYear() === dateB.getFullYear()
        );
    }

    const handleVerEspacio = (id) => {
        navigate(`/publicacion/${id}`, { state: { user: usuario } })

    }
    
    return (
        <div>
            <Barra user={usuario} />
            <div className='centrarMR'>
                <div className='cajaCalendarMr'>
                    <Paper elevation={3}>
                        <Calendar
                            value={bookingDate}
                            onChange={onDateChange}
                            locale="es-ES"
                            formatShortWeekday={(locale, date) => dias[date.getDay()]}
                            tileClassName={({ date }) => {
                                const isHighlighted = reservasUsuario.some((r1) =>
                                    r1.reservas.some((r2) => isSameDay(new Date(r2.fecha), date))
                                );
                                return isHighlighted ? 'highlighted-day' : '';
                            }}
                            tileContent={({ date }) => {
                                // Verificar si la fecha coincide con alguna de las fechas de reserva
                                const matchingReservas = reservasUsuario.reduce((total, r) => {
                                    return total + r.reservas.filter((re) => isSameDay(new Date(re.fecha), date)).length;
                                }, 0);

                                if (matchingReservas > 0) {
                                    return (
                                        <>
                                            <div className="triangle"></div>
                                            <span className="number">{matchingReservas > 100 ? "*" : matchingReservas}</span>
                                        </>
                                    );
                                }

                                return null; // No se muestra ningún contenido adicional en la celda
                            }}
                        />
                    </Paper>
                </div>
                <div className='cajaMR'>
                    {loadItems && itemRange.map((i, index) => (<ReservasL key={i} />))}

                    {(!loadItems && reservasUsuario) && reservasUsuario.map((r) => {
                        let re = r.reservas


                        return (

                            re.sort((a, b) => {
                                // Comparar las horas de las reservas
                                if (new Date(a.fecha).getHours() < new Date(b.fecha).getHours()) {
                                    return -1;
                                } else if (new Date(a.fecha).getHours() > new Date(b.fecha).getHours()) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }).map((reservas, index2) => (
                                new Date(reservas.fecha).getDay() === bookingDate?.getDay() &&
                                new Date(reservas.fecha).getDate() === bookingDate?.getDate() &&
                                new Date(reservas.fecha).getMonth() === bookingDate?.getMonth() &&
                                new Date(reservas.fecha).getFullYear() === bookingDate?.getFullYear() &&
                                <Paper
                                    elevation={3}
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '100%',
                                            md: '100%',
                                            lg: '48%',
                                            xl: '32%',
                                        },
                                        height: 'auto',
                                        marginLeft: '1.2vw',
                                        marginBottom: '1.2vw'
                                    }}
                                    key={`${r._id}-${index2}`}
                                >
                                    <div className='contenedorDentroCardMR'>
                                        <img src={r.portada} className='contenedorImagenMR' alt="portada" />
                                        <div className='cajaTextMR'>
                                            <p className='tituloMR'>{r.titulo} </p>
                                            <p className='codigo'>{r.idReservaMP}</p>
                                            <p className='fechaYHoraReservaMR margenMR'>Día:  {new Date(reservas.fecha).toLocaleDateString('es-ES')}</p>
                                            <p className='fechaYHoraReservaMR'>Hora: {reservas.horario}</p>
                                            <p className='direccionMR'>{r.direccion}</p>
                                            <p className='codigo2'>Reservado para: <b>{r.nombreIngresado}</b></p>
                                            <p className='codigo2 margenMR2'>Código de verificación: <b>{r.codigo}</b></p>
                                            {/*<p className='cancelacionMR'>{r.devolucion === "Siempre" ? "Podrás realizar la cancelación hasta justo antes de asistir." : r.devolucion === "nunca" ? "Este espacio no permite cancelaciones." : r.devolucion === "24hs" ? "Podrás cancelar este espacio hasta 24 horas antes de asistir." : r.devolucion === "48hs" ? "Podrás cancelar este espacio hasta 48 hs antes de asistir." : r.devolucion === "72hs" ? "Podrás cancelar este espacio hasta 72 hs antes de asistir." : r.devolucion === "96hs" ? "Podrás cancelar este espacio hasta 96 hs antes de asistir." : ""}</p>*/}
                                            {/*<h2>Estado</h2>*/}
                                            <div className='botonCancelarMR'>
                                                {/*}
                                                <Button
                                                    variant="contained"
                                                    disabled={handleCancelar(reservas.fecha, reservasUsuario[index].devolucion)}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '10px',
                                                            xl: '12px',
                                                        },
                                                        marginRight: '5%'
                                                    }}
                                                    onClick={() => handleParams(r._id, reservas.id)}>
                                                    Cancelar reserva
                                                </Button>{*/}
                                                <Button variant="contained"
                                                    onClick={() => handleVerEspacio(r.idItem)}
                                                    sx={{
                                                        fontSize: {
                                                            xs: '10px',
                                                            sm: '10px',
                                                            md: '10px',
                                                            lg: '10px',
                                                            xl: '12px',
                                                        },
                                                    }}
                                                >
                                                    ver
                                                </Button> 
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            ))
                        )

                    })}

                    {(!loadItems && errorReservas) && <div className="loSentimos alturaNoTienesRe">Aún no has recibido reservas en tus espacios publicados,   <br></br><br></br>¡asegúrate de divulgarlos adecuadamente para atraer a más clientes!</div>}



                </div>
            </div>
            <div>
                <div className='separacion'></div>
                <Footer />
            </div>
            {/*
            <Paper elevation={2} sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>Reserva</h1>   
            </Paper>
            */}
        </div>
    )
}