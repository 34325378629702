import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './acercaDe.scss';

function AcercaDe(props) {
    const {showContent,setShowContent,acercaDe,setAcercaDe,contacto,setContacto} = props

    const handleCerrar = () => {
        setShowContent(false)
        setAcercaDe(false)
        setContacto(false)
    }

    return (
        <AnimatePresence exit={{ x: '-100%' }} >
            {showContent && (
                <motion.div
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    exit={{ x: '-100%' }}
                    className="about-container"
                >
                    <div className="cross" onClick={handleCerrar}>˟</div>
                    <motion.div
                        initial={{ x: '100%' }}
                        animate={{ x: 0 }}
                        exit={{ x: '-100%' }}
                        className="about-text"
                    >
                        {acercaDe && (
                            <h1 className='tituloAD'>
                                Acerca de Tulu
                            </h1>
                        )}
                        {contacto && (
                            <h1 className='tituloAD'>
                                Contacto
                            </h1>
                        )}
                        {acercaDe && (
                            <p>
                                Esta aplicación, creada como proyecto universitario, tiene como objetivo principal brindar una solución para la búsqueda y reserva de espacios adaptados a las necesidades de usuarios y profesionales/propietarios.
                            </p>
                        )}
                        {contacto && (
                            <p>
                                Tulu cuenta con un bot de asistencia para ayudarte a resolver cualquier duda o inquietud que puedas tener. Si requieres asistencia de un profesional, no dudes en enviarnos un correo electrónico. Para acceder al bot de ayuda, simplemente busca el icono de chat en la esquina inferior izquierda de la pantalla y escribe la palabra 'Ayuda' para obtener nuestra dirección de correo electrónico.
                            </p>
                        )}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}

export default AcercaDe;
