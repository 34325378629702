import React, { useState, useEffect, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useNavigate, useLocation } from "react-router-dom";
import Barra from "../barra_de_busqueda/barra.jsx";
import "./crearPublicacion.scss";

import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import IconButton from "@mui/material/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CancelIcon from "@mui/icons-material/Cancel";
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';

import SearchBar from "./googleSearch.jsx"

import CreatableSelect from "react-select/creatable";
import { ActionMeta, OnChangeValue } from "react-select";

import lottie from "lottie-web";
import loading from "../assets/loader4.json";
import loading2 from "../assets/loader5.json";

import algoliasearch from "algoliasearch";
import { useUsuario } from "../variablesGlobales/usuarioGlobal.jsx";

import imageCompression from 'browser-image-compression';

import { useMediaQuery } from '@mui/material';

import axios from "axios";
axios.defaults.withCredentials = true;

export default function CrearPublicacion() {
  const location = useLocation();

  const navigate = useNavigate();

  const indexName = process.env.REACT_APP_ALGOLIA_INDEX_NAME;
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_API_KEY
  );
  const index = client.initIndex(indexName);

  const animationContainer = useRef(null);
  const animationContainer2 = useRef(null);

  const [imagenes, setImagenes] = useState([]);


  const [fotoId, setFotoId] = useState(0);

  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);

  const [horaInicioLunes, setHoraInicioLunes] = useState(null);
  const [horaFinLunes, setHoraFinLunes] = useState(null);
  const [horaInicioMartes, setHoraInicioMartes] = useState(null);
  const [horaFinMartes, setHoraFinMartes] = useState(null);
  const [horaInicioMiercoles, setHoraInicioMiercoles] = useState(null);
  const [horaFinMiercoles, setHoraFinMiercoles] = useState(null);
  const [horaInicioJueves, setHoraInicioJueves] = useState(null);
  const [horaFinJueves, setHoraFinJueves] = useState(null);
  const [horaInicioViernes, setHoraInicioViernes] = useState(null);
  const [horaFinViernes, setHoraFinViernes] = useState(null);
  const [horaInicioSabado, setHoraInicioSabado] = useState(null);
  const [horaFinSabado, setHoraFinSabado] = useState(null);
  const [horaInicioDomingo, setHoraInicioDomingo] = useState(null);
  const [horaFinDomingo, setHoraFinDomingo] = useState(null);

  const [titulo, setTitulo] = useState("");
  const [descripcion, setDescripcion] = useState("");

  const [lat, setLat] = useState();
  const [lon, setLon] = useState();
  const [localidad, setLocalidad] = useState();

  /*const [provincia, setProvincia] = useState("");
  const [localidad, setLocalidad] = useState("");
  const [calle, setCalle] = useState("");
  const [altura, setAltura] = useState("");*/
  const [metrosC, setMetrosC] = useState("");
  const [precio, setPrecio] = useState("");
  const [numTel, setNumTel] = useState("")

  const [errorNumMetrosC, setErrorNumMetrosC] = useState(false);
  const [errorNumPrecio, setErrorNumPrecio] = useState(false);
  const [errorNumTel, setErrorNumATel] = useState(false);

  const [tituloError, setTituloError] = useState(false);
  const [descripcionError, setDescripcionError] = useState(false);
  /*const [provinciaError, setProvinciaError] = useState(false);
  const [localidadError, setLocalidadError] = useState(false);
  const [calleError, setCalleError] = useState(false);
  const [alturaError, setAlturaError] = useState(false);*/
  const [imagenesError, setImagenesError] = useState(false);
  //const [metrosCError,setMetrosCError] = useState(false);
  const [precioError, setPrecioError] = useState(false);
  const [telError, setTelError] = useState(false);
  const [aceptoError, setAceptoError] = useState(false);
  const [terminos, setTerminos] = useState(false);
  const [errorCheckBox, setErrorCheckBox] = useState(false);
  const [profesionesError, setProfesionesError] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);
  const [optionList2, setOptionList2] = useState([]);
  const [optionList, setOptionList] = useState([]);
  const [defaultValue1, setDefaultValue1] = useState([]);
  const [defaultValue2, setDefaultValue2] = useState([]);

  const [horarioLunesError, setHorarioLunesError] = useState(false);
  const [horarioMartesError, setHorarioMartesError] = useState(false);
  const [horarioMiercolesError, setHorarioMiercolesError] = useState(false);
  const [horarioJuevesError, setHorarioJuevesError] = useState(false);
  const [horarioViernesError, setHorarioViernesError] = useState(false);
  const [horarioSabadoError, setHorarioSabadoError] = useState(false);
  const [horarioDomingoError, setHorarioDomingoError] = useState(false);
  const [horaFinError, setErrorHoraFin] = useState(false);

  const [selectedValue, setSelectedValue] = useState('Siempre');

  const [progress, setProgress] = useState(0);
  const [ubicacion, setUbicacion] = useState("");
  const [ubicacionTotal, setUbicacionTotal] = useState(null);
  const [ubicacionError, setUbicacionError] = useState(false);
  const [loaderImgs, setLoaderImgs] = useState(false);
  const [progreso, setProgreso] = useState(5);

  const [imageToDelete, setImageToDelete] = useState([]);

  const [rows, setRows] = useState([
    { horas: '', precio: '' , horasError: false, precioError: false, horasVacio:true, precioVacio:true, vacioError:false}
  ]);
  const isSmallScreen = useMediaQuery('(max-width:767px)');


  //const [usuario,setUsuario] = useState(location.state? (location.state.user? location.state.user: undefined) :undefined);
  const { usuario, token } = useUsuario();
  const [editarItem, setEditarItem] = useState(
    location.state
      ? location.state.item
        ? location.state.item
        : undefined
      : undefined
  );

  const [open, setOpen] = React.useState(false);


  const sugestRef = useRef([]);

  useEffect(() => {
    if (usuario) {
      if (usuario.autorizacionMp === false) {
        setOpen(true);
      }
    }
  }, [usuario]);


  useEffect(() => {
    //setUsuario(location.state? (location.state.user? location.state.user: undefined) :undefined)
    setEditarItem(
      location.state
        ? location.state.item
          ? location.state.item
          : undefined
        : undefined
    );
  }, [location.state]);

  useEffect(() => {
    if (editarItem) {
      var estado = {
        Lunes:
          editarItem.horario.lunes.horaInicio &&
            editarItem.horario.lunes.horaFin
            ? true
            : false,
        Martes:
          editarItem.horario.martes.horaInicio &&
            editarItem.horario.martes.horaFin
            ? true
            : false,
        Miercoles:
          editarItem.horario.miercoles.horaInicio &&
            editarItem.horario.miercoles.horaFin
            ? true
            : false,
        Jueves:
          editarItem.horario.jueves.horaInicio &&
            editarItem.horario.jueves.horaFin
            ? true
            : false,
        Viernes:
          editarItem.horario.viernes.horaInicio &&
            editarItem.horario.viernes.horaFin
            ? true
            : false,
        Sabado:
          editarItem.horario.sabado.horaInicio &&
            editarItem.horario.sabado.horaFin
            ? true
            : false,
        Domingo:
          editarItem.horario.domingo.horaInicio &&
            editarItem.horario.domingo.horaFin
            ? true
            : false,
      };

      setTitulo(editarItem.titulo);
      setDescripcion(editarItem.descripcion);
      setLat(editarItem.location.coordinates[0]);
      setLon(editarItem.location.coordinates[1]);
      setLocalidad(editarItem.localidad)
      setUbicacionTotal(editarItem.direccion)

      setSelectedValue(editarItem.devolucion);
      /*setProvincia(editarItem.zona.provincia);
      setLocalidad(editarItem.zona.localidad);
      setCalle(editarItem.zona.calle);
      setAltura(editarItem.zona.altura);*/
      setMetrosC(editarItem.metros);
      setPrecio(editarItem.precio);
      setNumTel(editarItem.numTel);
      setTerminos(true);
      setHoraInicioLunes(new Date(editarItem?.horario?.lunes?.horaInicio));
      setHoraFinLunes(new Date(editarItem?.horario?.lunes?.horaFin));
      setHoraInicioMartes(new Date(editarItem?.horario?.martes?.horaInicio));
      setHoraFinMartes(new Date(editarItem?.horario?.martes?.horaFin));
      setHoraInicioMiercoles(new Date(editarItem?.horario?.miercoles?.horaInicio));
      setHoraFinMiercoles(new Date(editarItem?.horario?.miercoles?.horaFin));
      setHoraInicioJueves(new Date(editarItem?.horario?.jueves?.horaInicio));
      setHoraFinJueves(new Date(editarItem?.horario?.jueves?.horaFin));
      setHoraInicioViernes(new Date(editarItem?.horario?.viernes?.horaInicio));
      setHoraFinViernes(new Date(editarItem?.horario?.viernes?.horaFin));
      setHoraInicioSabado(new Date(editarItem?.horario?.sabado?.horaInicio));
      setHoraFinSabado(new Date(editarItem?.horario?.sabado?.horaFin));
      setHoraInicioDomingo(new Date(editarItem?.horario?.domingo?.horaInicio));
      setHoraFinDomingo(new Date(editarItem?.horario?.domingo?.horaFin));
      setSelectedOptions(editarItem.tags);
      setSelectedOptions2(editarItem.profesiones);

      if (editarItem.modulos) {
        setRows(editarItem.modulos.map((item) => {
          return { horas: item.horas, precio: item.precio, horasError: false, precioError: false, horasVacio: false, precioVacio: false, vacioError: false }
        }))
      }

      let aux1 = [];
      let aux2 = [];
      editarItem.profesiones.forEach((item) => {
        aux1.push({ value: item, label: item });
      });
      setDefaultValue1(aux1);

      editarItem.tags.forEach((item) => {
        aux2.push({ value: item, label: item });
      });
      setDefaultValue2(aux2);

      setState(estado);

      let imagenesAux = [];
      let aux = {};
      let ultimoValor = 0;
      editarItem.img.forEach((item, index) => {
        aux = {
          id: index + 1,
          url: item,
        };
        imagenesAux.push(aux);
        ultimoValor = index + 1;
      });
      setFotoId(ultimoValor + 1);
      setImagenes(imagenesAux);
    }
  }, [editarItem]);


  useEffect(() => {
    // Función auxiliar para procesar los datos y actualizar el estado
    const processAndSetOptions = (data, setter) => {
      const options = data.map((item) => ({ value: item, label: item }));
      setter(options);
    };

    // Realizar las dos llamadas en paralelo usando Promise.all
    Promise.all([
      axios.get("https://api.tulu.com.ar/Items/category").then((response) => response.data),
      axios.get("https://api.tulu.com.ar/Items/tags").then((response) => response.data),
    ]).then(([categoryData, tagsData]) => {
      // Procesar y establecer opciones para la categoría
      processAndSetOptions(categoryData, setOptionList2);

      // Procesar y establecer opciones para las etiquetas (tags)
      processAndSetOptions(tagsData, setOptionList);
    });
  }, []);

  //Ignorar este error, funciona perfectamente, es por temas de visual studio
  const handleChange = (
    newValue: OnChangeValue<optionList, true>,
    actionMeta: ActionMeta<optionList>
  ) => {
    console.group("Value Changed");
    console.groupEnd();

    let a = newValue.map((item) => {
      if (item.value.startsWith("#")) {
        let str = item.value;
        str = str.slice(1);

        return "#" + str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        const str = item.value;
        return "#" + str.charAt(0).toUpperCase() + str.slice(1);
      }
    });

    let b = new Set(a);
    let c = Array.from(b);
    //guardo lo seleccionado sin duplicados en selectedOptions
    let aux = [];
    c.forEach((item) => {
      aux.push({ value: item, label: item });
    });
    setDefaultValue2(aux);
    setSelectedOptions(c);
  };

  const handleChange2 = (
    newValue: OnChangeValue<optionList, true>,
    actionMeta: ActionMeta<optionList>
  ) => {
    console.group("Value Changed");
    console.groupEnd();

    let a = newValue.map((item) => {
      if (item.value.startsWith("#")) {
        let str = item.value;
        str = str.slice(1);

        return "#" + str.charAt(0).toUpperCase() + str.slice(1);
      } else {
        const str = item.value;
        return "#" + str.charAt(0).toUpperCase() + str.slice(1);
      }
    });

    let b = new Set(a);
    let c = Array.from(b);
    //guardo lo seleccionado sin duplicados en selectedOptions
    let aux = [];
    c.forEach((item) => {
      aux.push({ value: item, label: item });
    });
    setDefaultValue1(aux);
    setSelectedOptions2(c);
    setProfesionesError(false);
  };

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: loading,
    });
  }, [loader]);

  useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer2.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: loading2,
    });
  }, [loader2]);

  const upload = async () => {
    try {
      const imgs = imagenes.map((item, index) => (index < 5 ? item.file : null));

      const imgsUrl = imagenes.map((item, index) => {
        if (index < 5) {
          if (item.url.includes("cloudinary")) {
            return { value: item.url, posicion: index };
          } else {
            return { value: null, posicion: index };
          }
        }
      })

      const aux_rows = rows
      .filter(item => item.horas !== "" && item.precio !== "")  // Primero filtrar los elementos
      .map(item => ({ horas: item.horas, precio: item.precio }));
      

      const publicacion = {
        objectID: editarItem ? editarItem._id : null,
        titulo: titulo,
        descripcion: descripcion,
        location: {
          type: 'Point',
          coordinates: [lat, lon],
        },
        categoria: selectedOptions2,
        precio: precio,
        numTel: numTel,
        usuario: usuario._id,
        usuarioExterno: usuario._id,
        metrosCuadrados: metrosC,
        direccion: ubicacionTotal,
        localidad: localidad,
        imagenes: [], // Dejaremos esto vacío por ahora
        tags: selectedOptions,
        terminos: terminos,
        devolucion: selectedValue,
        imagenesAEliminar: imageToDelete,
        imagenesUrl: imgsUrl ? imgsUrl : [],
        modulos: aux_rows,
      };

      const horario = {
        lunes: {
          horaInicio: Lunes ? horaInicioLunes : null,
          horaFin: Lunes ? horaFinLunes : null,
        },
        martes: {
          horaInicio: Martes ? horaInicioMartes : null,
          horaFin: Martes ? horaFinMartes : null,
        },
        miercoles: {
          horaInicio: Miercoles ? horaInicioMiercoles : null,
          horaFin: Miercoles ? horaFinMiercoles : null,
        },
        jueves: {
          horaInicio: Jueves ? horaInicioJueves : null,
          horaFin: Jueves ? horaFinJueves : null,
        },
        viernes: {
          horaInicio: Viernes ? horaInicioViernes : null,
          horaFin: Viernes ? horaFinViernes : null,
        },
        sabado: {
          horaInicio: Sabado ? horaInicioSabado : null,
          horaFin: Sabado ? horaFinSabado : null,
        },
        domingo: {
          horaInicio: Domingo ? horaInicioDomingo : null,
          horaFin: Domingo ? horaFinDomingo : null,
        }
      }

      let formData = new FormData();

      formData.append('publicacion', JSON.stringify(publicacion)); // Convertir objeto a cadena JSON
      formData.append('horario', JSON.stringify(horario)); // Convertir objeto a cadena JSON
      imgs.forEach((img) => {
        if (img && img !== undefined) {
          formData.append('imgs', img);
        }
      });

      let response;

      if (editarItem) {
        response = await axios.put(`https://api.tulu.com.ar/Items/Items`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
      } else {
        response = await axios.post(`https://api.tulu.com.ar/Items/Items`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });
      }

      const data = response.data;
      publicacion.imagenes = data.imagenes;
      if (!editarItem) {
        publicacion.objectID = data._id;
      }

      if (editarItem) {
        await updateAlgolia(publicacion);
      } else {
        await uploadAlgolia(publicacion);
      }
    } catch (error) {
      console.error('Error al subir:', error);
    }
  };


  const handleClose = (e) => {
    setOpen(false);
    navigate("/miCuenta", { state: { scroll: true } });
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (base) => ({
      ...base,
      minHeight: "60px",
      width: "80vw",
      color: "#c8c8c8",
      fontFamily: "Roboto,Helvetica,Arial,sans-serif",
      paddingLeft: "3px"
    }),

    menu: (provided, state) => ({
      ...provided,
      width: "80vw",
      zIndex: "10",
    }),
  };

  const [state, setState] = useState({
    Lunes: false,
    Martes: false,
    Miercoles: false,
    Jueves: false,
    Viernes: false,
    Sabado: false,
    Domingo: false,
  });

  const handleChangeCheckTerminos = () => {
    setTerminos(!terminos);
    if (aceptoError) {
      setAceptoError(false);
    }
  };

  const handleChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorCheckBox(false);

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const { Lunes, Martes, Miercoles, Jueves, Viernes, Sabado, Domingo } = state;
  const error =
    [Lunes, Martes, Miercoles, Jueves, Viernes, Sabado, Domingo].filter(
      (v) => v
    ).length < 1;

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(imagenes);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setImagenes(items);
  };

  const handleInput = async (e) => {
    let selectedFiles = e.target.files;
    if (selectedFiles.length === 0) return;

    if (selectedFiles.length > 5 || imagenes.length + selectedFiles.length > 5) {
      selectedFiles = Array.from(selectedFiles).slice(0, 5 - imagenes.length);
    }

    let fotoIdAux = fotoId;

    const newImages = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const newImage = {
        id: fotoIdAux + 1,
        url: URL.createObjectURL(file),
        file: file,
      };
      fotoIdAux++;
      newImages.push(newImage);
    }

    setImagenes([...imagenes, ...newImages]);
    setFotoId(fotoIdAux);
  };




  const handleDelete = (img) => {
    const deletedImg = imagenes.filter((imagen) => imagen.id === img.id);
    if (deletedImg[0].url.includes("blob")) {
      URL.revokeObjectURL(deletedImg[0].url);
    } else if (editarItem) {
      if (deletedImg[0].url.includes("cloudinary")) {
        setImageToDelete([...imageToDelete, deletedImg[0].url]);
      }

    }

    const newImagenes = imagenes.filter((imagen) => imagen.id !== img.id);
    setImagenes(newImagenes);
  };

  /*
  const handleClick = () => {
    setProgress(100);
  };*/

  const handleCrearPublicacion = async () => {
    setProgress(100);

    var errores = false;
    if (titulo.length === 0) {
      setTituloError(true);
      errores = true;
    }
    if (descripcion.length === 0) {
      setDescripcionError(true);
      errores = true;
    }
    if (ubicacionTotal) {
      if (ubicacionTotal.length === 0) {
        setUbicacionError(1);
        errores = true;
      }
    } else {
      setUbicacionError(1);
      errores = true;
    }
    /*
    if (provincia.length === 0) {
      setProvinciaError(true);
      errores = true;
    }
    if (localidad.length === 0) {
      setLocalidadError(true);
      errores = true;
    }
    if (calle.length === 0) {
      setCalleError(true);
      errores = true;
    }
    if (altura.length === 0) {
      setAlturaError(true);
      errores = true;
    }*/

    if (imagenes.length === 0) {
      setImagenesError(true);
      errores = true;
    }
    if (editarItem) {
      if (Lunes) {
        if (!(horaInicioLunes !== null && horaFinLunes !== null)) {
          setHorarioLunesError(true);
          errores = true;
        } else if (horaInicioLunes.getHours() === horaFinLunes.getHours()) {
          setHorarioLunesError(true);
          errores = true;
        }
      }
      if (Martes) {
        if (!(horaInicioMartes !== null && horaFinMartes !== null)) {
          setHorarioMartesError(true);
          errores = true;
        } else if (horaInicioMartes.getHours() === horaFinMartes.getHours()) {
          setHorarioMartesError(true);
          errores = true;
        }
      }
      if (Miercoles) {
        if (!(horaInicioMiercoles !== null && horaFinMiercoles !== null)) {
          setHorarioMiercolesError(true);
          errores = true;
        } else if (horaInicioMiercoles.getHours() === horaFinMiercoles.getHours()) {
          setHorarioMiercolesError(true);
          errores = true;
        }
      }
      if (Jueves) {
        if (!(horaInicioJueves !== null && horaFinJueves !== null)) {
          setHorarioJuevesError(true);
          errores = true;
        } else if (horaInicioJueves.getHours() === horaFinJueves.getHours()) {
          setHorarioJuevesError(true);
          errores = true;
        }
      }
      if (Viernes) {
        if (!(horaInicioViernes !== null && horaFinViernes !== null)) {
          setHorarioViernesError(true);
          errores = true;
        } else if (horaInicioViernes.getHours() === horaFinViernes.getHours()) {
          setHorarioViernesError(true);
          errores = true;
        }
      }
      if (Sabado) {
        if (!(horaInicioSabado !== null && horaFinSabado !== null)) {
          setHorarioSabadoError(true);
          errores = true;
        } else if (horaInicioSabado.getHours() === horaFinSabado.getHours()) {
          setHorarioSabadoError(true);
          errores = true;
        }
      }
      if (Domingo) {
        if (!(horaInicioDomingo !== null && horaFinDomingo !== null)) {
          setHorarioDomingoError(true);
          errores = true;
        } else if (horaInicioDomingo.getHours() === horaFinDomingo.getHours()) {
          setHorarioDomingoError(true);
          errores = true;
        }
      }
    } else {

      if (Lunes) {
        if (!(horaInicioLunes !== null && horaFinLunes !== null)) {
          setHorarioLunesError(true);
          errores = true;
        } else if (horaInicioLunes.getHours() === horaFinLunes.getHours()) {
          setHorarioLunesError(true);
          errores = true;
        }
      }
      if (Martes) {
        if (!(horaInicioMartes !== null && horaFinMartes !== null)) {
          setHorarioMartesError(true);
          errores = true;
        } else if (horaInicioMartes.getHours() === horaFinMartes.getHours()) {
          setHorarioMartesError(true);
          errores = true;
        }
      }
      if (Miercoles) {
        if (!(horaInicioMiercoles !== null && horaFinMiercoles !== null)) {
          setHorarioMiercolesError(true);
          errores = true;
        } else if (horaInicioMiercoles.getHours() === horaFinMiercoles.getHours()) {
          setHorarioMiercolesError(true);
          errores = true;
        }
      }
      if (Jueves) {
        if (!(horaInicioJueves !== null && horaFinJueves !== null)) {
          setHorarioJuevesError(true);
          errores = true;
        } else if (horaInicioJueves.getHours() === horaFinJueves.getHours()) {
          setHorarioJuevesError(true);
          errores = true;
        }
      }
      if (Viernes) {
        if (!(horaInicioViernes !== null && horaFinViernes !== null)) {
          setHorarioViernesError(true);
          errores = true;
        } else if (horaInicioViernes.getHours() === horaFinViernes.getHours()) {
          setHorarioViernesError(true);
          errores = true;
        }
      }
      if (Sabado) {
        if (!(horaInicioSabado !== null && horaFinSabado !== null)) {
          setHorarioSabadoError(true);
          errores = true;
        } else if (horaInicioSabado.getHours() === horaFinSabado.getHours()) {
          setHorarioSabadoError(true);
          errores = true;
        }
      }
      if (Domingo) {
        if (!(horaInicioDomingo !== null && horaFinDomingo !== null)) {
          setHorarioDomingoError(true);
          errores = true;
        } else if (horaInicioDomingo.getHours() === horaFinDomingo.getHours()) {
          setHorarioDomingoError(true);
          errores = true;
        }
      }
    }
    /*
    if(metrosC.length === 0){
        setMetrosCError(true);
        errores=true;
    }*/
    if (precio.length === 0) {
      setPrecioError(true);
      errores = true;
    }
    if (!terminos) {
      setAceptoError(true);
      errores = true;
    }
    if (error) {
      setErrorCheckBox(true);
      errores = true;
    }
    if (!error) {
      setErrorCheckBox(false);
    }
    if (errorNumPrecio) {
      errores = true;
    }
    if (errorNumMetrosC) {
      errores = true;
    }
    if (selectedOptions2.length === 0) {
      setProfesionesError(true);
      errores = true;
    }

    if (selectedValue === null || selectedValue === "" || selectedValue === undefined) {
      errores = true
    }

    //modulos
    rows.forEach((item, index) => {
      if(!item.horasVacio && item.precioVacio){
        let aux = rows;
        aux[index].vacioError = true;
        setRows(aux);
        errores = true;
      }else if (item.horasVacio && !item.precioVacio){
        let aux = rows;
        aux[index].vacioError = true;
        setRows(aux);
        errores = true;
      }
    })
      


    if (!errores) {
      setLoader(true);
      await upload();
      setLoader(false);
      setLoader2(true);

      setTimeout(function () {
        navigate("/inicio", { state: { user: usuario } });
      }, 2500);
    }
  };

  useEffect(() => {
    if (state.Lunes === false) {
      setHorarioLunesError(false);
    }
    if (state.Martes === false) {
      setHorarioMartesError(false);
    }
    if (state.Miercoles === false) {
      setHorarioMiercolesError(false);
    }
    if (state.Jueves === false) {
      setHorarioJuevesError(false);
    }
    if (state.Viernes === false) {
      setHorarioViernesError(false);
    }
    if (state.Sabado === false) {
      setHorarioSabadoError(false);
    }
    if (state.Domingo === false) {
      setHorarioDomingoError(false);
    }
  }, [
    state.Lunes,
    state.Martes,
    state.Miercoles,
    state.Jueves,
    state.Viernes,
    state.Sabado,
    state.Domingo,
  ]);

  const checkearHorario = () => {
    let lunesErr = false;
    let martesErr = false;
    let miercolesErr = false;
    let juevesErr = false;
    let viernesErr = false;
    let sabadoErr = false;
    let domingoErr = false;

    let errorHoraFin = false;

    if (
      (horaInicioLunes !== null && horaFinLunes !== null) ||
      (horaInicioMartes !== null && horaFinMartes !== null) ||
      (horaInicioMiercoles !== null && horaFinMiercoles !== null) ||
      (horaInicioJueves !== null && horaFinJueves !== null) ||
      (horaInicioViernes !== null && horaFinViernes !== null) ||
      (horaInicioSabado !== null && horaFinSabado !== null) ||
      (horaInicioDomingo !== null && horaFinDomingo !== null)
    ) {
      lunesErr = false;
      martesErr = false;
      miercolesErr = false;
      juevesErr = false;
      viernesErr = false;
      sabadoErr = false;
      domingoErr = false;
    }

    if ((horaInicioLunes !== null && horaFinLunes !== null) && horaInicioLunes > horaFinLunes) {
      lunesErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioMartes !== null && horaFinMartes !== null) && horaInicioMartes > horaFinMartes) {
      martesErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioMiercoles !== null && horaFinMiercoles !== null) && horaInicioMiercoles > horaFinMiercoles) {
      miercolesErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioJueves !== null && horaFinJueves !== null) && horaInicioJueves > horaFinJueves) {
      juevesErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioViernes !== null && horaFinViernes !== null) && horaInicioViernes > horaFinViernes) {
      viernesErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioSabado !== null && horaFinSabado !== null) && horaInicioSabado > horaFinSabado) {
      sabadoErr = true;
      errorHoraFin = true;
    }
    if ((horaInicioDomingo !== null && horaFinDomingo !== null) && horaInicioDomingo > horaFinDomingo) {
      domingoErr = true;
      errorHoraFin = true;
    }

    setHorarioLunesError(lunesErr);
    setHorarioMartesError(martesErr);
    setHorarioMiercolesError(miercolesErr);
    setHorarioJuevesError(juevesErr);
    setHorarioViernesError(viernesErr);
    setHorarioSabadoError(sabadoErr);
    setHorarioDomingoError(domingoErr);
    setErrorHoraFin(errorHoraFin);
  };

  useEffect(() => {
    let lunesErr = false;
    let martesErr = false;
    let miercolesErr = false;
    let juevesErr = false;
    let viernesErr = false;
    let sabadoErr = false;
    let domingoErr = false;
    let errorHoraFin = false;

    if (
      (horaInicioLunes !== null && horaFinLunes !== null) ||
      (horaInicioMartes !== null && horaFinMartes !== null) ||
      (horaInicioMiercoles !== null && horaFinMiercoles !== null) ||
      (horaInicioJueves !== null && horaFinJueves !== null) ||
      (horaInicioViernes !== null && horaFinViernes !== null) ||
      (horaInicioSabado !== null && horaFinSabado !== null) ||
      (horaInicioDomingo !== null && horaFinDomingo !== null)
    ) {
      lunesErr = false;
      martesErr = false;
      miercolesErr = false;
      juevesErr = false;
      viernesErr = false;
      sabadoErr = false;
      domingoErr = false;

      if ((horaInicioLunes !== null && horaFinLunes !== null) && horaInicioLunes > horaFinLunes) {
        lunesErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioMartes !== null && horaFinMartes !== null) && horaInicioMartes > horaFinMartes) {
        martesErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioMiercoles !== null && horaFinMiercoles !== null) && horaInicioMiercoles > horaFinMiercoles) {
        miercolesErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioJueves !== null && horaFinJueves !== null) && horaInicioJueves > horaFinJueves) {
        juevesErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioViernes !== null && horaFinViernes !== null) && horaInicioViernes > horaFinViernes) {
        viernesErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioSabado !== null && horaFinSabado !== null) && horaInicioSabado > horaFinSabado) {
        sabadoErr = true;
        errorHoraFin = true;
      }
      if ((horaInicioDomingo !== null && horaFinDomingo !== null) && horaInicioDomingo > horaFinDomingo) {
        domingoErr = true;
        errorHoraFin = true;
      }
    }

    


    setHorarioLunesError(lunesErr);
    setHorarioMartesError(martesErr);
    setHorarioMiercolesError(miercolesErr);
    setHorarioJuevesError(juevesErr);
    setHorarioViernesError(viernesErr);
    setHorarioSabadoError(sabadoErr);
    setHorarioDomingoError(domingoErr);
    setErrorHoraFin(errorHoraFin);
  }, [
    horaInicioLunes,
    horaFinLunes,
    horaInicioMartes,
    horaFinMartes,
    horaInicioMiercoles,
    horaFinMiercoles,
    horaInicioJueves,
    horaFinJueves,
    horaInicioViernes,
    horaFinViernes,
    horaInicioSabado,
    horaFinSabado,
    horaInicioDomingo,
    horaFinDomingo,
    horaFinError
  ]);

  const handleValidationMetros = (e) => {
    setErrorNumMetrosC(isNaN(e.target.value));
  };

  const handleValidationPrecio = (e) => {
    setErrorNumPrecio(isNaN(e.target.value));
  };

  const handleValidacionNumTel = (e) => {
    setErrorNumATel(isNaN(e.target.value));
  }

  const uploadAlgolia = async (item) => {
    await index.saveObject(item, { autoGenerateObjectIDIfNotExist: false });
  };

  const updateAlgolia = async (item) => {
    await index.partialUpdateObject(item);
  };

  const handleCopiarHorarios = () => {
    if (state.Lunes && horaInicioLunes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });
      setHoraInicioLunes(horaInicioLunes);
      setHoraInicioMartes(horaInicioLunes);
      setHoraInicioMiercoles(horaInicioLunes);
      setHoraInicioJueves(horaInicioLunes);
      setHoraInicioViernes(horaInicioLunes);
      setHoraInicioSabado(horaInicioLunes);
      setHoraInicioDomingo(horaInicioLunes);
    } else if (state.Martes && horaInicioMartes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioMartes);
      setHoraInicioMiercoles(horaInicioMartes);
      setHoraInicioJueves(horaInicioMartes);
      setHoraInicioViernes(horaInicioMartes);
      setHoraInicioSabado(horaInicioMartes);
      setHoraInicioDomingo(horaInicioMartes);
    } else if (state.Miercoles && horaInicioMiercoles) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioMiercoles);
      setHoraInicioMartes(horaInicioMiercoles);
      setHoraInicioJueves(horaInicioMiercoles);
      setHoraInicioViernes(horaInicioMiercoles);
      setHoraInicioSabado(horaInicioMiercoles);
      setHoraInicioDomingo(horaInicioMiercoles);
    } else if (state.Jueves && horaInicioJueves) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioJueves);
      setHoraInicioMartes(horaInicioJueves);
      setHoraInicioMiercoles(horaInicioJueves);
      setHoraInicioViernes(horaInicioJueves);
      setHoraInicioSabado(horaInicioJueves);
      setHoraInicioDomingo(horaInicioJueves);
    } else if (state.Viernes && horaInicioViernes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioViernes);
      setHoraInicioMartes(horaInicioViernes);
      setHoraInicioMiercoles(horaInicioViernes);
      setHoraInicioJueves(horaInicioViernes);
      setHoraInicioSabado(horaInicioViernes);
      setHoraInicioDomingo(horaInicioViernes);
    } else if (state.Sabado && horaInicioSabado) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioSabado);
      setHoraInicioMartes(horaInicioSabado);
      setHoraInicioMiercoles(horaInicioSabado);
      setHoraInicioJueves(horaInicioSabado);
      setHoraInicioViernes(horaInicioSabado);
      setHoraInicioDomingo(horaInicioSabado);
    } else if (state.Domingo && horaInicioDomingo) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraInicioLunes(horaInicioDomingo);
      setHoraInicioMartes(horaInicioDomingo);
      setHoraInicioMiercoles(horaInicioDomingo);
      setHoraInicioJueves(horaInicioDomingo);
      setHoraInicioViernes(horaInicioDomingo);
      setHoraInicioSabado(horaInicioDomingo);
    }

    if (state.Lunes && horaFinLunes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinMartes(horaFinLunes);
      setHoraFinMiercoles(horaFinLunes);
      setHoraFinJueves(horaFinLunes);
      setHoraFinViernes(horaFinLunes);
      setHoraFinSabado(horaFinLunes);
      setHoraFinDomingo(horaFinLunes);
    } else if (state.Martes && horaFinMartes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinMartes);
      setHoraFinMiercoles(horaFinMartes);
      setHoraFinJueves(horaFinMartes);
      setHoraFinViernes(horaFinMartes);
      setHoraFinSabado(horaFinMartes);
      setHoraFinDomingo(horaFinMartes);
    } else if (state.Miercoles && horaFinMiercoles) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinMiercoles);
      setHoraFinMartes(horaFinMiercoles);
      setHoraFinJueves(horaFinMiercoles);
      setHoraFinViernes(horaFinMiercoles);
      setHoraFinSabado(horaFinMiercoles);
      setHoraFinDomingo(horaFinMiercoles);
    } else if (state.Jueves && horaFinJueves) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinJueves);
      setHoraFinMartes(horaFinJueves);
      setHoraFinMiercoles(horaFinJueves);
      setHoraFinViernes(horaFinJueves);
      setHoraFinSabado(horaFinJueves);
      setHoraFinDomingo(horaFinJueves);
    } else if (state.Viernes && horaFinViernes) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinViernes);
      setHoraFinMartes(horaFinViernes);
      setHoraFinMiercoles(horaFinViernes);
      setHoraFinJueves(horaFinViernes);
      setHoraFinSabado(horaFinViernes);
      setHoraFinDomingo(horaFinViernes);
    } else if (state.Sabado && horaFinSabado) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinSabado);
      setHoraFinMartes(horaFinSabado);
      setHoraFinMiercoles(horaFinSabado);
      setHoraFinJueves(horaFinSabado);
      setHoraFinViernes(horaFinSabado);
      setHoraFinDomingo(horaFinSabado);
    } else if (state.Domingo && horaFinDomingo) {
      setState({
        Lunes: true,
        Martes: true,
        Miercoles: true,
        Jueves: true,
        Viernes: true,
        Sabado: true,
        Domingo: true,
      });

      setHoraFinLunes(horaFinDomingo);
      setHoraFinMartes(horaFinDomingo);
      setHoraFinMiercoles(horaFinDomingo);
      setHoraFinJueves(horaFinDomingo);
      setHoraFinViernes(horaFinDomingo);
      setHoraFinSabado(horaFinDomingo);
    }
  };

  const handleChangeRadio = (event) => {
    setSelectedValue(event.target.value);
  };

  const handlePlaceLoaded = (place) => {
    var lat = place.geometry.location.lat();
    var lng = place.geometry.location.lng();
    setLat(lat);
    setLon(lng);
    if (place.types[0] !== "street_address") {
      setUbicacionError(2)
    } else {
      setUbicacionError(0)
      setLocalidad(place.address_components[2].long_name);
    }

    setUbicacionTotal(place.formatted_address);

    // Aquí puedes usar las variables lat y lng para hacer lo que necesites con la latitud y longitud
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...rows];
    // Check if the input is a number
    const isNumber = /^[0-9]*$/.test(value);
    newRows[index][field] = value;
    newRows[index][`${field}Error`] = !isNumber;
    newRows[index][`${field}Vacio`] = value === "" ? true : value === null ? true : false;
    setRows(newRows);
  };

  const addRow = () => {
    if (rows.length < 10) {
      setRows([...rows, { horas: '', precio: '' }]);
    }
  };

  const removeRow = (index) => {
    if (rows.length > 1) {
      const newRows = [...rows];
      newRows.splice(index, 1);
      setRows(newRows);
    }
  };

  return (
    <div className="fondoPu">
      <Barra user={usuario} />
      <div className="cajaPrincipalPublicar">
        <div className="centarPu">
          <h1 className="publicaTuEspacioPu">¡Publicá tu espacio!</h1>
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "80vw" },
          }}
          noValidate
          autoComplete="on"
          className="cuerpoPu"
        >
          <div className="textoPu">
            <h1 className="tituloPu">Título</h1>
            <h1 className="subtituloPu">
              Un buen título hará que tus clientes te encuentren más fácilmente.
            </h1>
          </div>
          <div className="caja2Pu">
            <TextField
              error={tituloError}
              required
              id="outlined-required"
              label="Titulo"
              value={titulo ? titulo : ""}
              onChange={(a) => {
                setTitulo(a.target.value);
                setTituloError(false);
              }}
              color="warning"
              helperText={tituloError ? "Debes ingresar un título." : ""}
            />
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Descripción</h1>
            <h2 className="subtituloPu">
              Describí con detalle el espacio que queres publicar.
            </h2>
          </div>
          <div className="caja2Pu">
            <TextField
              error={descripcionError}
              required
              multiline
              id="outlined-required"
              label="Descripción"
              value={descripcion ? descripcion : ""}
              onChange={(a) => {
                setDescripcion(a.target.value);
                setDescripcionError(false);
              }}
              color="warning"
              helperText={
                descripcionError ? "Debes ingresar una descripción." : ""
              }
            />
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Profesiones</h1>
            <h2 className="subtituloPu">
              Indica cuales son las profesiones en la que tu espacio se adapta
              (Ingresar al menos una).
            </h2>
          </div>
          <div className="caja2Pu">
            <div>
              <CreatableSelect
                isMulti
                placeholder="Profesiones... *"
                formatCreateLabel={(inputText) => `Crear #${inputText}`} //si no esta va a crear una nueva profesion y la va a guardar, esto sirve
                //para cambiar el texto que aparece en la lista. si pongo por ejemplo
                //{(inputText) => `Crear ${inputText}`} va a aparecer "Crear Abogacia" (Abogacia o cualquier otra profesion)
                onChange={handleChange2}
                value={defaultValue1}
                options={optionList2}
                isSearchable={true}
                theme={
                  profesionesError
                    ? (theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#f9e4d4",
                        primary: "#D32F2F",

                        neutral30: "#D32F2F",
                        neutral20: "#D32F2F",
                        neutral50: "#D32F2F",
                      },
                    })
                    : (theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary25: "#f9e4d4",
                        primary: "#ED6C02",
                      },
                    })
                }
                styles={customStyles}
              />
              <p className="css-1wc848c-MuiFormHelperText-root.Mui-error css-1wc848c-MuiFormHelperText-root ubicacionError">{profesionesError ? "Debes seleccionar al menos una profesión." : ""}</p>
            </div>
          </div>


          <div className="textoPu">
            <h1 className="tituloPu">Identificadores</h1>
            <h2 className="subtituloPu">
              Permite que tu espacio llegue a más personas. Utiliza los que
              mejor vayan con tu espacio.
            </h2>
          </div>
          <div className="caja2Pu">
            <CreatableSelect
              isMulti
              placeholder="identificadores..."
              formatCreateLabel={(inputText) => `Crear #${inputText}`} //si no esta va a crear un neuvo identificador y lo va a guardar, esto sirve
              //para cambiar el texto que aparece en la lista. si pongo por ejemplo
              //{(inputText) => `Crear ${inputText}`} va a aparecer "Crear LindoEspacio" (LindoEspacio o cualquier otro tag)
              value={defaultValue2}
              onChange={handleChange}
              options={optionList}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#f9e4d4",
                  primary: "#ED6C02",
                },
              })}
              styles={customStyles}
            />
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Ubicación</h1>
            <h2 className="subtituloPu">
              ¿En que Altura/Calle/Localidad/Provincia se encuentra el espacio
              que vas a publicar?
            </h2>
          </div>



          <div className="caja2Pu">
            <SearchBar onPlaceLoaded={handlePlaceLoaded} error={ubicacionError} setError={setUbicacionError} ubi={ubicacionTotal}></SearchBar>
            <div>
              <p className="css-1wc848c-MuiFormHelperText-root.Mui-error css-1wc848c-MuiFormHelperText-root ubicacionError">{ubicacionError === 1 ? "Debes seleccionar alguna de las ubicaciones recomendadas." : ubicacionError === 2 ? `Coloca una direccion valida "Altura/Calle/Localidad/Provincia".` : ""}</p>
            </div>
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Imágenes </h1>
            <h2 className="subtituloPu">
              La última imagen que decidas subir será la portada de tu
              publicación. <b>(Máximo 5 imágenes)</b>
            </h2>
          </div>

          {imagenes.length === 0 && (
            <div className="caja2Pu">
              <div
                className={
                  imagenesError ? "uploadButtonPu2Error" : "uploadButtonPu2"
                }
              >
                <>
                  {loaderImgs ? (
                    <CircularProgress variant='determinate' value={progreso} color="primary" sx={{ height: "15vw", width: "15vw" }} />
                  )
                    : (
                      <IconButton
                        color="warning"
                        aria-label="upload picture"
                        component="label"
                        sx={{ height: "20vw", width: "20vw", borderRadius: "50%", color: "#bebebe" }}
                        disabled={loaderImgs}
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={handleInput}
                          multiple
                        />
                        <AddAPhotoIcon
                          sx={imagenesError ? { color: "#e66666", width: "40px", height: "40px" } : { color: "#bebebe", width: "40px", height: "40px" }}
                        />
                      </IconButton>
                    )}
                </>
              </div>
            </div>
          )}

          <div className="superContainerImagenesPu">
            {imagenes.length > 0 && (
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="plcaPU2"
                    >
                      {imagenes.length < 5 && (
                        <div className="uploadButtonPu">
                          <IconButton
                            color="warning"
                            aria-label="upload picture"
                            component="label"
                            sx={{ height: "10vw", width: "10vw", borderRadius: "50%", color: "#bebebe" }}
                            disabled={loaderImgs}
                          >
                            <>
                              {loaderImgs ? (
                                <CircularProgress variant='determinate' value={progreso} color="primary" sx={{ width: "40px", height: "40px" }} />
                              ) :
                                (<>
                                  <input
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    onChange={handleInput}
                                    multiple
                                  />
                                  <AddAPhotoIcon sx={{ color: "#bebebe", width: "40%", height: "40%" }} />
                                </>
                                )
                              }
                            </>
                          </IconButton>
                        </div>
                      )}
                      {imagenes.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="crossButtonPu"
                            >
                              <img src={item.url} alt="imagen publicacion" className="iPU" />
                              <button
                                className="deleteButtonPu"
                                onClick={() => handleDelete(item)}
                              >
                                <CancelIcon sx={{
                                  color: "#FF5722", width: "2vw", height: "2vw", backgroundColor: "#FFF", borderRadius: "50%",
                                  "&:hover": {
                                    transform: "scale(1.1)", // Cambiar el tamaño al hacer hover
                                    color: "#112b3c",
                                    boxShadow: "0 0 15px rgba(0, 0, 0, 0.3)", // Cambiar la sombra al hacer hover
                                  },
                                  "@media all and (min-width: 900px) and (max-width: 1270px)": {
                                    width: "3vw",
                                    height: "3vw",
                                  },
                                  "@media all and (min-width: 744px) and (max-width: 900px)": {
                                    width: "3.5vw",
                                    height: "3.5vw",
                                  },
                                  "@media all and (max-width: 743px)": {
                                    width: "1.5rem",
                                    height: "1.5rem",
                                  },
                                }} />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Días y horarios de atención</h1>
            <h2 className="subtituloPu mmPu">
              Selecciona los días y los horarios en el que vas a ofrecer tu
              espacio. Tendrás que seleccionar al menos un día, una hora de
              inicio y una hora de fin para ese día
            </h2>
          </div>
          <div className="caja2Pu">
            <div className="botonCopiar">
              <Button variant="contained" onClick={handleCopiarHorarios}>
                Copiar los horarios para todos los días
              </Button>
            </div>
            <div className={errorCheckBox ? "diasPu error" : "diasPu"}>
              <FormControl
                required
                error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Lunes}
                    name="Lunes"
                    label={isSmallScreen ? "Lu" : "Lunes"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Martes}
                    name="Martes"
                    label={isSmallScreen ? "Ma" : "Martes"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Miercoles}
                    name="Miercoles"
                    label={isSmallScreen ? "Mi" : "Miercoles"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Jueves}
                    name="Jueves"
                    label={isSmallScreen ? "Ju" : "Jueves"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Viernes}
                    name="Viernes"
                    label={isSmallScreen ? "Vi" : "Viernes"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Sabado}
                    name="Sabado"
                    label={isSmallScreen ? "Sa" : "Sabado"}
                    className="diasSelectorPu"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        sx={errorCheckBox ? { color: "#DB2F2F" } : {}}
                      />
                    }
                    onChange={handleChangeCheckbox}
                    checked={Domingo}
                    name="Domingo"
                    label={isSmallScreen ? "Do" : "Domingo"}
                    className="diasSelectorPu"
                  />
                </FormGroup>
              </FormControl>
              <div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        ampm={false}
                        disabled={!state.Lunes}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Lunes ? horaInicioLunes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioLunesError(true);
                          } else {
                            setHoraInicioLunes(newValue.$d);
                            checkearHorario();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioLunesError ? true : false}
                            helperText={horaFinError ? horarioLunesError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioLunesError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu "

                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Lunes}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Lunes ? horaFinLunes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioLunesError(true);
                          } else {
                            setHoraFinLunes(newValue.$d);
                            checkearHorario();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioLunesError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>

                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Martes}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Martes ? horaInicioMartes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioMartesError(true);
                          } else {
                            setHoraInicioMartes(newValue.$d);
                            checkearHorario();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioMartesError ? true : false}
                            helperText={horaFinError ? horarioMartesError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioMartesError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Martes}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Martes ? horaFinMartes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioMartesError(true);
                          } else {
                            setHoraFinMartes(newValue.$d);
                            checkearHorario();
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioMartesError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Miercoles}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Miercoles ? horaInicioMiercoles : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioMiercolesError(true);
                          } else {
                            setHoraInicioMiercoles(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioMiercolesError ? true : false}
                            helperText={horaFinError ? horarioMiercolesError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioMiercolesError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Miercoles}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Miercoles ? horaFinMiercoles : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioMiercolesError(true);
                          } else {
                            setHoraFinMiercoles(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioMiercolesError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Jueves}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Jueves ? horaInicioJueves : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioJuevesError(true);
                          } else {
                            setHoraInicioJueves(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioJuevesError ? true : false}
                            helperText={horaFinError ? horarioJuevesError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioJuevesError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Jueves}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Jueves ? horaFinJueves : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioJuevesError(true);
                          } else {
                            setHoraFinJueves(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioJuevesError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Viernes}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Viernes ? horaInicioViernes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioViernesError(true);
                          } else {
                            setHoraInicioViernes(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioViernesError ? true : false}
                            helperText={horaFinError ? horarioViernesError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioViernesError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Viernes}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Viernes ? horaFinViernes : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioViernesError(true);
                          } else {
                            setHoraFinViernes(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioViernesError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Sabado}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Sabado ? horaInicioSabado : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioSabadoError(true);
                          } else {
                            setHoraInicioSabado(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioSabadoError ? true : false}
                            helperText={horaFinError ? horarioSabadoError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioSabadoError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Sabado}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Sabado ? horaFinSabado : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioSabadoError(true);
                          } else {
                            setHoraFinSabado(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioSabadoError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
                <div className="diasPu horariosPu">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Domingo}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora inicio"
                        value={Domingo ? horaInicioDomingo : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioDomingoError(true);
                          } else {
                            setHoraInicioDomingo(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioDomingoError ? true : false}
                            helperText={horaFinError ? horarioDomingoError ? "La hora fin debe ser mayor que la de inicio." : "" : horarioDomingoError ? "Horario no valido. Estilo permitido (HH:00)" : ""}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                    <Stack spacing={3}>
                      <TimePicker
                        disabled={!state.Domingo}
                        ampm={false}
                        openTo="hours"
                        views={["hours"]}
                        inputFormat="HH:00"
                        mask="__:__"
                        label="Hora fin"
                        value={Domingo ? horaFinDomingo : null}
                        onChange={(newValue) => {
                          if (newValue.$d == "Invalid Date") {
                            setHorarioDomingoError(true);
                          } else {
                            setHoraFinDomingo(newValue.$d);
                            checkearHorario()
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            color="warning"
                            error={horarioDomingoError ? true : false}
                            FormHelperTextProps={{
                              style: {
                                width: '250%',
                                position: 'absolute',
                                marginTop: '38%'
                              }
                            }}
                          />
                        )}
                        className="horasPu"
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            {(horarioLunesError ||
              horarioMartesError ||
              horarioJuevesError ||
              horarioMiercolesError ||
              horarioViernesError ||
              horarioSabadoError ||
              horarioDomingoError) && (
                <p className="error errorPu">
                  Error: asegúrate de seleccionar un día y completar tanto la hora
                  de inicio como la hora de fin. No pueden ser las mismas. Además La hora fin debe ser mayor a la de inicio.
                </p>
              )}
          </div>
          <div className="textoPu">
            <h1 className="tituloPu">Ofrecer devolución </h1>
            <h2 className="subtituloPu">
              Podes ofrecerle a tus clientes la posibilidad de devolver el dinero y cancelar la reserva.
            </h2>
            <div className="radioButtonPu">
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedValue}
                  onChange={handleChangeRadio}
                >
                  <FormControlLabel sx={{ marginRight: 10 }} value="Siempre" control={<Radio />} label="Siempre" />
                  <FormControlLabel sx={{ marginRight: 10 }} value="24hs" control={<Radio />} label="24hs" />
                  <FormControlLabel sx={{ marginRight: 10 }} value="48hs" control={<Radio />} label="48hs" />
                  <FormControlLabel sx={{ marginRight: 10 }} value="72hs" control={<Radio />} label="72hs" />
                  <FormControlLabel sx={{ marginRight: 10 }} value="96hs" control={<Radio />} label="96hs" />
                  <FormControlLabel sx={{ marginRight: 10 }} value="Nunca" control={<Radio />} label="Nunca" />
                </RadioGroup>
              </FormControl>
              {selectedValue === "Siempre" && <div className="textoRadioPu">Tus clientes podrán cancelar la reserva inclusive faltando minutos para asistir al espacio.</div>}
              {selectedValue === "96hs" && <div className="textoRadioPu">Tus clientes podrán cancelar la reserva hasta 96hs antes de la hora de asistencia al espacio.</div>}
              {selectedValue === "72hs" && <div className="textoRadioPu">Tus clientes podrán cancelar la reserva hasta 72hs antes de la hora de asistencia al espacio.</div>}
              {selectedValue === "48hs" && <div className="textoRadioPu">Tus clientes podrán cancelar la reserva hasta 48hs antes de la hora de asistencia al espacio.</div>}
              {selectedValue === "24hs" && <div className="textoRadioPu">Tus clientes podrán cancelar la reserva hasta 24hs antes de la hora de asistencia al espacio.</div>}
              {selectedValue === "Nunca" && <div className="textoRadioPu">Tus clientes no podrán cancelar la reserva.</div>}
            </div>


          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Contacto</h1>
            <h2 className="subtituloPu">
              Podes ofrecerle a tus clientes la posibilidad de contactarte vía WhatsApp.
            </h2>
          </div>
          <div className="metrosPu">
            <TextField
              error={errorNumTel}
              color="warning"
              label="Número de teléfono"
              id="outlined-end-adornment"
              sx={{ m: 1, width: "25ch" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+54</InputAdornment>
                ),
              }}
              value={numTel ? numTel : ""}
              inputProps={{ maxLength: 12 }} // Ajusta la longitud máxima según el formato del número de teléfono
              className="precioPu"
              onChange={(a) => {
                setNumTel(a.target.value);
                handleValidacionNumTel(a);
              }}
              helperText={
                errorNumTel
                  ? "Solo se permiten números."
                  : ""
              }
            />
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Metros cuadrados (m²) </h1>
            <h2 className="subtituloPu">
              El tamaño en metros cuadrados de tu espacio. (No es obligatorio)
            </h2>
          </div>
          <div className="metrosPu">
            <TextField
              error={errorNumMetrosC}
              id="outlined-required"
              label="Metros cuadrados"
              value={metrosC ? metrosC : ""}
              color="warning"
              className="precioPu"
              inputProps={{ maxLength: 6 }}
              onChange={(a) => {
                setMetrosC(a.target.value);
                handleValidationMetros(a);
              }}
              helperText={errorNumMetrosC ? "Solo se permiten números" : ""}
            />
          </div>
          <div className="textoPu">
            <h1 className="tituloPu">Precio</h1>
            <h2 className="subtituloPu">
              El precio (por hora) al que vas a publicar tu espacio.{" "}
            </h2>
          </div>
          <div className="metrosPu">
            <TextField
              error={precioError || errorNumPrecio}
              required
              color="warning"
              label="Precio"
              id="outlined-end-adornment"
              sx={{ m: 1, width: "25ch" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">por hora</InputAdornment>
                ),
              }}
              value={precio ? precio : ""}
              inputProps={{ maxLength: 6 }}
              className="precioPu"
              onChange={(a) => {
                setPrecio(a.target.value);
                setPrecioError(false);
                handleValidationPrecio(a);
              }}
              helperText={
                precioError
                  ? "Debes ingresar un precio."
                  : "" || errorNumPrecio
                    ? "Solo se permiten números."
                    : ""
              }
            />
          </div>

          <div className="textoPu">
            <h1 className="tituloPu">Módulos</h1>
            <h2 className="subtituloPu">
              Elegí la cantidad de horas que dura cada módulo de tu espacio y el costo del mismo. (No es obligatorio)
            </h2>
          </div>



          <div className="metrosPu">
            {rows.map((row, index) => (
              <div key={index} className="diasPu horariosPu">
                <TextField
                  error={row.horasError? true : row.vacioError ? (row.horasVacio && !row.precioVacio) ? true : false: false }
                  color="warning"
                  label="Ej: 3"
                  id="outlined-end-adornment"
                  sx={{ m: 1, width: "20ch" }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">horas</InputAdornment>
                    ),
                  }}
                  value={row.horas}
                  inputProps={{ maxLength: 6 }}
                  className="precioPu"
                  onChange={(e) => handleInputChange(index, 'horas', e.target.value)}
                  helperText={
                    row.vacioError
                      ? (row.horasVacio && !row.precioVacio) ? 
                        "Debes ingresar un precio." : ""
                      : "" || row.horasError
                        ? "Solo se permiten números."
                        : ""
                  }
                />

                <TextField
                  error={row.horasError? true : row.vacioError ? (!row.horasVacio && row.precioVacio) ? true : false: false }
                  color="warning"
                  label="Precio"
                  id="outlined-end-adornment"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">$</InputAdornment>
                    ),
                  }}
                  value={row.precio}
                  inputProps={{ maxLength: 6 }}
                  className="precioPu"
                  onChange={(e) => handleInputChange(index, 'precio', e.target.value)}
                  helperText={
                    row.vacioError
                      ? (!row.horasVacio && row.precioVacio) ? 
                        "Debes ingresar un precio." : ""
                      : "" || row.precioError
                        ? "Solo se permiten números."
                        : ""
                  }
                />
                {rows.length > 1 && (
                  <IconButton onClick={() => removeRow(index)} sx={{ color: 'red' }}>
                    <DeleteIcon />
                  </IconButton>
                )}


              </div>
            ))}
            <IconButton
              color="warning"
              onClick={addRow}
              disabled={rows.length >= 10}
              sx={{ fontSize: 20 }}>

              <AddCircleRoundedIcon sx={{fontSize: 30, marginRight:'10px'}}/> Agregar más módulos
            </IconButton>

          </div>

          <div className="caja2Pu enLineaPu">
            <FormControl
              required
              error={error}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="warning"
                      sx={aceptoError ? { color: "#DB2F2F" } : {}}
                    />
                  }
                  onChange={handleChangeCheckTerminos}
                  checked={terminos}
                  name="terminos y condiciones"
                  className="terminosPu2"
                  label={
                    <div>
                      Acepto los{" "}
                      <a
                        href="https://drive.google.com/file/d/1X7RtWHmL4K7QdYl1BYdY-UVJYryDcCNh/view"
                        target="_blank"
                        rel="noreferrer"
                        className="terminosPu"
                      >
                        terminos y condiciones de servicio
                      </a>{" "}
                    </div>
                  }
                />
              </FormGroup>
            </FormControl>
          </div>
        </Box>
        <div className="caja2Pu">


          {/* Este es otro boton

        <MyButton loading={true} />
        <Button variant="contained" sx={{width: '32vw', height: '8vh'}}>crear publicación</Button>*/}

          {!loader && !loader2 && !(tituloError || descripcionError || profesionesError || ubicacionError === 1 || ubicacionError === 2 || horarioLunesError || horarioMartesError || horarioMiercolesError || horarioJuevesError || horarioViernesError || horarioSabadoError || horarioDomingoError || precioError || aceptoError) && (
            <>
              <button
                className="crearPublicacion"
                onClick={handleCrearPublicacion}
              >
                <ProgressBar progress={progress} />
                {editarItem ? "Editar publicación" : "Crear publicación"}
              </button>
            </>
          )}

          {!loader && !loader2 && (tituloError || descripcionError || profesionesError || ubicacionError === 1 || ubicacionError === 2 || horarioLunesError || horarioMartesError || horarioMiercolesError || horarioJuevesError || horarioViernesError || horarioSabadoError || horarioDomingoError || precioError || aceptoError) && (
            <>
              <button
                className="crearPublicacionDisabled"
                onClick={handleCrearPublicacion}
                disabled={true}
              >
                <ProgressBar progress={progress} />
                {editarItem ? "Editar publicación" : "Crear publicación"}
              </button>
              <p className="css-1wc848c-MuiFormHelperText-root.Mui-error css-1wc848c-MuiFormHelperText-root crearError">{(tituloError || descripcionError || profesionesError || ubicacionError || horarioLunesError || horarioMartesError || horarioMiercolesError || horarioJuevesError || horarioViernesError || horarioSabadoError || horarioDomingoError || precioError || aceptoError) ? "Encontramos errores en uno o más campos, revisalos y completalos nuevamente." : ""}</p>
            </>
          )}
          {loader && (
            <div className="crearPublicacion2">
              <div className="animacionLoaderPu" ref={animationContainer}></div>
            </div>
          )}
          {loader2 && (
            <>
              <div className="crearPublicacion2">
                <div
                  className="animacionLoaderPu2"
                  ref={animationContainer2}
                ></div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* POP UP EN CASO DE NO TENER MERCADO PAGO, LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS

        PARA VOLVER A HABILITAR TODO BUSCAR EL MENSAJE EN TODO EL CODIGO 'LO DESHABILITO HASTA TENER SUFICIENTES USUARIOS'


      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"No se pudo establecer la conexión con Mercado Pago"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No pudimos encontrar la configuración de tu cuenta de Mercado Pago.
            ¡Ve a la sección de configuración para agregarla y así poder cobrar
            tus futuras reservas con Mercado Pago!
            <br />
            <br />
            En el caso de omitir este paso, los usuarios no podran reservar tu
            lugar.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Omitir</Button>
          <Button onClick={handleClose}>Configurar</Button>
        </DialogActions>
      </Dialog>
    */}
    </div>
  );
}


function ProgressBar({ progress }) {

  return (
    <div
      style={{
        width: `100%`,
        height: '100%',
        backgroundColor: 'blue',
        transition: 'width 0.3s',
      }}
    />
  );
}