import React, { useState, useMemo } from "react";

const TagsContext = React.createContext();

export function TagsProvider(props) {
  const [tags, setTags] = useState([]);
  const [tags2, setTags2] = useState([]);
  const [tagsTotal, setTagsTotal] = useState([]);
  const [profesiones, setProfesiones] = useState([]);
  const [ubicaciones, setUbicaciones] = useState([]);
  const [busqueda, setBusqueda] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState([]);

  const value = useMemo(() => {
    return {
      tags: tags,
      tags2: tags2,
      tagsTotal: tagsTotal,
      profesiones: profesiones,
      busqueda,
      selectedOptions2,
      ubicaciones,
      setBusqueda,
      setProfesiones,
      setSelectedOptions2,
      setTagsTotal,
      setTags2,
      setTags,
      setUbicaciones,
    };
  },[tags, tags2, tagsTotal,busqueda,profesiones,selectedOptions2]);

  return <TagsContext.Provider value={value} {...props} />;
}

export function useTags() {
  const context = React.useContext(TagsContext);
  if (!context) {
    throw new Error("useUsuario debe estar dentro del provedor UsuarioContext");
  }
  return context;
}
