import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaInstagram } from 'react-icons/fa';
import './footer.scss';
import AcercaDe from "../acercaDe/acercaDe";


const Footer = () => {
  const [showContent, setShowContent] = useState(false);
  const [contacto,setContacto] = useState(false)
  const [acercaDe,setAcercaDe] = useState(false)

  const handleNav = (n) =>{
    setShowContent(!showContent)
    if(n === 1){
        setAcercaDe(true)
    }else{
        setContacto(true)
    }
  }

  return (
    <>
    <footer className='footer'  >
      <div className="footer-container">
        <div className="footer-section">
          <h3>Rutas de la página</h3>
          <ul>
            <li><Link to="/">Inicio</Link></li>
            <li><button style={{ all:"unset", textDecoration: "none", color: "#FFF", cursor: "pointer" }} onClick={() => handleNav(1)}>Acerca de</button></li>
            <li><button style={{ all:"unset", textDecoration: "none", color: "#FFF", cursor: "pointer" }} onClick={() => handleNav(2)}>Contacto</button></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Información adicional</h3>
          <ul>
            <li><a href="https://drive.google.com/file/d/1X7RtWHmL4K7QdYl1BYdY-UVJYryDcCNh/view" target="_blank" rel="noreferrer" style={{ all:"unset", textDecoration: "none", color: "#FFF", cursor: "pointer" }}>Términos y condiciones</a></li>
            {/*<li><Link to="#">Política de privacidad</Link></li>*/}
          </ul>
        </div>

        <div className="footer-section footer-section2">
          <h3>Redes sociales</h3>
          <ul>
            <li>
              <a  
                href="https://www.linkedin.com/company/tulu-arg/"
                target="_blank"
                rel="noreferrer">
                  <FaLinkedin /> 
                </a>
            </li>
            <li>
              <a  
                href="https://twitter.com/tulu_arg"
                target="_blank"
                rel="noreferrer">
                  <FaTwitter /> 
                </a>
            </li>
            <li>
              <a  
                href="https://www.instagram.com/tulu_arg/"
                target="_blank"
                rel="noreferrer">
                  <FaInstagram />
                </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-copyright">
        <hr />
        <p>Copyright © {new Date().getFullYear()} Tulu Todos los derechos reservados.</p>
      </div>
    </footer>
    <AcercaDe showContent={showContent} setShowContent={setShowContent} contacto={contacto} setContacto={setContacto} acercaDe={acercaDe} setAcercaDe={setAcercaDe}/>
  </>
  );
};

export default Footer;
